import { Component, AfterViewInit, OnInit,OnDestroy, ViewChild } from '@angular/core';
import { HttpService } from "@app/services/Http/http.service"
import { AlertService } from 'ngx-alerts'
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import * as moment from "moment"


@Component({
  selector: 'app-extra-approves',
  templateUrl: './extra-approves.component.html',
  styleUrls: ['./extra-approves.component.scss']
})
export class ExtraApprovesComponent implements OnInit {
  
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  startDateEdit: Date = moment().toDate();
  endDateEdit: Date = moment().toDate();
  dtOptions: any = {
    pagingType: 'full_numbers',
    dom: 'Bfrtip',
    buttons: [
        { extend: 'print', text: 'Imprimir', title: 'Reporte de horas extras' },
        { extend: 'excel', title: 'Reporte de horas extras' }
      ],
  "language": {
    "url": "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json",
    "emptyTable": "No existen ausencias con los parametros especificados"
  },
  "pageLength": 50     
  };
  isLoadingTab: boolean = false;
  rowsReport: any = [];


  constructor(public httpService: HttpService, private alertService: AlertService) { }

  ngOnInit() {
    this.isLoadingTab= true;
    this.httpService.postHttpAuth("/reporte/extrasaprobadas",{})
    .subscribe(data => {
      this.isLoadingTab= false;
      this.rowsReport = data.report;
      this.rerender();
    });
    
  }
  
  ngAfterViewInit(): void {
   this.dtTrigger.next();
   }

   ngOnDestroy(): void {
     // Do not forget to unsubscribe the event
     this.dtTrigger.unsubscribe();
   }
   
   apply(){
     var data = {
       from:moment(this.startDateEdit).format("YYYY-MM-DD"),
       to: moment(this.endDateEdit).format("YYYY-MM-DD")
     };
     this.isLoadingTab= true;
     this.httpService.postHttpAuth("/reporte/extrasaprobadas",data)
     .subscribe(data => {
       this.isLoadingTab= false;
       this.rowsReport = data.report;
       this.rerender();
     });
     
   }
   
   rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
   }

}
