import { Pipe, PipeTransform } from '@angular/core';
import * as moment from "moment"
import 'moment-duration-format';

import 'twix';
@Pipe({
  name: 'hours'
})
export class HoursPipe implements PipeTransform {

  	transform(value: any, format: string): any {
  		if (value == 0) {
  			return "00:00";
  		}else if (value < 0) {
	  		value = value * (-1);
	  		return "-" + moment.duration(value, 'minutes').format(format);
	  	}
	  	else if (value>=60){
	      return (value==0 ? "00:00:00" : moment.duration(value, 'minutes').format(format));
	  	}
	  	else{
	      return  moment.utc((value*60)*1000).format(format);
	  	}
  	}
}
