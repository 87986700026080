import { Component, OnInit,ViewChild, AfterViewInit  } from '@angular/core';
import { config_hierarchy,findHierarchy } from "./configCompany";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { HttpService } from "./../services/Http/http.service"
import { AlertService } from 'ngx-alerts'
import { Subject } from 'rxjs';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {

  constructor(private modalService: NgbModal,public ngxSmartModalService: NgxSmartModalService, private httpService: HttpService, private  alertService: AlertService) { }
  @ViewChild(DataTableDirective)
    dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: any = {};
  type: number = 2;
  typeCreate: number = 2;
  sucursal: boolean=false;
  sucursalSelect: Number=0;
  departament: boolean=false;
  departamentSelect: Number= 0;
  division: boolean=false;
  divisionSelect: Number= 0;
  nameGroup: string="";
  id_empresa: number;
  filtersCompany: any = { "sucursal":true, "departamento": false, "division":false, "centro_costo":false }
  filtersBreadCrumb: any=[];
  sucursales: any=[];
  deparments: any=[];
  divisions: any=[];
  division_filter: any=[];
  datasTable: any=[];
  labelTable: string="";
  rowEdit: Number = 0;
  rowId: Number = 0;
  textGroupEdit: string="";
  typeGroupEdit: string="";
  previousID: Number=0;
  companyConfig: any;
  hierarchies:any = <any>[];
  open() {
    (<any>$("#myModal")).modal("show");
    this.nameGroup="";
    this.resetFormModal();
  }

  
  successCreate(data):void {
    this.alertService.success('Grupo creado exitosamente.');
    this.nameGroup="";
    let showing=  parseInt(<any>this.typeCreate)+1;
    (<any>$("#myModal")).modal("hide");

    if(this.type==showing){
      this.datasTable.push(data);
      this.rerender();
    }
  }
  
  
  saveGroup(){
    if(this.typeCreate==1){
      this.httpService.postHttpAuth("/sucursal",{id_empresa:this.id_empresa,nombre:this.nameGroup})
      .subscribe(data => {
        this.successCreate(data.data);
      })
    }
    else if(this.typeCreate==2){
  
      this.httpService.postHttpAuth("/departamento",{
        id_sucursal : this.sucursalSelect,
        nombre:this.nameGroup
      })
      .subscribe(data => {
        this.successCreate(data.data);
      })
  
    }
    else if(this.typeCreate==3){
  
      this.httpService.postHttpAuth("/division",{
        id_departamento : this.departamentSelect,
        nombre:this.nameGroup
      })
      .subscribe(data => {
        this.successCreate(data.data);
      });
  
    }  else if(this.typeCreate==4){
  
      this.httpService.postHttpAuth("/centro-costo",{
        id_division : this.divisionSelect,
        nombre:this.nameGroup
      })
      .subscribe(data => {
        this.successCreate(data.data);
      });
  
    }
  }
  
  
    selectType($evt){
      this.getSucursals();
      if(this.typeCreate==1){
        this.departament=false;
        this.division=false;
        this.sucursal=false;
      }
      else if(this.typeCreate==2){

        this.sucursales=[];
        this.departament=false;
        this.division=false;
      }
      else if(this.typeCreate==3){
        this.departament=true;
        this.sucursal=true;
        this.division=false;
      }
      else if(this.typeCreate==4){
        this.departament=true;
        this.sucursal=true;
        this.division=true;
      }
    }




    getSucursals(){
      this.sucursal=true;
      this.sucursalSelect=0;
      this.httpService.getHttpAuth("/sucursal/get/"+this.id_empresa)
      .subscribe(data => {
        this.sucursales=data.data;
      });
    }
    

   changeSucursal(){

     if(this.typeCreate!=2){
       this.deparments=[];

       if(this.sucursalSelect!=0)
       this.httpService.getHttpAuth("/departamento/get/"+this.sucursalSelect)
       .subscribe(data => {
         this.deparments=data.data;

         if(this.deparments.length==0)
         this.alertService.info("Esta sucursal no tiene departamentos asociados");
       })
       else
       {

         this.departamentSelect=0;
         this.deparments=[];
         this.divisionSelect=0;
         this.divisions=[];
       }
     }
   }

   changeDeparment(){

     if(this.typeCreate!=3){

       if(this.departamentSelect!=0)
       this.httpService.getHttpAuth("/division/get/"+this.departamentSelect)
       .subscribe(data => {

         this.divisions=data.data;
         if(this.divisions.length==0)
         this.alertService.info("Este departamento no tiene divisiones asociadas");

       })
       else{
         this.divisionSelect=0;
         this.divisions=[];
       }
     }
   }





  resetFormModal(){
    this.typeCreate=1;
    this.departamentSelect=0;
    this.deparments=[];
    this.divisionSelect=0;
    this.divisions=[];
    this.sucursalSelect=0;
    this.sucursales=[];
    this.departament=false;
    this.division=false;
    this.sucursal=false;

  }

  showHierarchy(type,id,name) {
    this.type=type;
    let path = (
      type+1==1 ? "empresa" :
      type+1==2 ? "sucursal":
      type+1==3 ? "departamento":
      type+1==4 ? "division" :
      "centro-costo"
    )
    
    this.httpService.getHttpAuth(`/${path}/get/`+id)
    .subscribe(data => {
      
        if(data.data.length==0){
          this.alertService.info("No existen elementos dentro de este grupo");
          return 0; 
        }
        
        this.datasTable=data.data;
        this.rerender();
        this.enableBreadCrumb(this.type,id, name);
        this.type=this.type+1;
    });
    
    this.previousID=id;
  
  }
  
  enableBreadCrumb = (type,id,name) => {
    this.filtersBreadCrumb[type-1] = { type, name, id }
    this.filtersBreadCrumb.length = type;
  };
      
  
    deleteGroup(typeTable,id,i){
      var url="";
      if(this.type==2)
        url="/sucursal/";
      else if(this.type==3)
        url="/division/";
      else if(this.type==4)
        url="/departamento/";
      else if(this.type==5)
        url="/centro-costo/";
    
      this.httpService.deleteHttpAuth(url+id,{})
      .subscribe(data => {
          this.datasTable.splice(i, 1);
          this.alertService.success("Grupo eliminado exitosamente");
          this.rerender();
          return 0;
      })
    
    }
    
    editGroup(type,id,i,text){
      this.rowId=id;
      this.rowEdit=i;
      this.textGroupEdit=text;
      this.typeGroupEdit=type;
    
      (<any>$("#editGroup")).modal("show");
    }
    
    editGroupPost(){
      var url="";
      var parameters={nombre:this.textGroupEdit,id_sucursal:null,id_division:null,id_departamento:null};
      if(this.type==2){
          url="/sucursal/";
      }
      else if(this.type==3){
          url="/departamento/";
          parameters.id_sucursal=this.previousID;
    
      }
      else if(this.type==4){
          url="/division/";
          parameters.id_departamento=this.previousID;
      }
      else if(this.type==5){
          url="/centro-costo/";
          parameters.id_division=this.previousID;
      }
    
      this.httpService.putHttpAuth(url+this.rowId,parameters)
        .subscribe(data => {
            //  this.datasTable.splice(i, 1);
            this.alertService.success("Grupo modificado exitosamente");
            (<any>$("#editGroup")).modal("hide");
            return 0;
        })
    }
    


  ngOnInit() {
    var config = findHierarchy(2,1);
    this.companyConfig=config;
    var group=null;
    group=localStorage.getItem("grupo");
    group=JSON.parse(group);
    group=group.grupos;
    this.id_empresa=group.empresa.id;

    this.getSucursals();
    var table="empresa"
    this.labelTable=this.companyConfig.level2;
    this.httpService.getHttpAuth("/empresa/"+this.id_empresa)
    .subscribe(datap => {
      this.httpService.getHttpAuth("/sucursal/get/"+this.id_empresa)
      .subscribe(data => {
        this.datasTable=data.data;
        this.rerender();
        this.enableBreadCrumb(1,this.id_empresa,datap.data.nombre)
      });
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }


  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

}
