import { Component, OnInit,ViewChild,Input, ChangeDetectionStrategy, TemplateRef } from '@angular/core';
import { AfterViewInit } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { HttpService } from "./../../services/Http/http.service"
import { AlertService } from 'ngx-alerts';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { CustomDateFormatter } from './custom-date-formatter.provider';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { IgxDatePickerModule } from "igniteui-angular";
import { ContentLoaderChecksComponent } from "./../../globals/content-loader-checks/content-loader-checks.component"
import { IgxDatePickerComponent, DateRangeType } from "igniteui-angular"
import { config_hierarchy,findHierarchy } from "./../../companies/configCompany";

import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours
} from 'date-fns';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap/modal/modal.module';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarDateFormatter,
  DAYS_OF_WEEK,
  CalendarEventTimesChangedEvent,
  CalendarMonthViewDay
} from 'angular-calendar';
import 'hammerjs';
import * as moment from "moment"
import 'twix';




@Component({
  selector: 'app-schedules-detail',
  templateUrl: './schedules-detail.component.html',
  styleUrls: ['./schedules-detail.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ]
})
export class SchedulesDetailComponent implements OnInit {
  isLoadingTab: boolean = true;

  @ViewChild(DataTablesModule)
  view: string = "month";
  viewDate: Date = new Date();
  locale: string = 'En';
  public date: Date = new Date(Date.now());
  @ViewChild("uidt") dpicker: IgxDatePickerComponent;
  nameSchedule: string="";
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  eventsCalendar: CalendarEvent[] = [];
  weekendDays: number[] = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY];
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  refresh: Subject<any> = new Subject();
  sucursal:string ="";
  sucursalSelect: number=0;
  departament: boolean=false;
  deparmentSelect: number= 0;
  division: boolean=false;
  divisionSelect: number= 0;
  ccenterSelect: number=0;
  sucursales: any=[];
  deparments: any= [];
  divisions: any= [];
  id_empresa: number;
  schduleSelect: number;
  specialSchedule: any = [];
  savedSpecialSchedule: any = [];
  templateSelected: number=0;
  allSchedules: any = [];
  extend: boolean=false;
  from: Date;
  to: Date;
  restTime: number;
  filtersCompany: any = { "sucursal":true, "departamento": false, "division":false, "centro_costo":false }
  companyCreateShow: any = { "sucursal":false, "departamento": true, "division":true, "centro_costo":false }
  dtOptions: any = {
    pagingType: 'full_numbers',
    "language": {
      "url": "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json"
    }
  };
  templates: any=[];
  groups: any  = [];
  schedulesByDay: any=[];
  cssClass: number =0;
  minutes: Number=0;
  listMinutes: any = [0,5,10,15,20,25,30];
  modifySchedule: boolean = false;
  dateToEdit: string = "";
  free_day_checkbox: boolean = false;
  initTime: any;
  endTime: any;
  isEditing: boolean=true;
  sucursalSelected: string = "";
  deparmentSelected: string = "";
  divisionSelected: string = "";
  departamentSelect: string="";
  scheduleEdited: number = 0;
  activeDayIsOpen: boolean = false;
  addDaysEventFromList:Subject<any> = new Subject();
  subjectAddDaysEdit:Subject<any> = new Subject();
  newDay:Subject<any> = new Subject();
  companyConfig: any;
  options: any = {
    text:"Guardar",
    disabled: false,
    type:"btn btn-primary",
    icon:"fa fa-spinner fa-spin",
    isLoading:false,
    principal_icon:"fa fa-floppy-o"
  };

  constructor(public httpService: HttpService, private alertService: AlertService, private atp: AmazingTimePickerService, private xf: IgxDatePickerModule) { }

  addSchedule(){
          this.isEditing=false;
          this.modifySchedule=false;
          this.to=null;
          this.from=null;
          this.minutes=0;
          this.nameSchedule="";
          this.templateSelected=0;
          this.eventsCalendar=[];
          this.deparments = [];
          this.divisions = [];

          this.sucursalSelect=0;
          this.refresh.next();
          this.addDaysEventFromList.next({
            template:{},
            buildSchedule: []
          });
          (<any>$("#modalSchedule")).modal("show");
  }

  updateCalendar(): void  {
    var $this=this;
    
    this.dpicker.disabledDates = [];
    
    if(this.to && !this.from){
      this.alertService.info("Debes seleccionar una fecha de inicio");
      this.to=null;
      return;
    }
    else{
      
      var disableFrom = moment(this.from).subtract(100, 'years').toDate();
      var dateMax = moment(this.from).add(3, 'months').toDate();
      var dateMin = moment(this.from).add(6, 'days').toDate();

      this.dpicker.disabledDates = [
        {type: DateRangeType.Between,dateRange:[dateMax, new Date("2200-1-15")]},
        {type: DateRangeType.Between,dateRange:[disableFrom, dateMin]}
      ];
      
    }


    if($this.from && $this.to){

      var schedule = <any>{};
      schedule.plantilla=$this.templates[$this.templateSelected];
      schedule.fecha_inicio = $this.from;
      schedule.fecha_fin = $this.to;
      schedule.horario_especial=[];
      console.log(schedule);
      this.addDaysEventFromList.next({
        template:schedule,
        buildSchedule: []
      });
    }
  }

  changeDate(){
    var $this=this;

    setTimeout(function(){
        $this.updateCalendar();
    },100);

  }

  // dayClicked(day){
  // 
  //   if(!this.from || !this.to)
  //   {
  //       this.alertService.info("Debe completar toda la información de horario para editar");
  //       return 0;
  //   }
  // 
  //   var compareDate = moment(day.date, "DD-MM-YYYY");
  //   var startDate   = moment(this.from, "DD-MM-YYYY");
  //   var endDate     = moment(this.to, "DD-MM-YYYY");
  // 
  // 
  //   if(!compareDate.isBetween(startDate, endDate))
  //   {
  //     this.alertService.info("Debe existir un horario para la fecha seleccionada ");
  //     return 0;
  //   }
  // 
  //   // day.date
  // 
  //   this.dateToEdit=compareDate.format("DD-MM-YYYY");
  //   this.modifySchedule=true;
  // 
  // }

  getSucursals(){
    this.companyCreateShow.sucursal=true;
    this.sucursalSelect=0;
    this.httpService.getHttpAuth("/sucursal/get/"+this.id_empresa)
    .subscribe(data => {

      this.sucursales=data.data;
      if(this.sucursales.length==0)
          this.alertService.info("Esta grupo no tiene elementos asociados");
    })
  }

  changeSucursal(){
        this.divisionSelect=0;
        this.deparmentSelect=0;
        this.ccenterSelect=0;
        this.divisions= [];
        this.deparments=[];

        if(this.sucursalSelect!=0)
          this.httpService.getHttpAuth("/departamento/get/"+this.sucursalSelect)
          .subscribe(data => {
            this.deparments=data.data;

            if(this.deparments.length==0)
                this.alertService.info("Esta grupo no tiene elementos asociados")
          })
  }


  changeDeparment(){

        if(this.deparmentSelect!=0){
            this.httpService.getHttpAuth("/division/get/"+this.deparmentSelect)
            .subscribe(data => {

              this.divisions=data.data;
              if(this.divisions.length==0)
                  this.alertService.info("Esta grupo no tiene elementos asociados")
            })


            }else{
              this.divisions=[];
            }
  }

  changeDivision(){
    if(this.divisionSelect!=0){
        this.httpService.getHttpAuth("/centro-costo/get/"+this.divisionSelect)
        .subscribe(data => {


          if(this.divisions.length==0)
              this.alertService.info("Esta grupo no tiene elementos asociados")
        })



    }
  }

  saveSchedule(){
    var data={
      id_plantilla:this.templates[this.templateSelected].id,
      nombre:this.nameSchedule,
      minutos_extra: this.minutes,
      fecha_inicio:moment(this.from).format("DD-MM-YYYY"),
      fecha_fin:moment(this.to).format("DD-MM-YYYY"),
      id_tipo_grupo:null,
      id_grupo:null,
      horario_especial: this.specialSchedule,
      extender: this.extend
    }

    if(!this.isEditing){
        if(this.sucursalSelect!=0){
          data.id_grupo=this.sucursalSelect;
          data.id_tipo_grupo=2;

          if(this.deparmentSelect && this.departamentSelect!="0"){
            data.id_grupo=this.deparmentSelect;
            data.id_tipo_grupo=3;
          }

          if(this.divisionSelect && this.divisionSelect!=0){
            data.id_grupo=this.divisionSelect;
            data.id_tipo_grupo=4;
          }
        }
        else{
          this.alertService.info("Debes seleccionar al menos una sucursal");
          return 0;
        }

        this.options.disabled=true;
        this.options.isLoading=true;

        this.httpService.postHttpAuth("/horario",data)
        .subscribe(x=>{
          this.options.disabled = false;
          this.options.isLoading = false;
          this.alertService.success("se ha registrado de manera correcta el horario");
          this.reloadSchedule();
          (<any>$("#modalSchedule")).modal("hide");
        },
        err => {
          this.options.disabled=false;
          this.options.isLoading=false;
        });
    }
    else{
        data.horario_especial=false;
        this.options.disabled = true;
        this.options.isLoading = true;
        this.httpService.putHttpAuth("/horario/"+this.scheduleEdited,data)
        .subscribe(x=>{
          this.options.disabled = false;
          this.options.isLoading = false;
          this.alertService.success("se ha modificada de manera correcta el horario");
          this.reloadSchedule();
          (<any>$("#modalSchedule")).modal("hide");
        },
        err => {
          this.options.disabled=false;
          this.options.isLoading=false;
        })
    }


  }

  addSpecialSchedule(){
    var events= this.eventsCalendar;
    var toEdit=this.dateToEdit;
    // var isValid = moment(toEdit).isValid();
    var match=false;
    // if(!isValid)
    //   return 0;

    if((!this.initTime || !this.endTime) && !this.free_day_checkbox){
      this.alertService.info("Debe seleccionar una hora de inicio y una hora de fin");
      return 0;
    }
    let date = moment(toEdit, "DD-MM-YYYY");
    var objectSpecial = {
      fecha:date.format("YYYY-MM-DD"),
      libre:this.free_day_checkbox,
      hora_inicio:this.initTime,
      hora_fin:this.endTime
    };


    if(this.isEditing){
      var data={
        "aplicar_a" : "grupo",
        "hora_inicio" :  (this.free_day_checkbox ? "00:00" : this.initTime),
        "hora_fin" : (this.free_day_checkbox ? "00:00" : this.endTime),
        "libre" : this.free_day_checkbox,
        "id_horario": this.scheduleEdited,
        "fecha":toEdit,
        "descanso":this.restTime
      };

      this.httpService.postHttpAuth("/horario-especial",data)
      .subscribe(x => {
        this.alertService.success("Horario agregado exitosamente");
        this.specialSchedule.push(objectSpecial);
        this.modifySchedule=false;
      });
    }else{


      this.newDay.next(objectSpecial);
      this.specialSchedule.push(objectSpecial);

    }
  }

  deleteScheduleById(id,i){
    this.httpService.deleteHttpAuth("/horario/"+id, {})
    .subscribe( x => {
      this.alertService.success("Horario eliminado exitosamente");
      this.allSchedules.splice(i,1);
    });
  }

  modifyScheduleById(id){
    this.isEditing=true;
    this.to=null;
    this.from=null;
    this.minutes=5;
    this.nameSchedule="";
    this.templateSelected=0;
    this.modifySchedule=false;
    this.eventsCalendar=[];
    this.refresh.next();
    this.addDaysEventFromList.next({
      template:{},
      buildSchedule: []
    });
    this.httpService.getHttpAuth("/horario/"+id)
    .subscribe( x => {
        this.httpService.getHttpAuth("/plantilla/"+x.data.id_plantilla)
        .subscribe(w =>{


          this.httpService.getHttpAuth("/groups/"+x.data.id_grupo+"/"+x.data.id_tipo_grupo)
          .subscribe(y => {
            this.sucursalSelected=y.data.sucursal;
            this.deparmentSelected=y.data.departamento;
            this.divisionSelected=y.data.division;
          });
          
          
          var start_date=moment(x.data.init_date).format("MM-DD-YYYY");
          var end_date=moment(x.data.end_date).format("MM-DD-YYYY");
          this.from=moment(start_date).toDate();
          this.to=moment(end_date).toDate();
          this.nameSchedule=x.data.nombre;
          this.minutes=x.data.minutos_extra;
          this.extend=x.data.extender;
          this.scheduleEdited=id;
          this.savedSpecialSchedule=x.data.horario_especial;
          for (let i = 0; i < this.templates.length; i++) {
            if(this.templates[i].id==x.data.id_plantilla)
              this.templateSelected=i;
          }

          this.updateCalendar();
          (<any>$("#modalSchedule")).modal("show");
        });

    });
  }

  ngOnInit() {
      var config = findHierarchy(2,1);
      this.companyConfig=config;
      var group=null;
      group=localStorage.getItem("grupo");
      group=JSON.parse(group);
      group=group.grupos;
      this.id_empresa=group.empresa.id;
      this.getSucursals();

      this.httpService.getHttpAuth("/plantilla/get/all")
      .subscribe(x => {

        this.templates=x.data
      })

      this.httpService.getHttpAuth("/horario/get/all")
      .subscribe(x => {
        this.isLoadingTab=false;
        this.allSchedules=x.data;
        this.dtTrigger.next();
       });


      this.view = 'month'


  }

  reloadSchedule (): void{
    this.isLoadingTab=true;
    this.httpService.getHttpAuth("/horario/get/all")
    .subscribe(x => {
      this.isLoadingTab=false;
      this.allSchedules=x.data;
       this.rerender();
     });
  }



  rerender(): void {

    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

}
