import { Component, AfterViewInit, OnInit,OnDestroy, ViewChild } from '@angular/core';
import { HttpService } from "@app/services/Http/http.service"
import { AlertService } from 'ngx-alerts'
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Angular2Txt } from 'angular2-txt/Angular2-txt';
import * as moment from "moment"

@Component({
  selector: 'app-report',
  templateUrl: './distribution.component.html',
  styleUrls: ['./distribution.component.scss']
})
export class DistributionComponent implements OnInit {
    
    
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  startDateEdit: Date = moment().toDate();
  endDateEdit: Date = moment().toDate();
  type: string = "t";
  dtOptions: any = {
    "ordering": false,
    pagingType: 'full_numbers',
    dom: 'Bfrtip',
    buttons: [
        { extend: 'excel', title: 'Reporte de distribución', exportOptions:{
          stripNewlines: false
        }
        }
      ],
  "language": {
    "url": "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json",
    "emptyTable": "No existen ausencias con los parametros especificados"
  },
  "paging":   false  
  };
  isLoadingTab: boolean = false;
  rowsReport: any = [];
  groups: any = {};
  concepts: any = [];
  isLoading: boolean = true;
  txt;
  disable_download : boolean = true;
  constructor(public httpService: HttpService, private alertService: AlertService) { }

  
  ngOnInit() {
    // this.isLoadingTab= true;
    // this.httpService.postHttpAuth("/reporte/distribucion",{})
    // .subscribe(data => {
    //   this.isLoadingTab= false;
    //   this.rowsReport = data.data;
    //   this.rerender();
    // });
   
    this.httpService.getHttpAuth("/conceptos/manuales/show/fulllist",{})
    .subscribe(data => {
      this.concepts=data.conceptos;
      this.isLoading = false;
      console.log(data);
    });
  }
  
  ngAfterViewInit(): void {
   this.dtTrigger.next();
   }

   ngOnDestroy(): void {
     // Do not forget to unsubscribe the event
     this.dtTrigger.unsubscribe();
   }
   
   apply(){
     console.log(this.groups);
     var data = {
       from:moment(this.startDateEdit).format("YYYY-MM-DD"),
       to: moment(this.endDateEdit).format("YYYY-MM-DD"),
       group:this.groups,
       type:this.type
     };
     this.isLoadingTab= true;
     this.httpService.postHttpAuth("/reporte/distribucion",data)
     .subscribe(data => {
       this.isLoadingTab= false;
       this.rowsReport = data.data.dist;
       this.txt = data.data.txt;
       this.disable_download = false;
       console.log(this.txt);
       this.rerender();
     });     
   }
   º
    generate() {  
    	if(this.txt){
    		new Angular2Txt(this.txt.data, 'neotec_' + " " + moment().format("DD-MM-YY HH:mm"), {
	    		fieldSeparator: this.txt.config.separator,
	        	quoteStrings: "",
	        	useBom : false
	    	});    
    	}    	
    }  
   
   rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
   }
   
   filter(event){    
     this.groups=event;
     this.isLoading = event.loading; 
     console.log(this.isLoading);    
   }

}
