import { Component, OnInit, ViewChild,Input,Output,EventEmitter } from '@angular/core';
import { FormatHoursPipe } from "@pipes/format-hours.pipe"
import { AmazingTimePickerService } from 'amazing-time-picker'; // this line you need
import {HttpService} from '@services/Http/http.service';
import { ScheduleService } from '@services/globals/schedule.service';
import { AlertService } from 'ngx-alerts';
import { Subject } from "rxjs/Subject"
import * as moment from "moment"

@Component({
  selector: 'app-special-schedule',
  templateUrl: './special-schedule.component.html',
  styleUrls: ['./special-schedule.component.scss']
})
export class SpecialScheduleComponent implements OnInit {
    
  @ViewChild("modalSchedule") modalSchedule: any;  
  @Input() canDelete?: boolean = false;
  @Input() data;
  @Input() open: Subject<any>;
  @Output() saveScheduleEmitter = new EventEmitter();
  @Output() deleteScheduleEmitter = new EventEmitter();
  scheduleMode: string = "schedule-template";
  dateToEdit: Date;
  dateToEditLabel: String;
  initTime: any="00:00";
  endTime: any="00:00";
  free_day_checkbox: boolean=false;
  restTime: number = 15;
  minutes: number = 15;
  titleModal: String = "Editar horario";
  listMinutes: any = [0,5,10,15,20,25,30];
  idUser: any;
  isLoading: boolean = false;
  templates: any= [];
  templateSelected: any=0;
  templateScheduleSelected:any="";
  detailsTemplate: Array<any> = [];
  detailSchedule: any="";
  idSpecial:any;
  userToEdit: string = null;
  sindicato;
  hora_sindicato;
  selected_horario;
  id_plantilla_detalle;


  constructor(private formatHoursPipe: FormatHoursPipe,
              private atp: AmazingTimePickerService,
              private alertService: AlertService,
              private httpService: HttpService,
              private scheduleService: ScheduleService) { }
  
  ngOnInit() {
      this.httpService.getHttpAuth("/plantilla/get/all")
     .subscribe(x => {
       this.templates=x.data;
       if(this.templates && this.templates[0])
         this.detailsTemplate = this.templates[0].plantilla_detalle;
     })
  }
  
  clickOnTimePicker($event,type) {

    const amazingTimePicker = this.atp.open({
      time: this.formatHoursPipe.transform($event.target.value,"hh:mm A","HH:mm"),
    });
    amazingTimePicker.afterClose().subscribe(time => {
      if(type=='start')
        this.initTime = moment(time,"HH:mm").format("hh:mm A");
      else
        this.endTime= moment(time,"HH:mm").format("hh:mm A");
    });
  }
  
  openModal(data):void {
  	  this.templateScheduleSelected = "";
      this.detailSchedule = "";
      this.sindicato = null;
      this.hora_sindicato = null;

      this.data = data;
      this.idSpecial = data.id;
      this.dateToEditLabel=moment(this.data.date).format("DD-MM-YYYY");
      this.dateToEdit = this.data.date;
      this.initTime = (!this.data.free_day ? this.data.entrada : "00:00");
      this.endTime = (!this.data.free_day ? this.data.salida : "00:00");
      this.restTime = (!this.data.free_day ? this.data.restTime : 0);
      this.userToEdit = (this.data.user ? this.data.user.nombre : null);
      this.free_day_checkbox = this.data.free_day;
      this.minutes = this.data.extraTime;
      this.idUser=this.data.id_usuario;
      this.scheduleMode="schedule-template";
      this.modalSchedule.open();
  }
  
  close(){
      this.modalSchedule.dismissv();
  }
  
  deleteSchedule(){
      this.isLoading=true;
      this.httpService.deleteHttpAuth("/horario/especial/"+this.idSpecial,{})
      .subscribe(x => {
          this.isLoading=false;
          this.deleteScheduleEmitter.emit();
          this.modalSchedule.dismissv();
      },err => this.isLoading=false);
  }
  
  saveSchedule(){
    console.log(this.scheduleMode);
      if(this.scheduleMode=="schedule-manual") {
        if(!this.free_day_checkbox){
          let validator = this.scheduleService.validateMaxTime(
            moment(this.initTime,"hh:mm A"),
            moment(this.endTime,"hh:mm A"),
            8,
            this.restTime);
          
          if(!validator){
            this.alertService.danger('El horario seleccionado no es valido, valida que el horario sea igual o menor a 8 horas incluyendo el tiempo de descanso.');
            return 0;
          }
        }
        
        var data= <any>{
          "aplicar_a" : "usuario",
          "hora_inicio" :  (this.free_day_checkbox ? "00:00" : moment(this.initTime,"hh:mm A").format("HH:mm")),
          "hora_fin" : (this.free_day_checkbox ? "00:00" : moment(this.endTime,"hh:mm A").format("HH:mm")),
          "id_usuario":this.idUser,
          "libre" : this.free_day_checkbox,
          "isSpecial":null,
          "id":null,
          "fecha":this.dateToEditLabel,
          "fecha_not_parsed":this.dateToEdit,
          "descanso":this.restTime,
          "minutos_extra":this.minutes,
          "tipo_horario":'manual',
          "sindicato":this.sindicato,
          "id_plantilla_detalle":null

        };
      }
      else{
        
        if(this.templateScheduleSelected=="" || this.detailSchedule=="")  {
            this.alertService.info("Debes seleccionar un horario valido");
            return 0;
        }
          
        var detail =  this.templates[this.templateScheduleSelected].plantilla_detalle[this.detailSchedule];
        console.log(detail);
        var data = <any>{
          "aplicar_a" : "usuario",
          "hora_inicio" :  (detail.libre ? "00:00" : detail.hora_entrada),
          "hora_fin" : (detail.libre ? "00:00" : detail.hora_salida),
          "id_usuario":this.idUser,
          "libre" : detail.libre,
          "isSpecial":null,
          "id":null,
          "fecha":this.dateToEditLabel,
          "fecha_not_parsed":this.dateToEdit,
          "descanso":detail.descanso,
          "minutos_extra":this.minutes,
          "tipo_horario":'plantilla',
          "sindicato":this.sindicato,
          "id_plantilla_detalle":this.id_plantilla_detalle
        };
      }
      

    
    this.isLoading=true;
    this.httpService.postHttpAuth("/horario/especial",data)
    .subscribe(x => {
      this.isLoading=false;
      var dateToAdd=moment(data.fecha_not_parsed).format("YYYY-MM-DD");
      data.fecha=dateToAdd;
      data.isSpecial=true;
      data.id=x.data.id;
      this.modalSchedule.dismissv();
      this.saveScheduleEmitter.emit(x);
      this.alertService.success("Horario agregado correctamente");
    },err=>this.isLoading=false);


  }


    changeTemplate(i){
    	this.detailsTemplate = this.templates[i].plantilla_detalle;
    	this.detailSchedule = ""; // set value to nothing.

    
    	//this.id_plantilla_detalle = null;
    
	    console.log(this.detailsTemplate);
	    /*if(this.selected_horario == "Libre"){
	      this.sindicato = null;
	      this.hora_sindicato = null;
	    }else{
	      this.sindicato = this.detailsTemplate[0].hora_sindicato;
	      this.hora_sindicato = this.detailsTemplate[0].hora_sindicato/60;
	    }*/
  	}


  	changeDetailTemplate(i){
  		let template_detail = this.detailsTemplate[i];
  		this.id_plantilla_detalle = this.detailsTemplate[i].id;
  		console.log(this.detailsTemplate[i]);
  		if(this.detailsTemplate[i].libre) {
  			this.sindicato = null;
  			this.hora_sindicato = null;
  		} else {
  			this.sindicato = this.detailsTemplate[i].hora_sindicato;
  			this.hora_sindicato = this.detailsTemplate[i].hora_sindicato / 60;
  		}

	    /*this.id_plantilla_detalle = this.detailsTemplate[i].id;
	    let text = $event.target.options[$event.target.options.selectedIndex].text;
	    this.selected_horario = text;
	    if(this.selected_horario == "Libre"){
	      this.sindicato = null;
	      this.hora_sindicato = null;
	    }else{
	        this.loadDetailTemplate2();
	    }*/
  	}

 


  loadDetailTemplate2() {


    
  }


}
