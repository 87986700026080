import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'days'
})
export class DayPipePipe implements PipeTransform {

  	transform(value: any): any {
	  	var minutes =  value;
	  	var str = '';
	  	if (minutes == 0) str = "0 días";

	  	if(minutes < 0) minutes *= -1; 
	  	var horas = Math.round((minutes / 60) % 8);
	  	var dias = Math.round((minutes / 60) / 8);

	  	str = dias + " días " + ((horas > 0) ? horas + " horas" : "");
	  	
	  	return ((value < 0 ) ? "-" : "") + str;     
  	}
}
