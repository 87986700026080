import { Component, OnInit } from '@angular/core';
import { SharedService } from "./../services/eventsEmmiter/shared.service";
import {Router} from "@angular/router";
import { RolesService } from './../services/globals/roles.service'
import {environment} from './../../environments/environment'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss',]
})
export class DashboardComponent implements OnInit {
  
  configJanusSuite: any = null;
  openConfig: boolean=false;
  openMarks: boolean=false;
  openReports:boolean=false;
  openSchedules:boolean=false;
  openNav:boolean=true;
  token: string = "";
  user: any;
  group: any;
  components : any;
  constructor(private _sharedService: SharedService, private router: Router, role : RolesService) {
  	this.components = role.get();  
    _sharedService.changeEmitted$.subscribe(
      info => {
        this.globalEvent(info)
      }
    );
  }
  
  globalEvent(info) {
    this.router.navigate(["/dashboard",{ outlets: { section: ['users',info.id] } }]);
  }
  
  openDrop(type){
    
    if(type=='config')
    this.openConfig=!this.openConfig;
    else if(type=='marks')
    this.openMarks=!this.openMarks;
    else if(type=='openReports')
    this.openReports=!this.openReports;
    else if(type=='openSchedules')
    this.openSchedules=!this.openSchedules;
  }
  
  logout(){
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("group");
    localStorage.removeItem("user_filters");
    this.router.navigate(["/login"]);
  }
  ngOnInit() {
    this.user=localStorage.getItem("user");
    this.token=localStorage.getItem("token");
    this.user=JSON.parse(this.user);
    this.group = localStorage.getItem("grupo")
    this.group = JSON.parse(this.group);

    this.group = this.group.grupos.empresa;
       
        
        
    this.configJanusSuite = environment.suite_conf; 
  }
  
}
