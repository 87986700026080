import { Component, OnInit, Input, Output} from '@angular/core';
import { Injectable } from '@angular/core';
import { CustomDateFormatter } from './custom-date-formatter.provider';
import { EventEmitter } from '@angular/core';
import { FormatHoursPipe } from "@pipes/format-hours.pipe"
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours
} from 'date-fns';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap/modal/modal.module';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarDateFormatter,
  DAYS_OF_WEEK,
  CalendarEventTimesChangedEvent,
  CalendarMonthViewDay
} from 'angular-calendar';
import 'hammerjs';
import * as moment from "moment"
import { Subject } from 'rxjs';
import 'twix';

@Component({
  selector: 'app-user-calendar',
  templateUrl: './user-calendar.component.html',
  styleUrls: ['./user-calendar.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ]
})

@Injectable()
export class UserCalendarComponent implements OnInit {

  @Input() events=[];
  @Input() viewWernch=true;
  @Input() viewLegend=true;
  @Input() modifyEvent:Subject<any>;
  @Input() addDays:Subject<any>;
  @Input() addInfoCalendar: Subject<any>;
  @Output() clickDay = new EventEmitter();
  @Output() changeDateE = new EventEmitter(); 
  @Output() clickConfig = new EventEmitter();
  @Output() openConfig = new EventEmitter();
  @Input() rxxTest:Subject<any>;
  locale: string = 'es';
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  eventsCalendar: CalendarEvent[] = [];
  weekendDays: number[] = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY];
  dtTrigger: Subject<any> = new Subject();
  refresh: Subject<any> = new Subject();
  viewDate: Date = new Date();
  view: string = "month";
  schedulesByDay: any = [];
  activeDayIsOpen: boolean=true;

  constructor(private formatHoursPipe: FormatHoursPipe,) { }

  ngOnInit() {


    var $this=this;
    this.addDays.subscribe(event =>{
        if(event){
          $this.updateCalendar(event);
        }
          
    });
    if(this.modifyEvent){
        this.modifyEvent.subscribe(event => {
            $this=this;
            let found=0;
            for (let i = 0; i < this.eventsCalendar.length; i++) {
                  if(moment(this.eventsCalendar[i].start).format("YYYY-MM-DD")==event.fecha){
                    found++;
                    if(event.isSpecial){
                      this.eventsCalendar[i].meta.isSpecial = true;
                      //this.eventsCalendar[i].meta.scheduleType = (event.tipo_horario);
                      this.eventsCalendar[i].meta.id = event.id;
                      this.eventsCalendar[i].meta.start = this.formattedHour(event.hora_inicio);
                      this.eventsCalendar[i].meta.end = this.formattedHour(event.hora_fin);
                      this.eventsCalendar[i].meta.restTime = event.descanso;
                      this.eventsCalendar[i].meta.extraTime = event.minutos_extra;
                    }  
                    this.eventsCalendar[i].title=(event.libre!=true ? this.formattedHour(event.hora_inicio) + " - " +  this.formattedHour(event.hora_fin) : "Libre");
                  }
            }
          
            if(found==0){
                let data = {
                  title: (event.libre!=true ? this.formattedHour(event.hora_inicio) + " - " +  this.formattedHour(event.hora_fin) : "Libre"),
                  start: moment(event.fecha).toDate(),
                  end: moment(event.fecha).toDate(),
                  meta: {
                    isSpecial:true,
                    scheduleType:"especial",
                    extraTime : event.minutos_extra,
                    restTime : event.descanso,
                    start:this.formattedHour(event.hora_inicio),
                    end:this.formattedHour(event.hora_fin),
                    id:event.id,
                    base_title: (event.libre!=true ? this.formattedHour(event.hora_inicio) + " - " +  this.formattedHour(event.hora_fin) : "Libre"),
                    free_day: event.libre,
                    holidays:false
                  }
                };
                this.eventsCalendar.push(data);
                this.refresh.next();
            }
        });
    }
    

  }

  buildCalendar(scheduleDetailed):void {
    for (let i = 0; i < scheduleDetailed.length; i++) {
        let selecDay = scheduleDetailed[i];
    
        
        if(!selecDay)
          continue;
        
        var base_title = (selecDay.libre!=true ? this.formattedHour(selecDay.entrada) + " - " +  this.formattedHour(selecDay.salida) : "Libre");        
      
        if(selecDay.feriado){
          base_title = (selecDay.libre!=true ? this.formattedHour(selecDay.entrada) + " - " +  this.formattedHour(selecDay.salida) : "Libre") + " - Feriado "+ selecDay.dia_feriado.nombre
        }
        var concept = false;
        if(selecDay.concepto_manual)
          base_title = (selecDay.libre!=true ? this.formattedHour(selecDay.entrada) + " - " +  this.formattedHour(selecDay.salida) : "Libre") + " - " + (selecDay.concepto_manual.concepto.concepto.codigo == 'VAC' ? "Vacaciones" :  selecDay.concepto_manual.concepto.concepto.nombre) ;
          
        this.eventsCalendar.push({
          title: base_title,
          start: moment(selecDay.fecha).toDate(),
          end: moment(selecDay.fecha).toDate(),
          meta: {
            isSpecial:(selecDay.especial && selecDay.tipo_horario=='especial' ? true : false),
            scheduleType: selecDay.tipo_horario,
            start:this.formattedHour(selecDay.entrada),
            end:this.formattedHour(selecDay.salida),
            sindicato:(selecDay.sindicato == true ? 'SINDICATO' : null),
            id:(selecDay.especial && selecDay.tipo_horario=='especial' ? selecDay.id_horario : null),
            base_title: base_title,
            free_day: selecDay.libre,
            holidays:selecDay.feriado,
            restTime:selecDay.descanso,
            extraTime:selecDay.minutos_extra
          }
        });
    }
  }

  updateCalendar(template){
    
      let stop=false;
      if(template.template && Object.keys(template.template).length === 0 && template.template.constructor === Object){
        this.eventsCalendar=[];
        this.refresh.next();
        stop=true;
      }
  
      if(template.buildSchedule && template.buildSchedule.length>0){
        this.buildCalendar(template.buildSchedule);
        return 0;
      }
      
      
      if(stop)
        return 0;
      //template.fecha_inicio
      //template.fecha_fin
      //template.plantilla.plantilla_detalle
      //template.horario_especial
      var nTemplate = template.template;
      var to = moment(nTemplate.fecha_fin, 'DD-MM-YYYY');
      var toFinal= moment(nTemplate.fecha_fin, 'DD-MM-YYYY');
      var from = moment(nTemplate.fecha_inicio, 'DD-MM-YYYY');
      var daysBetween = moment.twix(moment(from),moment(to)).iterate("days");
      var range=[];
      while(daysBetween.hasNext()){
          range.push(daysBetween.next().toDate())
      }
      var totalDays=range.length;

      this.schedulesByDay=[];
      this.eventsCalendar=[];
      var countDays=0;
      var iterate=true;      
      var detailTemplate=nTemplate.plantilla.plantilla_detalle;
      var specialSchedule=nTemplate.horario_especial;
      var holidays=nTemplate.dia_feriado;
      
      for (let i = 0; i < nTemplate.plantilla.plantilla_detalle.length; i++) {
          var days=detailTemplate[i].dias;
          
          to = moment(from).add((days-1), 'days');
          var itr = moment.twix(from,moment(to)).iterate("days");
          while(itr.hasNext()){
            var date=moment(itr.next().toDate());
            var find=false;
            var isHolidays = null;
            var id_special = null;
            var isFree = detailTemplate[i].libre;    
            var start = detailTemplate[i].hora_entrada;
            var end = detailTemplate[i].hora_salida;
            var sindicato = detailTemplate[i].hora_sindicato;
            
            
            specialSchedule.findIndex( (current, index) => {
                if(current.fecha==moment(date).format("DD-MM-YYYY"))
                {
                  id_special = current.id
                  isFree  = current.libre
                  start = current.hora_inicio;
                  end = current.hora_fin;
                  sindicato = current.hora_sindicato;
                }
            });
            if(holidays)
              holidays.findIndex( (current, index) => {
                  if(current.fecha==moment(date).format("DD-MM-YYYY"))
                  {
                    id_special = current.id
                    isFree  = current.libre
                    isHolidays= current.nombre
                  }
              });
            var base_title = (isFree!=true ? this.formattedHour(start) + " - " +  this.formattedHour(end) : "Libre");
            if(isHolidays)
                base_title = (isFree!=true ? this.formattedHour(start) + " - " +  this.formattedHour(end) : "Libre") + " - Feriado "+ isHolidays
            
      
            this.eventsCalendar.push({
              title: base_title,
              start: moment(date).toDate(),
              end: moment(date).toDate(),
              meta: {
                isSpecial:(id_special ? true : false),
                scheduleType: "horario",
                start:this.formattedHour(start),
                end:this.formattedHour(end),
                sindicato: sindicato != null ? 'SINDICATO' : null,
                id:(id_special ? id_special : null),
                base_title: base_title,
                free_day: isFree,
                holidays:isHolidays
              }
            });
            

            if(this.eventsCalendar.length>=totalDays){
              iterate=false;
              break;
            }
          }

          if(iterate==false)
            break;

          to = moment(to).add((1), 'days');
          from = to;

          if(i == detailTemplate.length-1 && iterate==true)
            i=-1;
      }

      this.refresh.next();

  }


  scheduleEdit(){
    this.openConfig.emit();
  }

  dayClicked(day){
    this.clickDay.emit(day);
  }
  
  changeDate(event){
    this.changeDateE.emit(this.viewDate);
  }

  formattedHour(date): void{
    return this.formatHoursPipe.transform(date,"HH:mm","hh:mm A")
  }
  
  editConfig(type){
    this.clickConfig.emit(type);
  }
}
