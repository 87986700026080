import { Injectable } from '@angular/core';
import * as moment from "moment"
import 'twix';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  constructor() { }
  
  
  validateMaxTime(init,end,limit,extra?){
    let valid = true;
    
    if(end<init)
    {
      end.add(1, 'days');
    }
    var duration = end.diff(init,"minutes");
      
    if(extra)
      duration = duration-extra;
    
      
    limit = limit*60;
    console.log(duration, limit);
    if(duration > limit || duration < 0)
      valid=false;
    
    return valid;
  }

  checkTimeDiff(init,end,extra?){
    
    if(end<init)
    {
      end.add(1, 'days');
    }
    var duration = end.diff(init,"minutes");
      
    if(extra)
      duration = duration-extra;
    
    return duration;
  }

  
}
