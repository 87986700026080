import { Component, AfterViewInit, OnInit,OnDestroy, ViewChild } from '@angular/core';
import { HttpService } from "@app/services/Http/http.service"
import { AlertService } from 'ngx-alerts'
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import * as moment from "moment"

@Component({
  	selector: 'app-concepts',
  	templateUrl: './concepts.component.html',
  	styleUrls: ['./concepts.component.scss']
})
export class ConceptsComponent implements OnInit {  
    @ViewChild(DataTableDirective)
    dtElement: DataTableDirective;
    dtTrigger: Subject<any> = new Subject();
    startDateEdit: Date = moment().toDate();
    endDateEdit: Date = moment().toDate();
    hierarchy_select : number = 4;
    groups: any = {};
    isLoadingTab: boolean = false;
    isLoading: boolean = false;
    rowsReport: any = [];
    hack = Array(20).fill(3);

    // options for datatables 
    dtOptions: any = {
      	pagingType: 'full_numbers',
      	pageLength: 10,     
      	lengthChange:true,
      	dom: 'Blfrtip',   
      	paging: true,
      	order : [],
      	bSort : false,
      	scrollX : true,       
      	columns : [
      		{width: "6px"},
      		{width: "188px"},
      		{width: "15px", class :"centered"},
      		{width: "88px", class : "centered"},
      		{class : "centered"},
      		{class : "centered"},
      		{class : "centered"},
      		{class : "centered"},
      		{class : "centered"},
      		{class : "centered"},
      		{class : "centered"},
      		{class : "centered"},
      		{class : "centered"},
      		{class : "centered"},
      		{class : "centered"},
      		{class : "centered"},
      		{class : "centered"},
      		{class : "centered"},
      		{class : "centered"},
      		{class : "centered"},
      		{class : "centered"}
       	],
     	buttons: [
          	{ extend: 'print', text: 'Imprimir', title: 'Reporte de conceptos especiales'},
          	{ extend: 'excel', title: 'Reporte de conceptos especiales', exportOptions:{
          		stripNewlines: false
        	} }
        ],
    	language : {
      		url: "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json",
      		emptyTable: "No existen ausencias con los parametros especificados"
    	}    	
    };


  	constructor(public httpService: HttpService, private alertService: AlertService) { }

  	ngOnInit() {
  		this.apply();
  	}

  	customizeXML(xlsx) {
  		//console.log(xlsx);
  	}
  
  	ngAfterViewInit(): void {
   		this.dtTrigger.next();
   	}

   	ngOnDestroy(): void {
     	// Do not forget to unsubscribe the event
     	this.dtTrigger.unsubscribe();
   	}
   
   	apply(){
     	var data = {
       		from:moment(this.startDateEdit).format("YYYY-MM-DD"),
       		to: moment(this.endDateEdit).format("YYYY-MM-DD"),
       		jerarquia:this.hierarchy_select,
     	};
     	this.isLoadingTab = true;
     	this.httpService.postHttpAuth("/reporte/conceptos-variables",data).subscribe(data => {
       		this.isLoadingTab = false;
       		this.rowsReport = data.report;
       		this.rerender();
     	});    
   	}
   
   	rerender(): void {
    	this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        	// Destroy the table first
        	dtInstance.destroy();
        	// Call the dtTrigger to rerender again
        	this.dtTrigger.next();
      	});
  	 }
   
   	filter(event){
     	this.groups=event;
     	this.isLoading = event.loading
     	console.log(this.groups);  
   	}

}
