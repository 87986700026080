import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ElementRef} from '@angular/core';
import { HttpService } from "@app/services/Http/http.service"
import { AlertService } from 'ngx-alerts'
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import * as moment from "moment"

@Component({
  	selector: 'app-periods',
  	templateUrl: './periods.component.html',
  	styleUrls: ['./periods.component.scss']
})
export class PeriodsComponent implements OnInit {
	@ViewChild('PDF') pdf : ElementRef<any>;
  	isLoadingTab: boolean = false;
  	isLoading: boolean = false;
  	report: any = {};
  	groups: any = {};
  	lib = [];
  	closures = [];
  	selectedClosure = null;
  	users = [];
  	watching = '';
  	date = new Date;
  	startDateEdit : any;
  	endDateEdit : any;

  	constructor(public httpService: HttpService, private alertService: AlertService) { }

  	ngOnInit() {
  		this.startDateEdit = this.date;
  		this.endDateEdit = this.date;
  		/*this.isLoadingTab = true;
  		this.httpService.getHttpAuth("/periodo/cortes",{}).subscribe(data => {
  			this.isLoadingTab = false;
  			this.closures = data.data;
  			this.selectedClosure = data.data[0].id;
  		});*/    	
  	}

  	apply(correo){  		
  		let x = {
  			'users' : this.users,
  			'correo' : correo,
  			'id_grupo' : this.groups.id_grupo, 
  			'id_tipo_grupo' : this.groups.id_tipo_grupo, 
  			'fecha_inicio' :  moment(this.startDateEdit).format("YYYY-MM-DD"),
  			'fecha_fin' : moment(this.endDateEdit).format("YYYY-MM-DD")
  		};
  		this.watching = '';
  		this.report = {};
  		this.isLoadingTab = true;
  		this.httpService.postHttpAuth("/reporte/periodos", x).subscribe(data => {
      		this.isLoadingTab= false;
      		this.lib = data.data;
      		if(this.lib.length > 0){
      			this.report = this.lib[0];
      			this.watching = this.report.codigo;
      		}  
      		if(correo){
      			this.alertService.success("Correos enviados satisfactoriamente");
      		}   		
    	});    
   	}

   	filter(event){ 
     	this.groups=event;
     	console.log(this.groups);
     	this.isLoading = event.loading;  
     	this.users = [];
     	if(!this.isLoading) {
     		this.users = event.users;
     	}  
   	}  

   	change_emp(t){
   		let index = 0;
   		this.isLoadingTab = true;
   		for(let i = 0; i < this.lib.length; i++) {
   			if(this.watching == this.lib[i].codigo){
   				if(t == 'prev') i--;
   				if(t == 'next') i++;
   				index = i;
   				break;
   			}
   		}
   		this.isLoadingTab = false;   		
   		if(index >= 0 && index < this.lib.length) {   			
   			this.report = this.lib[index];
   			this.watching = this.report.codigo;
   		}else{
   			this.alertService.info("No hay resultados");   			
   		}  		
   	}

   	descarga() {   		
   		let HTML = this.pdf.nativeElement.children[0].innerHTML;   		
   		var mywindow = window.open("'PRINT', 'height=400,width=1200'");
   		mywindow.document.write(HTML);
   		mywindow.document.close();
      	mywindow.focus();
      	mywindow.print();
      	mywindow.close();
   	}

}
