import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

	private role : number = 3;

	components : any = {
		sidebar : {
			home : true,
			cards : true,
			schedules : true,
			reports : true,
			configuration : true
		},
		users : {
			restrictAll : false,
			ban : [],
			employees : {
				create : true,
				edit : true,
				delete : true
			}
			
		},
		schedules : {
			restrictAll : false,
			create : true,
			edit : true,
			delete : true
		},
		cards : {
			restrictAll : false,
			cardsDetail : {
				watch : true,
				approve : true,
			},
			closure: true
		},
		reports : {
			watch : true
		},
		configuration : {
			watch : true
		}
	}

  	constructor() {   		
  		this.prepare();
  	}

  	loadRole() :void {

  	}

  set(ro) {
  	localStorage.setItem("role", ro); 
  	this.prepare();  	
  }

  private prepare() {  	
  	this.role = parseInt(localStorage.getItem("role"));
  	this.components.sidebar.schedules = true;
	this.components.sidebar.cards = true;
	this.components.sidebar.reports = true;
	this.components.sidebar.configuration = true;
	this.components.users.employees.create = true;
	this.components.users.employees.edit = true;
	this.components.users.employees.delete = true;
	this.components.reports.watch = true;
	this.components.configuration.watch = true;
	this.components.cards.restrictAll = false;
	this.components.schedules.restrictAll = false;
  	console.log(this.role);
  	if(this.role == 7) { // Administrador RH
  		this.components.users.ban = [8,7];
  	}else if(this.role == 8) { // Administrador T1
  		this.components.users.ban = [5,3,4];
  		// hide almost all sidebar
  		this.components.sidebar.schedules = false;
  		this.components.sidebar.cards = false;
  		this.components.sidebar.reports = false;
  		this.components.sidebar.configuration = false;
  		this.components.users.employees.create = false;
  		this.components.users.employees.edit = false;
  		this.components.users.employees.delete = false;
  		this.components.reports.watch = false;
  		this.components.configuration.watch = false;
  		this.components.cards.restrictAll = true;
  		this.components.schedules.restrictAll = true;
  	}else if(this.role == 3){ // Revisor
  		this.components.users.ban = [3,4,7,8];
  	} else if(this.role == 4) { // preparador
  		this.components.users.ban = [3,4,5,7,8];
  	}

  	 	console.log(this.components);
  }

  	get() {
  		this.prepare();
  		return this.components;
  	}

  	getRole() {
  		return this.role;  
  	}

  
}
