import { Component, OnInit, ViewChild } from '@angular/core';
import {HttpService} from './../../services/Http/http.service';
import { AlertService } from 'ngx-alerts';
import { Subject } from 'rxjs';
import * as moment from "moment"
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';


@Component({
  selector: 'app-checks-reports',
  templateUrl: './checks.component.html',
  styleUrls: ['./checks.component.scss']
})
export class ChecksComponent implements OnInit {
  
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  isLoadingTab: boolean = true;
  dtTrigger: Subject<any> = new Subject
  dtOptions: any = {
      pagingType: 'full_numbers',
      "language": {
                "url": "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json"
      },
      pageLength:25,
      "order": [],
      dom: 'Bfrtip',
      buttons: [
          { extend: 'print', text: 'Imprimir', title: 'Reporte de marcaciones' },
          { extend: 'excel', title: 'Reporte de marcaciones' }
      ],
    };
  type: Number = 1;
  status: string ="t";
  checks: any = [
      
  ];
  startDateEdit: Date = moment().toDate();
  endDateEdit: Date = moment().toDate();
  filters: any = null;
  
  constructor(private httpService: HttpService, private  alertService: AlertService) { }
  
  query():void{
    
    
    this.isLoadingTab=true;
    let i = moment(this.startDateEdit).format("YYYY-MM-DD");
    let f = moment(this.endDateEdit).format("YYYY-MM-DD");
    
    var filters = "";
    
    if(this.filters) 
      filters = `&id_grupo=${this.filters.id_grupo}&id_grupo_tipo=${this.filters.id_tipo_grupo}`;
      
      
    this.httpService.getHttpAuth("/marcaciones/get/old?tipo="+this.type+"&estado="+this.status+"&inicio="+i+"&fin="+f+filters)
    .subscribe(x => {
      this.isLoadingTab = false;  
      this.checks = x.data;
      this.rerender();
    });
  }
  
  rerender(): void {
    console.log(this.dtElement);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
  
  ngOnInit() {
            
    let i = moment(this.startDateEdit).format("YYYY-MM-DD");
    let f = moment(this.endDateEdit).format("YYYY-MM-DD");
    
    this.httpService.getHttpAuth("/marcaciones/get/old?tipo="+this.type+"&estado="+this.status+"&inicio="+i+"&fin="+f)
    .subscribe(x => {
      this.isLoadingTab = false;
      this.checks = x.data;
        this.dtTrigger.next();
    });
  }
  

  
  filter(filter){
    this.filters = filter; 
    this.query();
  }

}
