 import { Component, OnInit,ViewChild } from '@angular/core';
import {HttpService} from '@services/Http/http.service';
import { FormatHoursPipe } from "@pipes/format-hours.pipe"
import { AmazingTimePickerService } from 'amazing-time-picker'; // this line you need
import { HoursPipe } from "@pipes/hours.pipe"
import { GlobalsService } from '@services/globals/globals.service'
import { ActivatedRoute } from "@angular/router";
import { AlertService } from 'ngx-alerts';
import { ScheduleService } from '@services/globals/schedule.service';
import { UserCalendarComponent } from "./../../globals/user-calendar/user-calendar.component"
import { Subject } from 'rxjs';
import * as moment from "moment"
import { IgxDatePickerComponent, DateRangeType } from "igniteui-angular"

import 'twix';
@Component({
  selector: 'app-users-detail-component',
  templateUrl: './users-detail-component.component.html',
  styleUrls: ['./users-detail-component.component.scss']
})
export class UsersDetailComponentComponent implements OnInit {
    
  @ViewChild("modalSchedule") modalSchedule: any;
  @ViewChild("buttonSave") public btnSave: any;
  @ViewChild("uidt") dpicker: IgxDatePickerComponent;
  @ViewChild("modalConcept") modalConcept: any; 
   
  parentSubject:Subject<any> = new Subject();
  modalOpen:Subject<any> = new Subject();
  subjectAddDaysEdit:Subject<any> = new Subject();
  addDaysEventFromList:Subject<any> = new Subject();
  addInfoCalendar: Subject<boolean> = new Subject();
  rxSubjecttt: Subject<any> = new Subject();
  filtersCompany: any = { "sucursal":true, "departamento": false, "division":false, "centro_costo":false }
  companyCreateShow: any = { "sucursal":false, "departamento": false, "division":false, "centro_costo":false }
  employeeData: boolean = false;
  supervisorData: boolean = false;
  noAdminData: boolean = false;
  mark_app: boolean=true;
  mark_web: boolean=true;
  from: Date;
  to: Date;
  viewDate: Date = new Date();
  locale: string = 'En';
  mark_app_supervisor: boolean = true;
  mark_web_supervisor: boolean = true;
  name:string ="";
  lastname:string ="";
  sucursal:string ="";
  centro_costo:string ="";
  username:string ="";
  password:string ="";
  email:string ="";
  phone:string ="";
  id_employee:string ="";
  pin:string ="";
  fingerPrintId:string ="";
  readWrite:string ="";
  card:string ="";
  type: string = "admin";
  sucursalSelect: number=0;
  departament: boolean=false;
  departamentSelect: number= 0;
  division: boolean=false;
  divisionSelect: number= 0;
  ccenterSelect: number=0;
  sucursales: any=[];
  deparments: any= [];
  divisions: any= [];
  centers: any= [];
  free_day_checkbox: boolean=false;
  initTime: any="00:00";
  endTime: any="00:00";
  id_empresa: number;
  calendar=true;
  id_schedule: Number=0;
  dateToEdit: Date;
  dateToEditLabel: String;
  events=[];
  conceptsList=[];
  deleteSpecial: boolean=false;
  selectedEvent: any;
  startDateEdit: Date;
  endDateEdit: Date;
  templateSelected: any=0;
  templates: any= [];
  minutes: number= 0;
  nameSchedule:string="";
  restTime: number = 15;
  divisionSelectArray: any= [];
  incapacity_found: any = null;
  vacation_found: any = null;
  id_rol: Number = null;
  isLoading: boolean = false;
  isLoadingSave: boolean = false;
  hora;
  permisionsSupervisor: any = {
    "view":true,
    "edit":true,
    "approve":true
  };
  options: any = {
    text:"Guardar",
    disabled: false,
    type:"btn btn-primary",
    icon:"fa fa-spinner fa-spin",
    isLoading:false,
    principal_icon:"fa fa-floppy-o"
  };

  listMinutes: any = [0,5,10,15,20,25,30];
  conceptSelected : any = "";
  startDateConcept : Date = new Date;
  endDateConcept : Date  = new Date;
  isLoadingConcept: boolean = false;
  actualDateCalendar: Date= new Date;
  registeredConcepts: any = [];
  title_concept:String="Conceptos agregados";
  dataSpecial: any = <any>{}; 
  sindicato;
  hora_sindicato;

  calendario = [];
  fecha;
  entrada;
  salida;
  minutos_extra = "";
  descanso: string = "";
  turno: string = "";
  isLoadingProfile = true;
  users: any = [];
  type_user_filter: string = "3,4,5";
  selectedUser;

  estado;
  estado2 = 1;
  options2: any = {
    text:"Guardar",
    disabled: false,
    type:"btn btn-primary",
    icon:"fa fa-spinner fa-spin",
    isLoading:false,
    principal_icon:"fa fa-floppy-o"
  };

 

  constructor(private formatHoursPipe: FormatHoursPipe,
              private hours: HoursPipe,
              private atp: AmazingTimePickerService,
              private httpService: HttpService,
              private _route: ActivatedRoute,
              private alertService: AlertService,
              private gs: GlobalsService,
              private scheduleService: ScheduleService) { }

    ngOnInit() {
    
        this.httpService.getHttpAuth("/conceptos/manuales")
        .subscribe(data => {
          this.conceptsList =  data.conceptos;
        });

        this.httpService.getHttpAuth("/usuario/"+this._route.snapshot.params.id).subscribe(data => {

            this.httpService.getHttpAuth("/plantilla/get/all").subscribe(x => { this.templates=x.data });

            this.name=data.data.usuario.nombre;
            this.fingerPrintId=data.data.usuario.codigo_huella;
            this.lastname=data.data.usuario.apellido;
            this.username=data.data.usuario.nombre_usuario;
            this.email=data.data.usuario.email;
            this.id_rol=data.data.usuario.id_rol;
            this.phone=(data.data.relaciones.telefono ? data.data.relaciones.telefono.numero : "");
            this.id_employee=data.data.usuario.codigo;
    	
    		this.estado = data.data.usuario.id_estado;
            this.estado2 = data.data.usuario.id_estado;
            var group=null;
            group=localStorage.getItem("grupo"),
            group=JSON.parse(group);
            var table=group.tabla;
            this.id_empresa=group.id;

            this.sucursales = data.data.grupos.sucursal;
            this.deparments = data.data.grupos.departamento;
            this.divisions = data.data.grupos.division;
            this.centers = data.data.grupos.centro_costo;

            let rol = data.data.usuario.id_rol;

            if(rol != 3 && rol != 7 && rol != 8) {
                this.sucursalSelect =  this.getSelectedIDfromGroup(this.sucursales);
                this.departamentSelect = this.getSelectedIDfromGroup(this.deparments);
                if(rol == 5) {
                    this.ccenterSelect = this.getSelectedIDfromGroup(this.centers);
                    this.divisionSelect = this.getSelectedIDfromGroup(this.divisions);
                    this.type="colaborador";
                    this.mark_app=data.data.relaciones.permiso.marcar_app;
                    this.mark_web=data.data.relaciones.permiso.marcar_web;
                    this.employeeData=true;
                    this.supervisorData=false;
                    this.companyCreateShow.sucursal=true;
                    this.companyCreateShow.departamento=true;
                    this.companyCreateShow.division=true;
                    this.companyCreateShow.centro_costo=true;
                    this.noAdminData=true;
                    this.incapacity_found = this.hours.transform(data.data.usuario.fondo_incapacidad,"HH:mm");           
                    this.vacation_found =  this.hours.transform(data.data.usuario.fondo_vacaciones ,"HH:mm");

                    this.loadCalendar();                    
                } else {
                    this.type="preparador";
                    this.employeeData=false;
                    this.supervisorData=true;
                    this.companyCreateShow.sucursal=true;
                    this.companyCreateShow.departamento=true;
                    this.companyCreateShow.division=true;
                    this.companyCreateShow.centro_costo=false;
                    this.noAdminData=true;
                    this.permisionsSupervisor.view = data.data.relaciones.permiso.ver; 
                    this.permisionsSupervisor.edit = data.data.relaciones.permiso.editar;
                    this.permisionsSupervisor.approve=data.data.relaciones.permiso.approve_extra;

                    this.divisions.forEach(item => {
                        if(item.selected) this.divisionSelectArray.push(item.id);
                    });         
                }                
            } else {
            	if(rol == 3) this.type = "revisor";
            	else if(rol == 7) this.type = "adminRH";
            	else if(rol == 8) this.type = "adminIT";
                
                this.employeeData=false;
                this.supervisorData=false;
                this.companyCreateShow.sucursal=false;
                this.companyCreateShow.departamento=false;
                this.companyCreateShow.division=false;
                this.companyCreateShow.centro_costo=false;
                this.noAdminData=false;
            }

            if(data.data.relaciones.permiso.ver==true) this.readWrite="view";
            if(data.data.relaciones.permiso.editar==true) this.readWrite="edit";
        });
    }



    getByChild(parent, tipo, id)  {
        if (id == 0) {
            if(tipo == 'departamento'){
                this.sucursalSelect = 0; 
                this.divisionSelectArray = []; 
                this.deparments = [];                 
                this.divisions = []; 
                this.centers = []; 
            }
            if(tipo == 'division') {
                this.departamentSelect = 0;
                this.divisionSelectArray = []; 
                this.divisions = [];
                this.centers = [];
            }
            if(tipo == 'centro-costo') {
                this.ccenterSelect = 0;
                this.centers = [];
            }

            return false;
        }

           
        //this.body.divisiones = null;       
        if(tipo != '') {
           this.httpService.getHttpAuth("/"+tipo+"/get/"+id).subscribe(data => {                           
                if(tipo == 'departamento') { 
                    if(data.data.length == 0){
                        this.deparments= [];
                        this.alertService.info("Esta sucursal no tiene divisiones disponibles");
                    } else {                        
                        this.deparments = data.data; 
                        this.divisions = []; // limpia los arreglos de division y centro de costo
                        this.centers = []; 
                        this.divisionSelectArray = []; 
                        this.departamentSelect = 0;
                    }                    
                } 
                if(tipo == 'division') {
                    if(data.data.length == 0){
                        this.divisions = [];
                        this.alertService.info("Esta división no tiene departamentos disponibles");
                    } else {
                        this.divisions = data.data; 
                        this.divisionSelectArray = []; 
                        this.centers = [];  
                        this.ccenterSelect = 0;
                    }                    
                } 
                if(tipo == 'centro-costo') {                  
                    if(data.data.length == 0) {
                        this.centers = [];
                        this.alertService.info("Este departamento no tiene centro de costos disponibles");
                    } else {
                        this.centers = data.data; 
                        this.ccenterSelect = 0;
                    }                   
                }                                    
            });        
        }        
    }

    getSelectedIDfromGroup(group) : number {
        if(group.length == 0) return 0;
        
        return group.find((item, i) => { if(item.selected) return item.id; }).id;
    }

  selectType($ev){
    if(this.type=="colaborador"){
        this.employeeData=true;
        this.supervisorData=false;
        this.companyCreateShow.sucursal=true;
        this.companyCreateShow.departamento=true;
        this.companyCreateShow.division=true;
        this.companyCreateShow.centro_costo=true;
        this.noAdminData=true;
    }
    else if(this.type=="preparador"){
        this.employeeData=false;
        this.supervisorData=true;
        this.companyCreateShow.sucursal=true;
        this.companyCreateShow.departamento=true;
        this.companyCreateShow.division=true;
        this.companyCreateShow.centro_costo=false;
        this.noAdminData=true;
    }
    else if(this.type=="adminIT" || this.type=="adminRH" || this.type=="revisor"){
        this.employeeData=false;
        this.supervisorData=false;
        this.companyCreateShow.sucursal=false;
        this.companyCreateShow.departamento=false;
        this.companyCreateShow.division=false;
        this.companyCreateShow.centro_costo=false;
        this.noAdminData=false;
    }
  }

  changeDate(e){
    var $this=this;
    setTimeout(function(){
        $this.updateCalendar();
    },100);

  }
  changeDateE(event){
    this.actualDateCalendar = event; 
  }
  
  updateCalendar(){
    
    this.dpicker.disabledDates = [];
    
    if(this.endDateEdit && !this.startDateEdit){
      this.alertService.info("Debes seleccionar una fecha de inicio");
      this.endDateEdit=null;
      return 0;
    }
    else{
      
      var disableFrom = moment(this.startDateEdit).subtract(100, 'years').toDate();
      var dateMax = moment(this.startDateEdit).add(3, 'months').toDate();
      var dateMin = moment(this.startDateEdit).add(6, 'days').toDate();

      this.dpicker.disabledDates = [
        {type: DateRangeType.Between,dateRange:[dateMax, new Date("2200-1-15")]},
        {type: DateRangeType.Between,dateRange:[disableFrom, dateMin]}
      ];
      
    }

    
    if(this.startDateEdit && this.endDateEdit){
        var template = {
          fecha_inicio:this.startDateEdit,
          fecha_fin:this.endDateEdit,
          plantilla:this.templates[this.templateSelected],
          horario_especial:[]
        }

        this.subjectAddDaysEdit.next({
          template:template,
          buildSchedule: []
        });
    }
  }

   abrirModal(){
    this.hora = moment().format("hh:mm A");
    var fecha = moment().format("YYYY-MM-DD");
    this.fecha = fecha;    
    if(this.calendario.length != 0){
      this.getSelectedCalendarByDate( fecha );
    }
  }


  saveScheduleByUsers(){
    var ids=[this._route.snapshot.params.id];
    // for (let i = 0; i < this.usersToEdit.length; i++) {
    //     ids.push(this.usersToEdit[i].id);
    // }
    this.options2.disabled = true;
    this.options2.isLoading = true;
    var data={
      "id_plantilla" : this.templates[this.templateSelected].id,
      "minutos_extra" : this.minutes,
      "fecha_inicio"	: moment(this.startDateEdit).format("DD-MM-YYYY"),
      "fecha_fin"    : moment(this.endDateEdit).format("DD-MM-YYYY"),
      "id" : ids
    }

    this.httpService.postHttpAuth("/horario/store/users",data)
    .subscribe(x => {
      (<any>$("#modalEditSchedule")).modal("hide");
      this.loadCalendar();
       this.options2.disabled = false;
    	this.options2.isLoading = false;
      this.alertService.success("Horario agregado correctamente");
    });
  }

  saveUser(){



    var base_data=<any>{
        "nombre" : this.name,
        "apellido" : this.lastname,
        "nombre_usuario": this.username ,
        "email" : this.email,
        "telefono" : this.phone,
        "id_grupo" : 1,
        "id_tipo_grupo" : 1,
        "codigo":null,
        "id_rol":null,
        "permisos" : {
          "ver" : true,
          "editar" : true,
          "aprobar":true,
          "marcar_web" : true,
          "marcar_app" : true
       },
       "id_estado": this.estado
    };

    base_data.password = this.password;
    
    
    var id_rol=0;
    if(this.type=="adminIT")
    {
      base_data.id_rol=8;

    }
    if(this.type=="adminRH")
    {
      base_data.id_rol=7;

    }
    else if(this.type=="revisor")
    {
      base_data.id_rol=3;

    }
    else if(this.type=="preparador")
    {
      base_data.id_rol=4;

      base_data.permisos.marcar_app=this.mark_app_supervisor;
      base_data.permisos.marcar_web=this.mark_web_supervisor;
      base_data.permisos.ver = this.permisionsSupervisor.view;
      base_data.permisos.editar = this.permisionsSupervisor.edit;
      base_data.permisos.aprobar = this.permisionsSupervisor.approve;      
      
      
      if (this.permisionsSupervisor.edit) {
        base_data.permisos.ver = true;
        base_data.permisos.editar = true;
      }
      
      if (!this.permisionsSupervisor.view) {
        base_data.permisos.editar = false;
        base_data.permisos.aprobar = false;
      }
      
      if(this.sucursalSelect!=0){
          base_data.id_grupo=this.sucursalSelect;
          base_data.id_tipo_grupo=2;

          if(this.departamentSelect && this.departamentSelect!=0){
            base_data.id_grupo=this.departamentSelect;
            base_data.id_tipo_grupo=3;
          }

          if (this.divisionSelect || this.divisionSelectArray.length > 0) {
              base_data.divisiones = this.divisionSelectArray;
              base_data.id_grupo = this.departamentSelect;
              base_data.id_tipo_grupo = 3;
          }
          
      }else{
        this.alertService.info("Debe seleccionar al menos una sucursal");
        return 0;
      }

    }
    else if(this.type=="colaborador"){
        base_data.id_rol=5;
        base_data.id_tipo_grupo=5;
        base_data.id_grupo=this.ccenterSelect;

        if(this.ccenterSelect==0){
          this.alertService.info("debe seleccionar un centro de costo para el empleado");
          return 0;
        }

        if(this.readWrite=="view"){
          base_data.permisos.ver=true;
          base_data.permisos.editar=false;
        }
        else  {
          base_data.permisos.ver=false;
          base_data.permisos.editar=true;
        }

        base_data.permisos.marcar_app=this.mark_app;
        base_data.permisos.marcar_web=this.mark_web;
        base_data.codigo=this.id_employee;
        base_data.codigo_huella=this.fingerPrintId;
        base_data.fondo_incapacidad= moment.duration(this.incapacity_found).asMinutes();
        base_data.fondo_vacaciones = moment.duration(this.vacation_found).asMinutes();
    }     
    
    this.isLoadingSave = true;
    this.httpService.putHttpAuth("/usuario/"+this._route.snapshot.params.id,base_data).
    subscribe(data => {
        this.isLoadingSave = false;
        this.alertService.success('Usuario modificado exitosamente');
      },
      error => this.isLoadingSave = false
    );



  }
  

  dayClicked(data){
    if(this.gs.getRole()!=5){
     
      this.dataSpecial.date = moment(data.date);
      this.dataSpecial.entrada = ((data.events[0] && !data.events[0].meta.free_day) ? data.events[0].meta.start : "00:00")
      this.dataSpecial.salida = ((data.events[0] && !data.events[0].meta.free_day ? data.events[0].meta.end : "00:00"));
      this.dataSpecial.restTime = (( data.events[0] && !data.events[0].meta.free_day) ? data.events[0].meta.restTime : 15);
      this.dataSpecial.extraTime =( data.events[0] ? data.events[0].meta.extraTime : 15);
      this.dataSpecial.free_day = ((!data.events[0] ? false : data.events[0].meta.free_day ? true : false ));
      this.dataSpecial.id_usuario = this._route.snapshot.params.id;
      this.dataSpecial.id = ((data.events[0] && data.events[0].meta.id) ? data.events[0].meta.id : null);
      this.modalSchedule.openModal(this.dataSpecial);
    }
  
  }

  
  

  editSchedule(type){
    if(type=='schedule')
      (<any>$("#modalEditSchedule")).modal("show");
    else if(type=='concept')
      (<any>$("#modalConcepts")).modal("show");
    else if(type=='concept_edit'){
      this.registeredConcepts = [];
      this.isLoadingConcept=true;
      this.httpService.postHttpAuth("/conceptos/manuales/show/bymonth",{
        month:moment(this.actualDateCalendar).format("YYYY-MM-DD"),
        user: this._route.snapshot.params.id
      })
      .subscribe(x => {
        this.isLoadingConcept=false;
        this.registeredConcepts = x.conceptos;
      },err=>this.isLoadingConcept=false);
      this.modalOpen.next();
    }  
  }

  deleteSchedule(){
      this.loadCalendar();
  }
  
  clickOnTimePicker($event,type){

    const amazingTimePicker = this.atp.open({
      time: this.formatHoursPipe.transform($event.target.value,"hh:mm A","HH:mm"),
    });
    amazingTimePicker.afterClose().subscribe(time => {
      if(type=='start')
        this.initTime = moment(time,"HH:mm").format("hh:mm A");
      else
        this.endTime= moment(time,"HH:mm").format("hh:mm A");
    });
  }


  saveSchedule(){
    if(!this.free_day_checkbox){
      let validator = this.scheduleService.validateMaxTime(
        moment(this.initTime,"hh:mm A"),
        moment(this.endTime,"hh:mm A"),
        8,
        this.restTime);
      
      if(!validator){
        this.alertService.danger('El horario seleccionado no es valido, valida que el horario sea igual o menor a 8 horas incluyendo el tiempo de descanso.');
        return 0;
      }
    }
    

    var data={
      "aplicar_a" : "usuario",
      "hora_inicio" :  (this.free_day_checkbox ? "00:00" : moment(this.initTime,"hh:mm A").format("HH:mm")),
      "hora_fin" : (this.free_day_checkbox ? "00:00" : moment(this.endTime,"hh:mm A").format("HH:mm")),
      "id_usuario":this._route.snapshot.params.id,
      "libre" : this.free_day_checkbox,
      "isSpecial":null,
      "id":null,
      "fecha":this.dateToEditLabel,
      "fecha_not_parsed":this.dateToEdit,
      "sindicato":this.hora_sindicato,
      "descanso":this.restTime,
      "minutos_extra":this.minutes
    };

    this.httpService.postHttpAuth("/horario/especial",data)
    .subscribe(x => {
      var dateToAdd=moment(data.fecha_not_parsed).format("YYYY-MM-DD");
      data.fecha=dateToAdd;
      data.isSpecial=true;
      data.id=x.data.id;
      this.parentSubject.next(data);
      this.alertService.success('Horario agregado exitosamente');
      (<any>$("#modalSchedule")).modal("hide");
    });


  }

  resetForm(){
    this.name="";
    this.lastname="";
    this.username="";
    this.email="";
    this.password="";
    this.phone="";
    this.sucursalSelect=0;
    this.departamentSelect=0;
    this.divisionSelect=0;
    this.deparments=[];
    this.divisions= [];
  }
  
  saveConcepts(){
    let data = {
      id_usuario: this._route.snapshot.params.id,
    	id_concepto: this.conceptSelected,
    	fecha_inicio: moment(this.startDateConcept).format("YYYY-MM-DD"),
    	fecha_fin:  moment(this.endDateConcept).format("YYYY-MM-DD")
    };
    this.isLoadingConcept=true;
    this.httpService.postHttpAuth("/conceptos/manuales",data)
    .subscribe(x => {
      (<any>$("#modalConcepts")).modal("hide");
      this.isLoadingConcept=false;
      this.alertService.success("Horario agregado correctamente");
      this.loadCalendar();
      
    }, err => this.isLoadingConcept=false );
  }
  
  deleteConcepts(i){
    
    var id=this.registeredConcepts[i].id_concepto_manual;
    this.modalConcept.dismissv();
    this.isLoadingSave=true;
    this.httpService.deleteHttpAuth("/conceptos/manuales/"+id,{})
    .subscribe(x => {
      this.isLoadingSave=false;
      this.alertService.success("Conceptos eliminados de manera correcta");
      this.loadCalendar();
    },err =>{
      this.isLoadingSave=false;
      this.modalConcept.open();
    });
  }
  
  loadCalendar():void {
    this.isLoading=true;
    this.httpService.getHttpAuth("/horario/calendario/"+this._route.snapshot.params.id)
    .subscribe(x => {
      this.calendario = x.data;
      this.isLoading=false;
      this.addDaysEventFromList.next({
        template:{},
        buildSchedule: x.data
      });
    }, err => this.isLoading=false);
  }

   getSelectedCalendarByDate(selectedDate: string) { 
    var calendario = this.calendario;
    var bandera = false;
    var entrada = "";
    var salida = "";
    var descanso = "";
    var turno = "";
    var minutos_extra = "";
    calendario.forEach(function(value, index){
      if (calendario[index]){
        if(calendario[index].fecha){
          if(calendario[index].fecha === selectedDate && calendario[index].entrada && calendario[index].salida){
            entrada = calendario[index].entrada;
            salida = calendario[index].salida;
            descanso = calendario[index].descanso;
            turno = calendario[index].turno;
            minutos_extra = calendario[index].minutos_extra;
            bandera = true;
            (<any>$("#modalMark")).modal("show");
          }
        }
      }
    });
    var momentE=moment(this.formatHoursPipe.transform(entrada,"hh:mm A","HH:mm"),"hh:mm");
    var momentS=moment(this.formatHoursPipe.transform(salida,"hh:mm A","HH:mm"),"hh:mm");
    var momentEn=moment(momentE).format("hh:mm A");
    var momentSa=moment(momentS).format("hh:mm A");
    this.entrada = momentEn;
    this.salida = momentSa;
    this.descanso = descanso + ' min';
    this.turno = turno;
    this.minutos_extra = minutos_extra + " minutos";
    if(bandera == false){    
      this.alertService.danger('El día de hoy no tiene horario asignado');
    }
  }


  save(){
    this.options.disabled = true;
    this.options.isLoading = true;
    let data = {
      'tipo': 'web',
      'marcacion_app': false
    };
    this.httpService.postHttpAuth("/marcaciones", data)
    .subscribe(x => {
      (<any>$("#modalMark")).modal("hide");
      this.alertService.success("Marcación realizada satisfactoriamente");
      this.options.disabled = false;
      this.options.isLoading = false;
    });
  }
  


  
  
  async ch(data) {
      var data_parsed={
        "aplicar_a" : "usuario",
        "hora_inicio" : (data.data.libre ? "00:00" : moment(data.data.entrada.date).format("HH:mm")),
        "hora_fin" : (data.data.libre ? "00:00" : moment(data.data.salida.date).format("HH:mm")),
        "id_usuario":this._route.snapshot.params.id,
        "libre" : data.data.libre,
        "isSpecial":true,
        "id":data.data.id,
        "fecha":moment(data.data.fecha).format("YYYY-MM-DD"),
        "fecha_not_parsed": moment(data.data.fecha),
        "descanso":data.data.descanso,
        "minutos_extra":data.data.minutos_extra
      };
      
      var dateToAdd=moment(data.data.fecha).format("YYYY-MM-DD");
      data_parsed.fecha=dateToAdd;
      this.parentSubject.next(data_parsed);
  }
  
}
