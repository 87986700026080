import { Component, OnInit,ViewChild,AfterViewInit,EventEmitter,Output } from '@angular/core';
import { AlertService } from 'ngx-alerts'
import { HttpService } from "@services/Http/http.service";
import { ScheduleEditInterface } from "./schedule-edit-interface"
import { IgxDatePickerComponent, DateRangeType } from "igniteui-angular"
import * as moment from "moment"
import {Subject} from "rxjs"
@Component({
  selector: 'app-users-edit-schedule',
  templateUrl: './users-edit-schedule.component.html',
  styleUrls: ['./users-edit-schedule.component.scss']
})
export class UsersEditScheduleComponent implements OnInit {

  constructor(private alertService: AlertService,private httpService: HttpService) { }
  
  @ViewChild("modalSchedule") modalSchedule;
  @ViewChild("uidt") dpicker: IgxDatePickerComponent;
  @Output() saved = new EventEmitter<any>();
  @Output() userDeleted = new EventEmitter<any>();
  isLoading: boolean = false;
  templates: Array<any> = [];
  subjectAddDaysEdit: Subject<any> = new Subject();
  data : ScheduleEditInterface  =  <any>{}
  
  ngOnInit() {
      this.data.id=[];
      this.data.minutos_extra=15;
      this.data.id_plantilla=0;
      this.data.nombre='Horario usuario';
      this.data.users = [];
      this.data.fecha_inicio=null;
      this.data.fecha_fin=null;
    this.httpService.getHttpAuth("/plantilla/get/all")
    .subscribe(x => {
        this.templates = x.data;
        this.data.id_plantilla=0;
    });
  } 
  
  ngAfterViewInit(){
     
  }
  
  open(){
      if(this.data.id.length==0) {
          this.alertService.info("Debes seleccionar al menos un usuario de la lista para poder editar horarios");
          return 0;
      }
      
      this.data.minutos_extra=15;
      this.data.id_plantilla=0;
      this.data.nombre='Horario usuario';
      this.data.fecha_inicio=null;
      this.data.fecha_fin=null;
      
      this.modalSchedule.open();
  }
  
  dismiss(){
      this.modalSchedule.dismiss();
  }
  
  changeDate() {
    var $this = this;

    setTimeout(function() {

      $this.updateCalendar();

    }, 100);

  }

  updateCalendar() {
    
    
    
    this.dpicker.disabledDates = [];
    
    
    if(this.data.fecha_fin && !this.data.fecha_inicio){
      this.alertService.info("Debes seleccionar una fecha de inicio");
      this.data.fecha_fin=null;
      return 0;
    }
    else{
      
      var disableFrom = moment(this.data.fecha_inicio).subtract(100, 'years').toDate();
      var dateMax = moment(this.data.fecha_inicio).add(3, 'months').toDate();
      var dateMin = moment(this.data.fecha_inicio).add(5, 'days').toDate();

      this.dpicker.disabledDates = [
        {type: DateRangeType.Between,dateRange:[dateMax, new Date("2200-1-15")]},
        {type: DateRangeType.Between,dateRange:[disableFrom, dateMin]}
      ];
      
    }

    if (this.data.fecha_fin && this.data.fecha_inicio) {
      var template = {
        fecha_inicio: this.data.fecha_inicio,
        fecha_fin: this.data.fecha_fin,
        plantilla: this.templates[this.data.id_plantilla],
        horario_especial: []
      }

      this.subjectAddDaysEdit.next({template});

    }
  }
  
  addUserToList(user){
      var find = false;
      var index = this.data.users.findIndex(function(item, i){
          return user.id === item.id
      });
      if(index==-1){
          this.data.users.push({
            name:user.nombre,
            id:user.id  
          });
          this.data.id.push(user.id);
          find = true;
          return find;
      }
      else
      {    
          this.data.id = this.data.id.filter(e => e !== this.data.users[index].id);
          this.data.users.splice(index, 1);
          return find;
      }
  }
  
  saveScheduleByUsers() {  	
    var data = JSON.parse(JSON.stringify(this.data));
    data.id_plantilla = this.templates[this.data.id_plantilla].id;  	
    data.fecha_inicio = moment(this.data.fecha_inicio).format("DD-MM-YYYY");
    data.fecha_fin = moment(this.data.fecha_fin).format("DD-MM-YYYY");
    data.minutos_extra = this.data.minutos_extra;
    this.isLoading= true;
    

    this.httpService.postHttpAuth("/horario/store/users", data)
      .subscribe(x => {
        this.data.id=[];
        this.data.minutos_extra=15;
        this.data.id_plantilla=0;
        this.data.nombre='Horario usuario';
        this.data.users = [];
        this.data.fecha_inicio=null;
        this.data.fecha_fin=null;
        this.isLoading = false;
        this.saved.emit({
            data:x
        });
        this.modalSchedule.dismiss();
        this.alertService.success("Horario agregado correctamente");
    }, err => this.isLoading = false);
  }
  
  deleteUserList(user){
      this.data.id = this.data.id.filter(e => e !== user.id);
      var index = this.data.users.findIndex(function(item, i){
          return user.id === item.id
      });
      this.data.users.splice(index, 1);
      
      this.userDeleted.emit(user.id);
  }
  
  deleteList(){
      this.data.id=[];
      this.data.users=[];
  }
  
  
}
