import { Component, OnInit,ViewChild } from '@angular/core';
import { AlertService } from 'ngx-alerts';
import { Subject } from 'rxjs';
import * as moment from "moment"
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { AgmCoreModule } from '@agm/core';
import { HttpService } from "@services/Http/http.service"


@Component({
  selector: 'app-marks-phone',
  templateUrl: './marks-phone.component.html',
  styleUrls: ['./marks-phone.component.scss']
})
export class MarksPhoneComponent implements OnInit {

    users: any = [];
    lat: number = 9.010131;
    lng: number = -79.474144;
    markers: any = [];
    marks = [] = []
    userName: String="";
    dateMark: String=null;
    statusMark: String="";
    page: number = 1;
    previousDisable=true;
    nextDisable=false;
    type: String = "a";
    reason: String = "";
    selected: any = null;
    isLoadingTab: boolean = true;
    user_note: String = "";
    options: any = {
      text:"Guardar",
      disabled: false,
      type:"btn btn-primary",
      icon:"fa fa-spinner fa-spin",
      isLoading:false
    };

  constructor(private httpService: HttpService,private alertService: AlertService) { }
  checkMark(object){
    this.user_note=object.nota_colaborador;
    this.selected = object;
    this.userName=object.nombre;
    this.dateMark=object.dia + " " + object.hora;
    this.statusMark=object.estado;
    this.lat = object.latitud;
    this.lng = object.longitud;
    this.markers=[{
      lat:object.latitud,
      lng:object.longitud,
      label:"Marcación 1"
    }];
    (<any>$("#modalMarksMap")).modal("show");
  }
  ngOnInit() {
    this.loadChecks(1);
  }

  loadChecks(page):void{
    if((page-1)==0)
      this.previousDisable=true;
    else
      this.previousDisable=false;


    this.nextDisable=false;
    this.isLoadingTab=true;
    this.httpService.getHttpAuth("/marcaciones/get/web/all?page="+page)
    .subscribe(x =>{
       this.isLoadingTab=false;
       this.users=x.data;
       if(this.users.length>0){
         var t=x.data[0].cantidad;

         if(x.data.length<10){
           this.nextDisable=true;
         }
         if(t>(page*10)){
           this.nextDisable=false;
         }
         else
          this.nextDisable=true;
       }
     });
  }

  saveStatus(){

    var data= {
      	"id_marcacion_detalle" : this.selected.id,
      	"codigo" : this.type
    };

    if(this.reason)
      data["nota_admin"] = this.reason;

    this.options.disabled=true;
    this.options.isLoading=true;
    this.httpService.postHttpAuth("/marcaciones/get/web/aprobacion",data,this.options)
    .subscribe(x => {
      this.options.disabled=false;
      this.options.isLoading=false;
      this.alertService.info("Estado de la marcación modificado exitosamente");
      (<any>$("#modalMarksMap")).modal("hide");
      var $this=this;
      var index = this.users.findIndex( item => item.id==$this.selected.id);

      if(this.type=="r")
        this.users[index].estado="Rechazado";
      else
        this.users[index].estado="Aprobado";
    });

  }

  pageChanged(evt){

    if(evt=='next')
      this.page++;
    else
      this.page--;

    this.loadChecks(this.page);

  }
}
