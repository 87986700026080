import { Component, OnInit, Input,Output } from '@angular/core';
import { ButtonOpts } from "./interface-button";
import { EventEmitter } from '@angular/core';
@Component({
  selector: 'app-button-loader',
  templateUrl: './button-loader.component.html',
  styleUrls: ['./button-loader.component.scss']
})

export class ButtonLoaderComponent implements OnInit {

  @Input() options: ButtonOpts;
  @Input() element: string;
  @Output() onClick = new EventEmitter();

  constructor() { }

  ngOnInit() {

  }

  action(){
    this.onClick.emit();
  }

}
