// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url_api: "https://marcaciones.mybrinkspanama.net/janustime/public/API",
  suite_conf: {
      januspeople:{
          janus_url:"http://localhost:4300/#/auth",
          permission:[3,4,5]
      },
      janustime:{
          janus_url:"http://localhost:4200/#/auth",
          permission:[3,4,5]
      },
      janusemployee:{
          janus_url:"http://localhost:4400/#/auth",
          permission:[3,4,5]
      }
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
