import { Directive, HostListener, ElementRef,ComponentFactoryResolver,ViewChild,ViewContainerRef, ComponentRef } from '@angular/core';
import * as moment from "moment"

@Directive({
  selector: '[appManualTime]'
})
export class ManualTimeDirective {

  constructor( private resolver: ComponentFactoryResolver, public viewContainerRef: ViewContainerRef ) { 
      this.elementRef = this.viewContainerRef.element;
  }
  
 
  private elementRef: ElementRef;
  componentRef: ComponentRef<any>;
  parentComponent: any;
  invalidMessage: any;
  
  @HostListener('click') onMouseEnter() {
      var element = this.elementRef.nativeElement;
      this.parentComponent = this.elementRef.nativeElement.parentElement;
  }
  
  @HostListener("blur")
  onBlur() {
    this.validate();
  }
  
  @HostListener("paste")
  onPaste() {
    this.elementRef.nativeElement.value = this.elementRef.nativeElement.value.toUpperCase();
    this.validate();
  }
  
  @HostListener("change")
  onChange() {
    this.validate();
  }
  
  @HostListener("keyup", ["$event"])
  onKeyUp(value) {
      
    var val = value.target.value;
    console.log(val.length);
    this.elementRef.nativeElement.value = this.elementRef.nativeElement.value.toUpperCase();
    if((value.key >= 0 && value.key<=9) || (value.key=='p' || value.key=='m'||  value.key=='a' || value.key=='P' || value.key=='A' || value.key=='M' || value.keyCode==104 || value.keyCode==8 || value.keyCode==16 )){
    
        if(val.charAt(0)>1){
            let oldStr = val;
            let newStr = val.replace(val.charAt(0), 1);
            value.target.value = newStr;
        }
        if(val.length>=3)
            if(val.charAt(3)>5){
                let oldStr = val;
                let newStr = val.replace(val.charAt(3), 5);
                value.target.value = newStr;
            }
    }
    else{
        
        if(val.length>=7 && val.length<8){
            if(val.charAt(6)!= 'A' || val.charAt(6)!='P' || val.charAt(6)!='a' || val.charAt(6)!='m'){
                let oldStr = val;
                let newStr = val.replace(val.charAt(6), "P");
                value.target.value = newStr;
                return 0;
            }
            
        }
        if(val.length>=8){
            if(val.charAt(7)!='M' || val.charAt(7)!='m'){
                let oldStr = val;
                let newStr = val.replace(val.charAt(7), "M");
                console.log(newStr);
                
                value.target.value = newStr;
            }
        }
        
    }
    value.stopPropagation();
    value.preventDefault();
    return false;
  }

  validate(): void {
      var element = this.elementRef.nativeElement;
      this.parentComponent = this.elementRef.nativeElement.parentElement;
      let validate = /(1[0-1]|0?[1-9]):([0-5][0-9]) ?([AP][M])/.exec(element.value);
      if(!validate)
      {
          if(!this.invalidMessage) {
              element.classList.add('invalid-input');
              var x = document.createElement("span");
              this.invalidMessage = x;
              x.classList.add("invalid-input-span");
              x.textContent = "Hora invalida";
              this.parentComponent.appendChild(x);
         }
      }
      else {
          if(this.invalidMessage) {
              element.classList.remove('invalid-input');
              this.parentComponent.removeChild(this.invalidMessage);
              this.invalidMessage=null;
          }
      }
  }
}
