import { Component, OnInit } from '@angular/core';
import {Input } from '@angular/core';

@Component({
  selector: 'app-schedule-warning',
  templateUrl: './schedule-warning.component.html',
  styleUrls: ['./schedule-warning.component.scss']
})
export class ScheduleWarningComponent implements OnInit {
	@Input() type = 'alert-light';
	@Input() identifier;
    @Input() choosetxt = 'A';
	showme:boolean = true;
	structure:object = {users_component : true, users_detail_component : true, schedule_component: true };
    content:string;

  	constructor() { }

  	ngOnInit() { 
      

  		this.type += ' alert schedule-note-alert';
  		var json = JSON.parse(localStorage.getItem('warnings-show'));
  		if(json == null || json == undefined){
  			localStorage.setItem('warnings-show', JSON.stringify(this.structure));
  		} else {  			
  			for(let key in json){
	  			if(key == this.identifier) {
	  				this.showme = json[key]; 
	  			}
	  		}
  		}  		
  	}

  	dontShoweverAgain() {  		
  		for(let key in this.structure){  			
  			if(key == this.identifier){  				
  				this.structure[key] = false; // oculta permanentemente este key en este objeto
  				this.showme = false;
  			}
  		}	
  		localStorage.setItem('warnings-show', JSON.stringify(this.structure));  		
  	}

}
