import { Component, OnInit, ViewChild,AfterViewInit } from '@angular/core';
import {HttpService} from './../../services/Http/http.service';
import { AlertService } from 'ngx-alerts';
import { Subject } from 'rxjs';
import * as moment from "moment"
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';


@Component({
  selector: 'app-marks',
  templateUrl: './marks.component.html',
  styleUrls: ['./marks.component.scss']
})
export class MarksComponent implements OnInit {
  
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  isLoadingTab: boolean = false;
  dtTrigger: Subject<any> = new Subject;
  dtOptions: any = {
      pagingType: 'full_numbers',
      "language": {
                "url": "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json"
      },
      pageLength:25,
      "order": [],
      dom: 'Bfrtip',
      buttons: [
          { extend: 'print', text: 'Imprimir', title: 'Reporte de marcaciones' },
          { extend: 'excel', title: 'Reporte de marcaciones' }
      ],
    };
  type: Number = 1;
  status: string ="t";
  checks: any = [
      
  ];
  startDateEdit: Date = moment().toDate();
  endDateEdit: Date = moment().toDate();
  filters: any = null;
  isLoading: boolean = false;

  constructor(private httpService: HttpService, private  alertService: AlertService) { }
  
  query():void{
    this.isLoadingTab=true;
    let i = moment(this.startDateEdit).format("YYYY-MM-DD");
    let f = moment(this.endDateEdit).format("YYYY-MM-DD");
    var data = {
      from:i,
      to:f,
      tipo:this.type,
      estado:this.status,
      group:this.filters
    };
    this.httpService.postHttpAuth("/marcaciones/get/new",data)
    .subscribe(x => {
      this.isLoadingTab = false;
      this.checks = x.data;
      this.rerender();
    });
  }
  
  rerender(): void {
    console.log(this.dtElement);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
  
  ngOnInit() {
  }
  
  ngAfterViewInit(): void {
   this.dtTrigger.next();
   }

  filter(filter){
    this.isLoading = filter.loading
    this.filters = filter; 
  }

}
