import { Component, OnInit,ViewChild, ElementRef} from '@angular/core';
import {HttpService} from '@services/Http/http.service';
import { FormatHoursPipe } from "@pipes/format-hours.pipe";
import * as moment from "moment";
import { Subject } from "rxjs/Subject"
import { ScheduleTabInterface } from "./schedule-tab-interface";
import { GlobalsService } from '@services/globals/globals.service';
import { AlertService } from 'ngx-alerts';
import 'twix';
moment.locale('es');

@Component({
  selector: 'app-schedule-table',
  templateUrl: './schedule-table.component.html',
  styleUrls: ['./schedule-table.component.scss']
})
export class ScheduleTableComponent implements OnInit {

  @ViewChild("filters") filter : any;
  @ViewChild("modalSchedule") modalSchedule: any;
  @ViewChild("modalTag") modalTag: any; 
  @ViewChild("appModalCalendar") appModalCalendar : any;
  @ViewChild("colorTags") tr : ElementRef;
  listUsers : any = [];
  days : any = [];
  tags: any = [];
  from: any;
  to: any;
  dataSpecial: any = <any>{};
  subjectAddSpecial:Subject<any> = new Subject();
  colorTag: ScheduleTabInterface = <ScheduleTabInterface>{};
  isLoadingSaveSchedule: boolean = false;
  change: boolean = true;
  isLoadingCalendar: boolean = false;
  fromLabel: string = "";
  constructor(public gs: GlobalsService,private httpService: HttpService, private formatHoursPipe: FormatHoursPipe, private alertService: AlertService) { }
  
  
  ngOnInit() {
    this.colorTag.tag = "";
    this.colorTag.color = "";
    this.from = moment().startOf('month');
    this.to = moment().endOf('month');
    var daysBetween = moment.twix(moment(this.from ),moment(this.to)).iterate("days");
    var range=[];
    while(daysBetween.hasNext()){
      let date = daysBetween.next().toDate();
      range.push({
        date: date,
        day:moment(date).format("DD")
      });
    }
    this.fromLabel = this.from.format("MMMM YYYY");
    this.days=range;
  }
  

  async changeFilter($evt){
    this.listUsers=[];
    this.tags.tags = [];
    this.tags.days = [];
    this.appModalCalendar.deleteList();
    if(this.filter.divisionSelect!=0){
      for (let i = 0; i < this.filter.users.length; i++) {
      		let estado = this.filter.users[i].id_estado;
      		
      		if(estado==1) await this.loadCalendar(this.filter.users[i]);
      }
      
      this.showCounters();
    }  
  }

  	print() : void {
	   	for(let i = 0; i < this.tr.nativeElement.childNodes.length; i++) {
	  		let tr = this.tr.nativeElement.childNodes[i];
	  		let classes = tr.className;

	  		if(classes == undefined) continue;

	  		let res = classes.search(/print-me/);
	  		if (res == -1) continue;

	  		let td = tr.childNodes;
	  		for(let x = 0; x < td.length; x++){
	  			let tagname = td[x].tagName;

	  			if(tagname == "TD"){
		  			let background = td[x].style.backgroundColor;
		  			
		  			let rgb = background.substring(4, background.length-1).replace(/ /g, '').split(',');
		    		let r = rgb[0];
		    		let g = rgb[1];
		    		let b = rgb[2];   

				    td[x].animate([
				       	{"background": `rgb(${r},${g},${b},1)`},		   
				       	{"background": `rgb(${r},${g},${b},1)`},
				    ],{
				       	fill: 'forwards',
				       	duration: 300
				    });
	  			}
	  		}
	  	}

	  	window.print(); 	
  	}
  
  async loadCalendar(user)  {
    var from = this.from;
    var to = this.to;
    let data  = {
      from,
      to,
      users: [user.id]
    };
    this.isLoadingCalendar = true;
    var dataTags  = <any> await this.httpService.postHttpAuthAsync("/horario/table",data);

    for (let i = 0; i < dataTags.data.length; i++) {
        this.isLoadingCalendar = false;
        let day_estructure = dataTags.data[i];
        var daysBetween = moment.twix(moment(from),moment(to)).iterate("days");
        var range=[];
        while(daysBetween.hasNext()){
            let date = daysBetween.next().toDate();
            var index = day_estructure.dias.findIndex(function(item, i){
                return item.fecha === moment(date).format("YYYY-MM-DD")
            });
            
            range.push({
                date: date,
                day:moment(date).format("DD"),
                data: day_estructure.dias[index]
            });
        }
        
        var index = this.listUsers.findIndex(function(item, i){
            return item.id === user.id
        });
        if(index==-1){
          let data = {
              nombre:user.nombre,
              apellido:user.apellido,
              id:user.id,
              days: range
          };
          
          this.listUsers.push(data);
        }
        else{
          this.listUsers[index].days=range;
        }
    
    }
  }
  
  showCounters (): void {
      var tags = {
          "tags":[],
          "days":[]
      };
      for (let i = 0; i < this.listUsers.length; i++) {
          let days = this.listUsers[i].days;
          for (let k = 0; k < days.length; k++) {
              let day = days[k];             
              if(day.data.tag!="sh") {
                  let tag = tags.tags.findIndex( item => item.tag == day.data.tag);
                  if(tag==-1) {
                      let inserted = tags.tags.push({
                          "tag":day.data.tag,
                          "color":day.data.color,
                          "dias":JSON.parse(JSON.stringify(this.days)),//To copy element without the databinding methods from angular
                          "day":day
                      });
                      
                      let ind = tags.tags[inserted-1].dias.findIndex(item => {
                         item.counter=0; 
                         
                         if(moment(item.date).format("YYYY-MM-DD")==moment(day.date).format("YYYY-MM-DD")){
                             item.counter++;
                         }
                      });
                  }
                  else{
                     let tagDay = tags.tags[tag];
                     let dateSelected = tagDay.dias.find(item => {
                        if(moment(item.date).format("YYYY-MM-DD")==moment(day.date).format("YYYY-MM-DD")){
                            item.counter++;  
                        }
                                  
                     });
                  }
              }
          }
      }
      
      this.tags=tags;
      
      for (let i = 0; i < tags.tags.length; i++) {
          for (let k = 0; k < tags.tags[i].dias.length; k++) {
              let day = tags.tags[i].dias[k];
              let sel = tags.days.findIndex(element => {
                  return moment(element.date).format("YYYY-MM-DD")==moment(day.date).format("YYYY-MM-DD")
              });
              
              if(sel==-1) tags.days.push({date:day.date, counter:day.counter});  
              else tags.days[sel].counter += day.counter;
          }
      }
  }
  
  mouseleave($evt, day) {
      
    if(this.isLoadingCalendar)
        return 0;
      
    let background = $evt.target.style.backgroundColor;
    // let width = day.data.width*3;    
    let rgb = background.substring(4, background.length-1)
         .replace(/ /g, '')
         .split(',');
    let r = rgb[0];
    let g = rgb[1];
    let b = rgb[2];     
    
    // let width_new = [width,(width-(width/3)),(width-(width/3))/2];

    let player = $evt.target.animate([
       {"background": `rgb(${r},${g},${b},1)`},
       {"background": `rgb(${r},${g},${b},0.1)`},
       {"background": `rgb(${r},${g},${b},0.2)`},
       {"background": `rgb(${r},${g},${b},0.3)`},
       {"background": `rgb(${r},${g},${b},0.4)`},
       {"background": `rgb(${r},${g},${b},0.5)`},
       {"background": `rgb(${r},${g},${b},0.6)`},
       {"background": `rgb(${r},${g},${b},0.7)`},
       {"background": `rgb(${r},${g},${b},0.8)`},
       {"background": `rgb(${r},${g},${b},0.9)`},
       {"background": `rgb(${r},${g},${b},1)`},
     ],{
       fill: 'forwards',
       duration: 300
     });

  }
  
  hoverDay($evt,day){
    
    if(this.isLoadingCalendar)
        return 0;
    
    let background = $evt.target.style.backgroundColor;
    let rgb = background.substring(4, background.length-1)
         .replace(/ /g, '')
         .split(',');
    
    let r = rgb[0];
    let g = rgb[1];
    let b = rgb[2];

    
    var player = $evt.target.animate([
       {"background": `rgb(${r},${g},${b},1)`},
       {"background": `rgb(${r},${g},${b},0.9)`},
       {"background": `rgb(${r},${g},${b},0.8)`},
       {"background": `rgb(${r},${g},${b},0.7)`},
       {"background": `rgb(${r},${g},${b},0.6)`},
       {"background": `rgb(${r},${g},${b},0.5)`},
       {"background": `rgb(${r},${g},${b},0.4)`},
       {"background": `rgb(${r},${g},${b},0.3)`},
       {"background": `rgb(${r},${g},${b},0.2)`},
       {"background": `rgb(${r},${g},${b},0.1)`},
       {"background": `rgb(255,255,255)`},
     ],{
       fill: 'forwards',
       duration: 150
     });
     
  }
  
  getLabelByDay(day) {
      if(day.data.entrada=='sh')
        return "Sin horario asignado";
        
      if(day.data.libre)
        return "Libre";
        
      let entrada = this.formatHoursPipe.transform(day.data.entrada,"HH:mm","hh:mm A");
      let salida = this.formatHoursPipe.transform(day.data.salida,"HH:mm","hh:mm A");
      
      return entrada + " - "+ salida;
  }
  
  editDay(day) {
     
      this.dataSpecial.date = day.date;
      this.dataSpecial.entrada = (day.data.entrada!="sh" ? this.formatHoursPipe.transform(day.data.entrada,"HH:mm","hh:mm A") : "00:00")
      this.dataSpecial.salida = (day.data.entrada!="sh" ? this.formatHoursPipe.transform(day.data.salida,"HH:mm","hh:mm A") : "00:00")
      this.dataSpecial.restTime = (day.data.entrada!="sh" ? day.data.descanso : 15)
      this.dataSpecial.extraTime = (day.data.entrada!="sh" ? day.data.minutos_extra : 5)
      this.dataSpecial.free_day = (day.data.entrada!="sh" ? day.data.libre : false)
      this.dataSpecial.id_usuario = (day.data.id_usuario)
      this.dataSpecial.user = this.listUsers.find((item,index) => {
         return item.id == day.data.id_usuario; 
      });
      
      this.modalSchedule.openModal(this.dataSpecial);
  }
  
  showEditTag(tag){
      this.colorTag.id=JSON.parse(JSON.stringify(tag)).color;
      this.colorTag.old_tag=JSON.parse(JSON.stringify(tag)).tag;
      this.colorTag.color = "#"+tag.color;
      this.colorTag.tag = tag.tag;
      this.modalTag.open();
  }
  
  editTag($evt){
      
      this.colorTag.color = this.colorTag.color.replace('#', '');
      if(this.colorTag.tag.length==0)
      {     
          this.alertService.danger("Debes agregar un identificador");
          return 0;
      }
      this.isLoadingSaveSchedule = true;
      this.httpService.putHttpAuth("/horario/table/"+this.colorTag.id, this.colorTag)
      .subscribe(data => {
        
        for (let i = 0; i < this.listUsers.length; i++) {
            let days = this.listUsers[i].days;
            for (let k = 0; k < days.length; k++) {
              let day = days[k];
              if(day.data.color == this.colorTag.id) day.data.color = this.colorTag.color;
              if(day.data.tag == this.colorTag.old_tag) day.data.tag = this.colorTag.tag;
            }
        }
        
        for (let i = 0; i < this.tags.tags.length; i++) {
            let tag = this.tags.tags[i];
            if(tag.color == this.colorTag.id) tag.color  = this.colorTag.color;
            if(tag.tag == this.colorTag.old_tag) tag.tag = this.colorTag.tag;
        }
        let list = JSON.parse(JSON.stringify(this.listUsers));
        this.listUsers=[];
        this.listUsers = list;
        this.alertService.success("Identificador editado exitosamente");
        this.isLoadingSaveSchedule = false;
        this.modalTag.dismiss();
      }, err =>  this.isLoadingSaveSchedule = false);
  }
  
  async dayChange(data){
    this.appModalCalendar.deleteList();
    await this.loadCalendar({id:data.data.id_usuario});
    this.showCounters();
  }
  
  async changeDate(type) {
      
    if(this.isLoadingCalendar)  
        return 0;
      
    this.change=false;
    let from = null;
    let to = null;
    if(type=='next'){
      from = (this.from.add(1,'months')).startOf('month');
      to = (this.to.add(1,'months')).endOf('month');
    }else{
      from = (this.from.subtract(1,'months')).startOf('month');
      to = (this.to.subtract(1,'months')).endOf('month');
    }
    this.from = from;
    this.to = to;
    this.change=true;
    this.fromLabel = this.from.format("MMMM YYYY");
    var daysBetween = moment.twix(moment(this.from ),moment(this.to)).iterate("days");
    var range=[];
    while(daysBetween.hasNext()){
      let date = daysBetween.next().toDate();
      range.push({
        date: date,
        day:moment(date).format("DD")
      });
    }
    this.days=range;
    
    this.listUsers=[];
    this.tags.tags=[];
    this.tags.days = [];
    this.appModalCalendar.deleteList();
    if(this.filter.divisionSelect!=0){
      for (let i = 0; i < this.filter.users.length; i++) {
          await this.loadCalendar(this.filter.users[i]);
      }
      
      this.showCounters();
    }  
    
  }
  
  openEditCalendar() {
      this.appModalCalendar.open();
  }
  
  addToListCalendar(user){
    let bool = this.appModalCalendar.addUserToList(user);
    if(bool) user.active = true; else user.active = false;
  }
  
  async updatedSchedule(data){
      
      for (let i = 0; i < data.data.data.length; i++) {
          this.listUsers.find( (item,index) => {
             if(item.id == data.data.data[i].id)
                item.active = false;
          });
      }
      
      
      for (let i = 0; i < data.data.data.length; i++) {
          this.listUsers.find( (item,index) => {
             if(item.id == data.data.data[i].id)
                item.active = false;
          });
          await this.loadCalendar({id:data.data.data[i].id});
          this.showCounters();
      }
      
      
  }
  
  userDeleted(id){
      console.log(id);
      this.listUsers.find( (item,index) => {
         if(item.id == id)
            item.active = false;
      });
  }
}
