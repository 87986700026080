import { Pipe, PipeTransform } from '@angular/core';
import * as moment from "moment"
@Pipe({
  name: 'date'
})
export class DatePipe implements PipeTransform {

  transform(value: Date, format: string) : any{
    return moment(value).format(format);
  }

}
