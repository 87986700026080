import { Component, OnInit, ViewChild } from '@angular/core';
import {HttpService} from './../../services/Http/http.service';
import { GlobalsService } from '@services/globals/globals.service'
import { AlertService } from 'ngx-alerts';
import { Subject } from 'rxjs';
import * as moment from "moment"
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { ContentLoaderChecksComponent } from "./../../globals/content-loader-checks/content-loader-checks.component"
import 'twix';

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss']
})
export class RequestsComponent implements OnInit {

  constructor(private httpService: HttpService, private  alertService: AlertService, private gs: GlobalsService) { }
  @ViewChild(DataTablesModule)
  dtElement: DataTablesModule;
  isLoadingTab: boolean = true;
  dtOptions: any = {
      pagingType: 'full_numbers',
      "language": {
                "url": "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json"
      }
    };
  user: String ="Alfredo Rodriguez";
  type: any;
  reason: String="";
  from: Date = moment(new Date).toDate();
  to: Date = moment(new Date).toDate();
  note: String;
  fullTime: boolean=true;
  date: Date = moment(new Date).toDate();
  startTime: String = "00:00";
  endTime: String = "23:59";
  idRequest: number;
  reasons: any = []
  requests: any = [];
  dtTrigger: Subject<any> = new Subject();
  justRead: boolean = false;
  reasonType: number= 1;
  reasonText: string = "";

  openBoxRequest(id,type,object){
    this.type=type;
    this.idRequest=id;
    this.justRead=false;
    this.reasonText  = "";
    this.reason = "";

    if(object){
      this.httpService.getHttpAuth("/solicitud/"+id)
    .subscribe(x =>{
      console.log(x.data);

      if(type!='new'){
        this.justRead=true;
        this.reasonText = x.data.concepto;
        this.note = x.data.nota;

        let hora_inicio = moment(x.data.fecha_inicio).format("HH:mm");
        let hora_fin = moment(x.data.fecha_fin).format("HH:mm");
        let fecha_inicio = moment(x.data.fecha_inicio).toDate();;
        let fecha_fin = moment(x.data.fecha_fin).toDate();;
        if (hora_inicio != "00:00" && hora_fin != "23:59")
        {
            this.fullTime=false;
            this.startTime = hora_inicio;
            this.endTime = hora_fin;
        }
        this.from = fecha_inicio;
        this.to = fecha_fin;

      }



      (<any>$("#modalRequest")).modal("show");

      });

      console.log(object);
    }
    else
      (<any>$("#modalRequest")).modal("show");

  }


  modifyRequest(type){
      var users=localStorage.getItem("user");
      var uid = JSON.parse(users);
      console.log(this.startTime,this.endTime)
      var data={
        id_usuario_aprobar: uid.id,
        codigo_aprobacion: type,
        fecha_inicio: (this.fullTime ? moment(this.from).format("YYYY-MM-DD")+ " " + "00:00" : moment(this.from).format("YYYY-MM-DD") + " " + this.startTime),
        fecha_fin: (this.fullTime ? moment(this.to).format("YYYY-MM-DD")+ " " + "23:59" : moment(this.to).format("YYYY-MM-DD") + " " + this.endTime),
        razon:this.reason
      };

      this.httpService.putHttpAuth("/solicitud/"+this.idRequest,data)
      .subscribe(x => {
        this.reason="";

        this.alertService.success("Solicitud editada correctamente");
        for (let i = 0; i < this.requests.length; i++) {
            if(this.requests[i].id==this.idRequest){
              this.requests[i].estado=type;
              this.requests[i].fecha_inicio=data.fecha_inicio;
            }
        }
        (<any>$("#modalRequest")).modal("hide");

       })
  }

  saveRequest(){
    var users=localStorage.getItem("user");
    var uid = JSON.parse(users);
    var data= {
      fecha_inicio: (this.fullTime ? moment(this.from).format("YYYY-MM-DD")+ " " + "00:00" : moment(this.from).format("YYYY-MM-DD") + " " + this.startTime),
      fecha_fin: (this.fullTime ? moment(this.to).format("YYYY-MM-DD")+ " " + "23:59" : moment(this.to).format("YYYY-MM-DD") + " " + this.endTime),
      nota:this.note,
      id_motivo:this.reasonType,
      id_usuario:uid.id
    };

    this.httpService.postHttpAuth("/solicitud",data)
    .subscribe(x => {
    this.alertService.success("Solicitud creada correctamente");

    this.httpService.getHttpAuth("/solicitud/get/all")
    .subscribe(x =>{
      this.requests=x.data;
      console.log(x.data);


    });

     (<any>$("#modalRequest")).modal("hide"); });
  }
  ngOnInit() {
    this.httpService.getHttpAuth("/solicitud/get/all")
    .subscribe(x => {this.requests=x.data; this.isLoadingTab=false;});

    this.httpService.getHttpAuth("/solicitud/get/motivos")
    .subscribe(x => {
      this.reasons=x.data;
    });
  }

}
