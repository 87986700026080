import { Component, OnInit, EventEmitter, Output,Input } from '@angular/core';
import { HttpService } from "@services/Http/http.service"
import { AlertService } from 'ngx-alerts'
import { Subject } from 'rxjs';

@Component({
  selector: 'filters-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class FiltersCompaniesComponent implements OnInit {

  constructor(private httpService: HttpService, private  alertService: AlertService) { }
  @Output() modifyType = new EventEmitter();
  @Input() classCols? = "col-md-3 boxes-filter"
  @Input() showUsers? = false;
  @Input() canChangeUser? = false;
  @Input() isLoading? = false; 
  @Input() loadUserAfter? = 2; 
  @Input() showCCosto = false;

  sucursales: any=[];
  deparments: any=[];
  divisions: any=[];
  centers: any = [];
  id_empresa: number;
  sucursalSelect: Number=0;
  departamentSelect: Number= 0;
  divisionSelect: Number= 0;
  centerCostSelected: Number= 0;
  filters: any = null;
  users: Array<any> = [];
  usersList: Array<any> = [];
  selectGroup: any = {};
  
  changeSucursal(){
      this.deparments=[];
      this.divisions= [];
      this.centers=[];
      this.modified(2,this.sucursalSelect);
      if(this.sucursalSelect!=0){
        this.divisionSelect=0;
        this.departamentSelect=0;
        this.centerCostSelected=0;
        
        this.httpService.getHttpAuth("/departamento/get/"+this.sucursalSelect)
        .subscribe(data => {
          this.deparments=data.data;
          // if(this.deparments.length==0)
          //   this.alertService.info("Esta sucursal no tiene divisiones asociados");
        });
      }
        
      else
      {
        this.modified(1,0);
        this.divisionSelect=0;
        this.departamentSelect=0;
        this.centerCostSelected=0;
        this.deparments=[];
        this.divisions=[];
        this.centers=[];
      }
    
  }

  changeDeparment(){
      this.modified(3,this.departamentSelect);
      if(this.departamentSelect!=0){
        
        this.httpService.getHttpAuth("/division/get/"+this.departamentSelect)
        .subscribe(data => {
          
          this.divisions=data.data;
          // if(this.divisions.length==0)
          //   this.alertService.info("Este departamento no tiene departamentos asociados");
          
        })
      }
      else{
        this.modified(2,this.sucursalSelect);
        this.divisionSelect=0;
        this.divisions=[];
        this.centers=[];
      }
  }
  
  changeDivision() {
      this.modified(4,this.divisionSelect);
      if(this.divisionSelect!=0){
        
        this.httpService.getHttpAuth("/centro-costo/get/"+this.divisionSelect)
        .subscribe(data => {
          this.centers=data.data;
        })
      }
      else{
        this.modified(3,this.departamentSelect);
        this.centerCostSelected=0;
        this.centers=[];
      }
    
  }
  
  changeCenter(){  
      if(this.centerCostSelected==0)
        this.modified(4,this.divisionSelect);
      else
        this.modified(5,this.centerCostSelected);
        
  }
  

  modified(type,id){
  
    this.users = [];
    this.usersList = [];
    this.selectGroup = {
      "id_grupo":id,
      "id_tipo_grupo":type,
      "users":this.usersList
    };
    
    if(type < this.loadUserAfter){
        this.modifyType.emit({
          "id_grupo":id,
          "id_tipo_grupo":type,
          "users":this.usersList
        });
        return 0;
    }
    
    if(this.showUsers){
     
      this.modifyType.emit({
          "id_grupo":id,
          "id_tipo_grupo":type,
          "users":[],
          "loading":true
      });
      this.httpService.postHttpAuth("/users/bygroup",{
        "id_grupo":id,
        "jerarquia":type
      })
      .subscribe(data => {
  
        for (let i = 0; i < data.users.length; i++) {
            data.users[i].nombre = data.users[i].nombre + " " + data.users[i].apellido;
        }
        this.users = data.users; 
        this.modifyType.emit({
          "id_grupo":id,
          "id_tipo_grupo":type,
          "users":this.usersList,
          "loading":false
        });
      });
      
    }else{
      this.modifyType.emit({
        "id_grupo":id,
        "id_tipo_grupo":type,
        "loading":false
      });
    }
  }
  
  changeDataUser(){
    this.selectGroup.users=this.usersList;
    this.modifyType.emit(this.selectGroup);
  }

 	reset_filters(filters){  
  		if(filters){
  			if(filters.id_sucursal != 0) {
  				this.sucursalSelect = filters.id_sucursal;
  				this.changeSucursal();
  			}
  			if(filters.id_departamento != 0){ 
  				this.departamentSelect = filters.id_departamento;
  				this.changeDeparment();
  			}
  			if(filters.id_division != 0) {
  				this.divisionSelect = filters.id_division;
  				this.changeDivision();
  			}  	
  		} 
  	}
  
  	ngOnInit(){
    	var group = null;
    	group = localStorage.getItem("grupo");
    	group = JSON.parse(group);
    	group = group.grupos;
    	this.id_empresa = group.empresa.id;
  		//this.sucursalSelect = 0;
      	this.httpService.getHttpAuth("/sucursal/get/" + this.id_empresa).subscribe(data => {
    		this.sucursales = data.data;
       
    	})
  	}
} 
