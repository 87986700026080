import { Component, AfterViewInit, OnInit,OnDestroy, ViewChild } from '@angular/core';
import { HttpService } from "@app/services/Http/http.service"
import { AlertService } from 'ngx-alerts'
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import * as moment from "moment"

@Component({
  selector: 'app-report-incapacity',
  templateUrl: './incapacity.component.html',
  styleUrls: ['./incapacity.component.scss']
})
export class IncapacityComponent implements OnInit {
    
    
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  startDateEdit: Date = moment().toDate();
  endDateEdit: Date = moment().toDate();
  groups: any = {};

  dtOptions: any = {
    pagingType: 'full_numbers',
    dom: 'Bfrtip',
    buttons: [
        { extend: 'print', text: 'Imprimir', title: 'Reporte de incapacidad' },
        { extend: 'excel', title: 'Reporte de incapacidad' }
      ],
  "language": {
    "url": "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json",
    "emptyTable": "No existen ausencias con los parametros especificados"
  },
  "pageLength": 50     
  };
  isLoadingTab: boolean = false;
  isLoading: boolean = false;
  rowsReport: any = [];

  
  constructor(public httpService: HttpService, private alertService: AlertService) { }

  
  ngOnInit() {}
  
  ngAfterViewInit(): void {
   this.dtTrigger.next();
   }

   ngOnDestroy(): void {
     // Do not forget to unsubscribe the event
     this.dtTrigger.unsubscribe();
   }
   
   apply(){
     var data = {
        group:this.groups,
    };
     this.isLoadingTab= true;
     this.httpService.postHttpAuth("/reporte/vacaciones",data)
     .subscribe(data => {
       this.isLoadingTab= false;
       this.rowsReport = data.users;
       console.log(this.rowsReport);
       this.rerender();
     });
     
   }
   
   rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
   }
   
  
   filter(event){
      this.groups=event;
      this.isLoading = event.loading;  
      console.log(this.groups);  
    }

}
