import { Component, OnInit,AfterViewInit } from '@angular/core';
import { HttpService } from "./../../services/Http/http.service"
import { AlertService } from 'ngx-alerts'
import { GlobalsService } from '@services/globals/globals.service'


@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

  users_counter: number = 0;
  request_counter: number = 0;
  approve_pending: number = 0;
  clocks: number = 0;
  checks: any = [];
  checks_pending: any=[];
  system: any = [];
  gauges: any = null;
  constructor(public httpService: HttpService, private alertService: AlertService, private gs: GlobalsService) { }
  
  
  ngOnInit() {
    this.httpService.getHttpAuth("/usuario/get/dashboard")
    .subscribe(data => {
      this.gauges=data.circulos;
      this.users_counter= data.users;
      this.request_counter= data.solicitudes.total;
      this.approve_pending= data.phone_pending.total;
      this.clocks= data.reloj;
      this.checks=data.detalles;
      this.checks_pending=data.detalles.data;
      this.system = data.usuarios_sistema;
    })
  }
  
  ngAfterViewInit(){
    // var containerDiv = document.getElementById("vizContainer"),
            //     url = "http://public.tableau.com/views/RegionalSampleWorkbook/Storms",
            //     options = {
            //         hideTabs: true,
            //         onFirstInteractive: function () {
            //             console.log("Run this code when the viz has finished loading.");
            //         }
            //     };
            // 
            // var viz = new tableau.Viz(containerDiv, url, options); 
  }

}
