import {Directive, Component, Input, OnInit, Output, ElementRef, Renderer2, ViewChild, EventEmitter} from '@angular/core';
import { DomService } from'./modal-service'  

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit {
  
  @Input() title = null;
  @Input() icon;
  @Input() classbtn;
  @Input() description;
  navchange: EventEmitter<number> = new EventEmitter();
  @Output() onClick = new EventEmitter();
  @Output() Confirm = new EventEmitter();
  @Output() Close = new EventEmitter();
  constructor() { }

  ngOnInit() {
    
  }
  
  
  open(content){
    (<any>$("#confirmModal")).modal("show");
  }
  
  confirm(){  
    this.Confirm.emit();
  }
  
  justDismiss(){
    (<any>$("#confirmModal")).modal("hide");
  }
  dismiss(content){
    this.Close.emit();
  }
  
  
}
