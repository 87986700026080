  let config = {
    custom:{
      companies_id:[2],
      config:[
        {
          id_company:2,
          hierachy:[
            "Empresa",
            "División",
            "Sucursal",
            "Departamento",
            "Centro de costo"
          ]
        }
      ]
    }
  }

  let originalHierarchy = [
    "Empresa",
    "Sucursal",
    "División",
    "Departamento",
    "Centro de costo"
  ];

  let findHierarchy = (id_company,level) => {
    let $id_company=id_company;
    let element = config.custom.config.findIndex(<any>function(item, i){
      if(item.id_company==$id_company)
      return item;
    });

    
    
    return (element!=-1 ? config.custom.config[element].hierachy : originalHierarchy);
  }

  let findHierarchyByLevel = (id_company,level) => {

    let $id_company=id_company;
    let element = config.custom.config.findIndex(<any>function(item, i){
      if(item.id_company==$id_company)
      return item;
    });
    return (element!=-1 ? config.custom.config[element].hierachy[level] : originalHierarchy[level]);  
  }

  export { config as config_hierarchy,findHierarchy,findHierarchyByLevel };