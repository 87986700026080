import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {Observable, throwError} from "rxjs"
import { catchError, retry } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { AlertService } from 'ngx-alerts'
import { map } from 'rxjs/operators';
import {Router, ActivatedRoute} from "@angular/router";
@Injectable({
  providedIn: 'root'
})


export class HttpService {

  constructor(private router:Router, public alertService: AlertService, private httpClient: HttpClient) { }

  base_api: string = environment.url_api;


  headers: any = {headers: {'Authorization': "Bearer " + localStorage.getItem("token")}}

  options: any;


  getHttp(url_parameter: string, options?:any): Observable<any>{

    if(options)
      this.options=options;

    return this.httpClient.get(this.base_api+url_parameter)
    .pipe(
      catchError(
        this.handleError.bind(this)
      )
    );

  }

  getHttpAuth(url_parameter: string, options?:any): Observable<any>{

    if(options)
      this.options=options;

    var headers = {headers: {'Authorization': "Bearer " + localStorage.getItem("token")}};
    return this.httpClient.get(this.base_api+url_parameter,headers)
    .pipe(
      catchError(
        this.handleError.bind(this)
      )
    );

  }

  postHttpAuth(url_parameter: string, parameters: any, options?:any): Observable<any>{

    if(options)
      this.options=options;

    var headers = {headers: {'Authorization': "Bearer " + localStorage.getItem("token")}};
    return this.httpClient.post(this.base_api+url_parameter,parameters,headers)
    .pipe(
      catchError(
        this.handleError.bind(this)
      )
    );
  }
  
  async postHttpAuthAsync(url_parameter: string, parameters: any, options?:any) {

    if(options)
      this.options=options;

    var headers = {headers: {'Authorization': "Bearer " + localStorage.getItem("token")}};
    return this.httpClient.post(this.base_api+url_parameter,parameters,headers)
    .pipe(
      catchError(
        this.handleError.bind(this)
      )
    ).toPromise();
  }


  postHttp(url_parameter: string, parameters: any,options?:any): Observable<any>{

    if(options)
      this.options=options;

    return this.httpClient.post(this.base_api+url_parameter,parameters)
    .pipe(
      catchError(
        this.handleError.bind(this)
      )
    );
  }


  deleteHttpAuth(url_parameter: string, parameters: any): Observable<any>{
    var headers = {headers: {'Authorization': "Bearer " + localStorage.getItem("token")}};
    return this.httpClient.delete(this.base_api+url_parameter,headers)
    .pipe(
      catchError(
        this.handleError.bind(this)
      )
    );
  }

  putHttpAuth(url_parameter: string, parameters: any,options?:any): Observable<any>{

    if(options)
      this.options=options;

    var headers = {headers: {'Authorization': "Bearer " + localStorage.getItem("token")}};
    return this.httpClient.put(this.base_api+url_parameter,parameters,headers)
    .pipe(
      catchError(
        this.handleError.bind(this)
      )
    );
  }


   handleError(error: HttpErrorResponse) {
      if(this.options){
        this.options.disabled=false;
        this.options.isLoading=false;
      }
      console.log(error);
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        this.alertService.danger('Ha ocurrido un error inesperado');
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,

        if(error.status==422){
            var arr=error.error.data.validation;
            var strErr="";
            for (let i = 0; i < arr.length; i++) {
                this.alertService.danger(arr[i]);
                strErr+=arr[i];
            }
            console.log(strErr);

        }

        if(error.status==401){
            this.router.navigate(['/login'])
            this.alertService.danger('Su sesión ha expirado, vuelva a iniciar sesión');
            localStorage.removeItem("token")
            return;
        }
        
        if(error.status==503){
            this.router.navigate(['/maintance'])
            localStorage.removeItem("token")
            return;
        }

        if(error.status==400){
            this.alertService.danger(error.error.data.message);
        }
        console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);

        console.log(error);
      }
    // return an observable with a user-facing error message
      return throwError(
      'Something bad happened; please try again later.');
  };


}
