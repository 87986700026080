import { Component } from '@angular/core';
import { gentellela } from "gentelella"
import * as jquery from 'jquery'
import * as $ from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';

}
