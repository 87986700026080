import { Component, OnInit } from '@angular/core';
import { HttpService } from './../../services/Http/http.service'
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'ngx-alerts';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {

  constructor(private serviceHttp: HttpService, private router: Router, private alertService: AlertService, private _route: ActivatedRoute) { }
  
    options: any = {
      text:"Recuperar contrasena",
      disabled: false,
      type:"btn btn-primary",
      icon:"fa fa-spinner fa-spin",
      isLoading:false
    };
    post:boolean=false;
    password: "";
    confirmPassword: "";
    token: "";
    name: string="";
    
    ngOnInit() {
      this.token = (this._route.snapshot.params.token ? this._route.snapshot.params.token : "");
      this.name = (this._route.snapshot.params.email ? this._route.snapshot.params.email : "");
      
      if(this.token!=''){
        this.post=true;
      }
      else{
        this.post=false;
      }
      console.log(this.post,this.token);
    }
    
    resetClick(){
      this.options.isLoading = true;
      this.options.disabled = true;
      if(!this.post){
        this.serviceHttp.postHttp("/resetpassword",{
          email:this.name
        })
        .subscribe(a => {
            this.alertService.success('Email de recuperación enviado.');
            this.router.navigate(["/login"]);
        }, err => {
          this.options.isLoading = false;
          this.options.disabled = false;
        });
    }
    else{
      
      if(this.password == ''  || this.password == '' )
      { 
        this.alertService.info('Rellene todos los campos.');
        return 0;
      }
      
      if(this.password!=this.confirmPassword){
        this.alertService.info('La contraseña no coincide con la confirmacion.');
      }
      this.serviceHttp.postHttp("/postresetpassword",{
        email:this.name,
        password:this.password,
        token:this.token
      })
      .subscribe(a => {
          this.alertService.success('Su contraseña fue reestablecida de manera correcta.');
          this.router.navigate(["/login"]);
      }, err => {
        this.options.isLoading = false;
        this.options.disabled = false;
      });
    }
  }
}
