import {Directive, Component, Input, OnInit, Output, ElementRef, Renderer2, ViewChild} from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})

@Directive({ 
     selector: '[modal]' 
})

export class ModalComponent implements OnInit {

  constructor(private elRef: ElementRef, private renderer: Renderer2) { }

  @Input () title;
  @Input() icon;
  @Input() classbtn;
  @Input() openModal;
  @Input() modalSize? = "";
  @Output() clickConfig = new EventEmitter();
  isOpen: boolean = false;
  backdrop: any;
  body: any;
  ngOnInit() {
    var $this = this;
    if(this.openModal)
        this.openModal.subscribe(event =>{
          $this.open(event);      
        });
  }

  open(content):void {
    var list = document.getElementsByTagName("body")[0];
    var backdrop = document.createElement("DIV");
    backdrop.classList.add('modal-backdrop', 'fade', 'in');
    list.appendChild(backdrop);
    this.backdrop=backdrop;
    list.classList.add('modal-open');
    this.body=list;
    this.isOpen = true;
  }
  
  dismiss(){
    this.isOpen = false;
    this.backdrop.remove();
    this.body.classList.remove("modal-open");
  }
  
  dismissv():void{
    this.isOpen = false;
    this.backdrop.remove();
    this.body.classList.remove("modal-open");
  }
}
