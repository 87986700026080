import { Pipe, PipeTransform } from '@angular/core';
import { config_hierarchy,findHierarchy,findHierarchyByLevel } from "./../../companies/configCompany";
@Pipe({
  name: 'hierarchies'
})
export class HierarchiesPipe implements PipeTransform {

  transform(value, level: any): any {
    var group=null;
    group=localStorage.getItem("grupo");
    group=JSON.parse(group);
    group=group.grupos;
    var id_empresa=group.empresa.id;
    return findHierarchyByLevel(id_empresa,level);
  }

}
