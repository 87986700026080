import { Component, OnInit,ViewChild,Input } from '@angular/core';
import { AfterViewInit } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { HttpService } from "./../services/Http/http.service"
import { AlertService } from 'ngx-alerts';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { ContentLoaderChecksComponent } from "./../globals/content-loader-checks/content-loader-checks.component"
import { FormatHoursPipe } from "@pipes/format-hours.pipe";
import { ScheduleService } from '@services/globals/schedule.service';
import * as moment from "moment"
import 'twix';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit  {


  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  isLoadingTab: boolean = true;
  dtTrigger: Subject<any> = new Subject();
  id_empresa: number;
  days: number=1;
  free_day: boolean=false;
  options: any = {
    text:"Guardar",
    disabled: false,
    type:"btn btn-primary",
    icon:"fa fa-spinner fa-spin",
    isLoading:false,
    principal_icon:""
  };
  listToEditId=1;

  dtOptions: any = {
    pagingType: 'full_numbers',   
    "language": {
              "url": "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json"
    }
  };
  datasTable: any=[];
  public initTime: any="00:00";
  public endTime: any="00:00";
  schedules: any=[];
  name: string;
  code: string;
  type:string='new';
  activeDayIsOpen: boolean = true;
  id_schedule:number;
  restTime: number = 15;
  sindicato: boolean=false;
  sindicato_time: number = 4;


  constructor(private scheduleService: ScheduleService, private formatHoursPipe: FormatHoursPipe,public httpService: HttpService, private alertService: AlertService, private atp: AmazingTimePickerService) { }

  ngOnInit() {

    var group=null;
    group=localStorage.getItem("grupo");
    group=JSON.parse(group);
    group=group.grupos;
    this.id_empresa=group.empresa.id;

    this.httpService.getHttpAuth("/plantilla/get/all")
    .subscribe(x =>{
      this.isLoadingTab=false;
      this.datasTable=x.data;
      this.rerender();
    });


  }
  
  clickOnTimePicker($event,type){
    const amazingTimePicker = this.atp.open(
    {time: this.formatHoursPipe.transform($event.target.value,"hh:mm A","HH:mm")}
    );
    amazingTimePicker.afterClose().subscribe(time => {
      if(type=="start")
        this.initTime = moment(time,"HH:mm").format("hh:mm A");
      else
        this.endTime= moment(time,"HH:mm").format("hh:mm A");
    });
  }

  changeRest(e,i){
    this.schedules[i].descanso=e;
  }

  rerender(): void {

    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  modalSchedule(type,id){



      if(type=="edit"){
        this.id_schedule=id;
        this.httpService.getHttpAuth("/plantilla/"+id).
        subscribe(x => {
          this.name=x.data.nombre;
          this.code=x.data.codigo;
          this.schedules=x.data.plantilla_detalle;
          for(let i=0; i<x.data.plantilla_detalle.length; i++){

          	 let diff = this.scheduleService.checkTimeDiff(
        		moment(x.data.plantilla_detalle[i].hora_entrada, "HH:mm"),
        		moment(x.data.plantilla_detalle[i].hora_salida, "HH:mm"),
        		x.data.plantilla_detalle[i].descanso);

          	
          	let hour = moment.utc(moment.duration(diff, "minutes").asMilliseconds()).format("hh:mm");
	      	var hora = hour;
            x.data.plantilla_detalle[i].delete=false;
            x.data.plantilla_detalle[i].total = hora;
          }
          
        });
        this.type="edit";
      }else{
        this.code="";
        this.name="";
        this.schedules=[];
        this.days=1;
        this.initTime="00:00";
        this.endTime="00:00";
        this.restTime=0;
        this.free_day=false;
        this.type="new";
      }


      (<any>$("#modalSchedule")).modal("show");
  }

  addToScheduleList(id){
   	var hour ="";
    if(this.free_day){
      this.restTime = 0;
      hour = "00:00";
    }
    
    if(!this.free_day){
      let difference = this.scheduleService.checkTimeDiff(
        moment(this.initTime,"hh:mm A"),
        moment(this.endTime,"hh:mm A"),
        this.restTime);
        hour = moment.utc(moment.duration(difference, "minutes").asMilliseconds()).format("hh:mm");
	      var hora = parseInt(hour);
	      if(this.sindicato){
	        if(this.sindicato_time < hora){
	          this.alertService.danger('El horario seleccionado no es valido, valida que el horario sea igual o menor a las horas de sindicato');
	          return 0;
	        }
	      }

      let validator = this.scheduleService.validateMaxTime(
        moment(this.initTime,"hh:mm A"),
        moment(this.endTime,"hh:mm A"),
        8,
        this.restTime);
      
      if(!validator){
        this.alertService.danger('El horario seleccionado no es valido, valida que el horario sea igual o menor a 8 horas incluyendo el tiempo de descanso.');
        return 0;
      }
    }

    

    this.schedules.push({
      dias:this.days,
      hora_entrada:(this.initTime ? this.initTime: null),
      hora_salida:(this.endTime ? this.endTime : null),
      libre: this.free_day,
      descanso: this.restTime,
      total: hour,
      hora_sindicato:(this.sindicato ? this.sindicato_time: null),
      delete:false,
      id: null
    });


    this.free_day = false;
    this.sindicato = false;
    this.initTime = "00:00";
    this.endTime = "00:00";
    this.restTime = 0;
    this.days = 1;



  }

  saveSchedule(){
    var data={
    	"id_empresa" : this.id_empresa,
    	"codigo" : this.code,
    	"nombre" : this.name,
    	"detalle" : this.schedules,
    };
    this.options.disabled = true;
    this.options.isLoading = true;
    
    let schedules = JSON.parse(JSON.stringify(this.schedules))

    for (let i = 0; i < schedules.length; i++) {
        console.log(schedules[i].hora_entrada);
        console.log(this.formatHoursPipe.transform(schedules[i].hora_entrada,"hh:mm A","HH:mm"));
        schedules[i].hora_entrada = this.formatHoursPipe.transform(schedules[i].hora_entrada,"hh:mm A","HH:mm");
        schedules[i].hora_salida = this.formatHoursPipe.transform(schedules[i].hora_salida,"hh:mm A","HH:mm");
    }
    
    data.detalle=schedules;

    if(this.type=="new")
      this.httpService.postHttpAuth("/plantilla",data)
      .subscribe(x => {
          this.options.disabled = false;
          this.options.isLoading = false;
          this.alertService.success("Plantilla creada exitosamente");
          this.code="";
          this.name="";
          this.schedules=[];
          this.free_day = false;
          this.sindicato = false;
          this.initTime = "00:00";
          this.endTime = "00:00";

          this.datasTable.push(x.data);
          (<any>$("#modalSchedule")).modal("hide");
      },
      err=>{
        this.options.disabled = false;
        this.options.isLoading = false;
      });
    else{
      console.log(data);

      this.httpService.putHttpAuth("/plantilla/"+this.id_schedule,data)
      .subscribe(x => {
          this.options.disabled = false;
          this.options.isLoading = false;
          console.log(x);
          this.alertService.success("Plantilla editada exitosamente");
          this.code="";
          this.name="";
          this.schedules=[];
          this.free_day = false;
          this.sindicato = false;
          this.initTime = "00:00";
          this.endTime = "00:00";

          (<any>$("#modalSchedule")).modal("hide");
      },
      err => {
        this.options.disabled = false;
        this.options.isLoading = false;
      });
    }


  }

  deleteScheduleDatabase(id,i){
    this.httpService.deleteHttpAuth("/plantilla/"+id,{}).
    subscribe(x => {
        this.alertService.success("Plantilla eliminada exitosamente");
        this.datasTable.splice(i,1);
    });

  }

  deleteScheduleTime(id,i){
    console.log(this.type);
    if(this.type=="new")
      this.schedules.splice(i, 1);
    else{
      if(this.schedules[i].id)
        this.schedules[i].delete=true;
      else
        this.schedules.splice(i, 1);
    }


  }

  changeSindicate(){
    if (this.free_day){
      this.sindicato = false;
    }
  }


  editRowSchedule(id){
    console.log(id);
  }
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }


}
