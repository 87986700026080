import { Component, AfterViewInit, OnInit,OnDestroy, ViewChild } from '@angular/core';
import { HttpService } from "@app/services/Http/http.service"
import { AlertService } from 'ngx-alerts'
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import * as moment from "moment"

@Component({
  selector: 'app-concepts-simple',
  templateUrl: './concepts-simple.component.html',
  styleUrls: ['./concepts-simple.component.scss']
})
export class ConceptsSimpleComponent implements OnInit {

  	@ViewChild(DataTableDirective)
    dtElement: DataTableDirective;
    dtTrigger: Subject<any> = new Subject();
    startDateEdit: Date = moment().toDate();
    endDateEdit: Date = moment().toDate();
    groups: any = {};
    isLoadingTab: boolean = false;
    isLoading: boolean = false;
    rowsReport: any = [];

    // datatables options
    dtOptions: any = {
      	pagingType: 'full_numbers',
      	dom: 'Blfrtip',
      	pageLength: 10,  
      	paging: true,
      	order : [],
      	bSort : false,
      	scrollX : true,
      	buttons: [
          	{ extend: 'print', text: 'Imprimir', title: 'Reporte de concpetos simples' },
          	{ extend: 'excel', title: 'Reporte de conceptos simples' }
        ],
        columns : [
      		{width: "15px", class : "centered"},
      		{width: "188px"},      	
      		{width: "88px"},
      		{class : "centered"},
      		{class : "centered"},
      		{class : "centered"},
      		{class : "centered"},
      		{class : "centered"},
      		{class : "centered"},
      		{class : "centered"},
      		{class : "centered"},
      		{class : "centered"},
      		{class : "centered"},
      		{class : "centered"},
      		{class : "centered"},
      		{class : "centered"},
      		{class : "centered"},
      		{class : "centered"},
      		{class : "centered"},
      		{class : "centered"}
       	],
    	language : {
      		url : "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json",
      		emptyTable : "No existen ausencias con los parametros especificados"
    	}    	  
    };
    
  	constructor(public httpService: HttpService, private alertService: AlertService) { }

  	ngOnInit() {
  		this.apply();
  	}
  
  	ngAfterViewInit(): void {
   		this.dtTrigger.next();
   	}

   	ngOnDestroy(): void {
     	// Do not forget to unsubscribe the event
     	this.dtTrigger.unsubscribe();
   	}
   
   	apply(){
     	var data = {
       		from:moment(this.startDateEdit).format("YYYY-MM-DD"),
       		to: moment(this.endDateEdit).format("YYYY-MM-DD"),
      	 	group:this.groups,
     	};
     	this.isLoadingTab = true;
     	this.httpService.postHttpAuth("/reporte/conceptos-simples",data).subscribe(data => {
       		this.isLoadingTab = false;
       		this.rowsReport = data.report;
       		this.rerender();
     	});
     
   }
   
   rerender(): void {
    	this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        	// Destroy the table first
        	dtInstance.destroy();
        	// Call the dtTrigger to rerender again
        	this.dtTrigger.next();
      	});
   }
   
   filter(event){
     	this.groups=event;
     	this.isLoading = event.loading
     	console.log(this.groups);  
   	}

}
