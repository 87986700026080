import { Component, OnInit, Inject, ViewContainerRef,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  ApplicationRef,
  ComponentRef,
  ViewChild,
  Directive
 } from '@angular/core';
import { HttpService } from "@app/services/Http/http.service"
import { AlertService } from 'ngx-alerts'
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ActivatedRoute,Router } from "@angular/router";
import * as moment from "moment"
import 'twix';
import { AmazingTimePickerService } from 'amazing-time-picker'; // this line you need
import { AgmCoreModule } from '@agm/core';
import { FormatHoursPipe } from "@pipes/format-hours.pipe"
import { listChecks } from "@interfaces/checks"
import { GlobalsService } from '@services/globals/globals.service'
import { ModalConfirmComponent } from "@html/modals/modal-confirm/modal-confirm.component"
import { DomService } from "@html/modals/modal-confirm/modal-service"





@Component({
  selector: 'app-cards-detail',
  templateUrl: './cards-detail.component.html',
  styleUrls: ['./cards-detail.component.scss']
})

export class CardsDetailComponent implements OnInit {
  scheduleInfo:any;
  hasManualConcept: boolean = false;
  user: any = null;
  isLoadingTab = true;
  isLoadingExtra = false;
  dataCards: any = []
  date : any = [new Date("07-15-2018"),new Date("07-31-2018")];
  modelReason: String="REG";
  finalDistribution: any = [];
  modelConcept: any = [];
  journyLimit: number = null;
  selectedDate: String="";
  total: String= "";
  regularHours: String="";
  extraHours: String="";
  aproveHours: boolean=false;
  checkList: string[] =[];
  lat: number = 9.010131;
  lng: number = -79.474144;
  markers: any = [];
  description: String ="";
  deletedIds: any = [];
  logs: any = [];
  extra_init: String ="";
  extra_end: String ="";
  approveExtra: boolean = true;
  isLoadingApprove: boolean=false;
  isApproved: boolean=false;
  userApprove: any = [];
  id_marcacion:number=null;
  from: any ="";
  extra: Array<any> = [];
  extraObject: any = <any>{};
  extra_formated: Array<any> = [];
  to: any ="";
  id_period: any ="";
  periods: Array<any> = [];
  calculatedTime: Array<any> = [];
  next: String = "Siguiente";
  prev: String = "Anterior";
  start: boolean = true;
  isOpen: boolean = true;
  options: any = {
    text:"Guardar",
    disabled: false,
    type:"btn btn-primary",
    icon:"fa fa-spinner fa-spin",
    isLoading:false,
    principal_icon:"fa fa-floppy-o"
  };
  optionsApprove: any = {
    text:"Guardar y Aprobar",
    disabled: false,
    type:"btn btn-success",
    icon:"fa fa-spinner fa-spin",
    isLoading:false,
    principal_icon:"fa fa-floppy-o"
  };
  buttonLoadLogs: any = {
    text:"",
    disabled: false,
    type:"btn btn-primary",
    icon:"fa fa-spinner fa-spin",
    principal_icon:"fa fa-history",
    onlyIcon:true,
    isLoading:false
  };
  optionsConfig: any = {
    text:"",
    disabled: false,
    type:"btn btn-primary",
    icon:"fa fa-spinner fa-spin",
    principal_icon:"fa fa-clock-o",
    onlyIcon:true,
    isLoading:false
  };
  styles: any[] = [
    {elementType: 'geometry', stylers: [{color: '#242f3e'}]},
    {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
    {elementType: 'labels.text.fill', stylers: [{color: '#746855'}]},
  ];
  changingValue: Subject<number> = new Subject();
  marks = [] = [];
  checksProccesed: Array<any> = [];
  @ViewChild("alertContainer", { read: ViewContainerRef }) container;
  componentRef: ComponentRef<any>;
  finalDistributionDaily: any = [];
  indexDailyDistribution = 0;
  wasModified: boolean = false;
  message_modified: String = "";
  extraComment: string  = "";
  extraStructure: any =<any>{};
  extraEnable: number = 0;

  constructor(private httpService: HttpService,
              private _route: ActivatedRoute,
              private atp: AmazingTimePickerService,
              private formatHoursPipe: FormatHoursPipe,
              private alertService: AlertService,
              public gs: GlobalsService,
              public domService: DomService,
              private resolver: ComponentFactoryResolver,
              private router:Router) { }

  ngOnInit() {
    this.httpService.getHttpAuth("/usuario/"+this._route.snapshot.params.id).
    subscribe(data => {

      this.user=data.data;
    });
    let id=null

    if(this._route.snapshot.params.id_corte) id=this._route.snapshot.params.id_corte;
    this.loadPeriod(id);
  }

  loadPeriod(id):void {

    let url="/periodo/"+this._route.snapshot.params.id;
    if(id){
      url="/periodo/"+this._route.snapshot.params.id+"/"+id;
    }

    this.isLoadingTab=true;

    this.httpService.getHttpAuth(url).
    subscribe(data => {
      this.isLoadingTab=false;
    
      for (let i = 0; i < data.data.marcaciones.length; i++) {
          this.isOpen=data.data.cerrar;
        
          data.data.marcaciones[i].button= {
            text:"",
            type:"btn btn-primary pos-relative",
            icon:"fa fa-spinner fa-spin",
            principal_icon:"fa fa-clock-o",
            onlyIcon:true,
            isLoading:false,
            disabled: (((data.data.marcaciones[i].horario && !data.data.cerrar &&  this.gs.getPermission().ver)) ? false : true)
          };

          data.data.marcaciones[i].buttonLogs  = {
            text:"",
            disabled: !data.data.marcaciones[i].historial,
            type:"btn btn-primary",
            icon:"fa fa-spinner fa-spin",
            principal_icon:"fa fa-history",
            onlyIcon:true,
            isLoading:false
          };
      }

      
      this.dataCards = data.data.marcaciones;
      this.from = data.data.periodo_actual.inicio;
      this.to = data.data.periodo_actual.fin;
      this.id_period = data.data.periodo_actual.id;
      this.periods = data.data.id_periodos;
    });
  }


  changePeriod(type){

      var actualPosition=this.periods.indexOf(this.id_period);
      var newPosition= (type=='next' ? actualPosition+1 : actualPosition-1);
    
      if(this.periods[newPosition]){
          this.loadPeriod(this.periods[newPosition]);
      }
      else{
        this.alertService.warning("No existe otro periodo");
      }

  }

  deleteRange(i){
    if(this.marks[i].entrada.id)
      this.deletedIds.push(this.marks[i].entrada.id);
    
    if(this.marks[i].salida.id)
      this.deletedIds.push(this.marks[i].salida.id);
    this.marks.splice(i,1);
    this.wasModified = true;
    this.changeHours();
  }

    clickOnTimePicker(value,i,type,mark){

      const amazingTimePicker = this.atp.open({
        time: this.formatHoursPipe.transform(value,"hh:mm A","HH:mm"),
      });
      amazingTimePicker.afterClose().subscribe(time => {

        if(type=='entry')
          this.marks[i].entrada=
          {
            hora:moment(time,"HH:mm").format("hh:mm A"),
            id:(mark.entrada.id ? mark.entrada.id : null),
            tipo: mark.entrada.tipo
          };
        else
          this.marks[i].salida={
            hora:moment(time,"HH:mm").format("hh:mm A"),
            id:mark.salida.id ? mark.salida.id : null,
            tipo: mark.salida.tipo
        };
        this.wasModified = true; 
        this.changeHours();

      });
    }

    clickOnTimePickerExtra(value,i,type, io){    	
 
      const amazingTimePicker = this.atp.open({
        time: this.formatHoursPipe.transform(value,"hh:mm A","HH:mm"),
      });
      amazingTimePicker.afterClose().subscribe(time => {

        var hour = moment(time,"HH:mm").format("hh:mm A");
    
        if(type=='entry' && io=='init')
          this.extraObject.inicio.entrada.hora= hour;
        else if(type=='exit' && io=='init')
          this.extraObject.inicio.salida.hora= hour;
        else if(type=='entry' && io=='end')
          this.extraObject.fin.entrada.hora= hour;
        else if(type=='exit' && io=='end')
          this.extraObject.fin.salida.hora= hour;      

      });
    }


    reason(i,e){
      let type  = e.target.value;
      this.marks[i].id_concepto=type;
    }

    changeHours():void {

      var seconds=0;
      for (let i = 0; i < this.marks.length; i++) {

        if(this.marks[i].entrada.hora && this.marks[i].salida.hora){

          var momentE=moment(this.formatHoursPipe.transform(this.marks[i].entrada.hora,"hh:mm A","HH:mm"),"hh:mm");
          var momentS=moment(this.formatHoursPipe.transform(this.marks[i].salida.hora,"hh:mm A","HH:mm"),"hh:mm");

          var duration = momentS.diff(momentE, 'seconds');
          seconds+=duration;
          var formatted = moment.utc(duration*1000).format('HH:mm');
          this.marks[i].horas=formatted;
        }
        
          
      }

      var getTotal = moment.utc(seconds*1000).format('HH:mm')
      this.total=getTotal;

      if(seconds>(this.journyLimit*60)){
        this.aproveHours=true;
        this.extraHours=moment.utc((seconds-(this.journyLimit*60))*1000).format('HH:mm');
        this.regularHours=moment.utc((this.journyLimit*60)*1000).format('HH:mm');
      }
      else{
        this.extraHours="12:00 PM";
        this.aproveHours=false;
        this.regularHours=getTotal;
      }
    }

    addNewMark(){
      this.wasModified = true;
      console.log("hereeeee");
      this.marks.push({
        "entrada":{hora: "", id:null,tipo:"admin"},
        "salida":{hora: "", id:null,tipo:"admin"},
        "id_concepto":this.modelConcept[0].id

      });

      this.changeHours();
    }

    addExtra(){
      this.extra.push({
        entrada:{hora:null,id:null},
        salida:{hora:null,id:null},
        id_tipo:"hora_extra",
        nota:""
      });
    }

    deleteExtra(i){
      this.extra.splice(i,1);
    }

    openMap(date,length,free){

      this.selectedDate=date;
      this.changeHours();
      (<any>$("#modalMarksMap")).modal("show");
    }

    openMarks(schedule){
      this.marks=[];
      this.wasModified = false;
      this.message_modified="";
      this.total="0";
      this.regularHours = "0";
      this.extraHours = "0";
      this.calculatedTime=[];
      this.selectedDate=schedule.fecha;
      this.extra = [];
      this.deletedIds = [];
      this.extra.push({
        marcacion: {
          entrada:{hora:null,id:null},
          salida:{hora:null,id:null}
        },
        id_tipo:"hora_extra",
        nota:""
      });

      var {id_marcacion} = schedule;

      if(id_marcacion){
        this.id_marcacion=id_marcacion;
        schedule.button.disabled=true;
        schedule.button.isLoading=true;

        this.httpService.getHttpAuth("/marcacion/"+id_marcacion+"/1",schedule.button).
        subscribe(data => {
          schedule.button.disabled=false;
          schedule.button.isLoading=false;
          var marcaciones = data.data.marcaciones;
          this.modelConcept=data.data.conceptos;
          this.hasManualConcept= false; //data.data.horario.concepto_manual;
      
          this.scheduleInfo=data.data.horario;
          
          for (let i = 0; i < marcaciones.length; i++) {
              var marcacion = marcaciones[i];

              this.marks.push({
                "entrada": {
                  hora: (marcacion.entrada ? this.formatHoursPipe.transform(marcacion.entrada.hora,"HH:mm","hh:mm A") : null),
                  id:(marcacion.entrada ? marcacion.entrada.id : null),
                  tipo:marcacion.entrada.tipo
                },
                "salida": {
                  hora: (marcacion.salida ? this.formatHoursPipe.transform(marcacion.salida.hora,"HH:mm","hh:mm A") : null),
                  id:(marcacion.salida ? marcacion.salida.id : null),
                  tipo:(marcacion.salida ? marcacion.salida.tipo : "admin")
                },
                "id_concepto":marcacion.id_concepto
              });
          }

          this.changeHours();
          (<any>$("#modalMarks")).modal({backdrop: 'static', keyboard: false,show:true});

        });
      }else{
        var data = {
            "fecha": schedule.fecha,
            "id_usuario":this._route.snapshot.params.id
        };
        schedule.button.disabled=true;
        schedule.button.isLoading=true;
        this.httpService.postHttpAuth("/marcacion",data)
        .subscribe( x => {
            
            this.httpService.getHttpAuth("/marcacion/"+x.data.marcacion.id+"/1",schedule.button).
            subscribe(data => {
                this.id_marcacion=x.data.marcacion.id;
                this.scheduleInfo=data.data.horario;
                schedule.id_marcacion=x.data.marcacion.id;
                schedule.button.disabled=false;
                schedule.button.isLoading=false;
                this.modelConcept=x.data.conceptos;
                this.marks=[];
                (<any>$("#modalMarks")).modal({backdrop: 'static', keyboard: false,show:true});
            }, err=> {
                schedule.button.disabled=false;
                schedule.button.isLoading=false;
            });
            
            
        },err => {
            schedule.button.disabled=false;
            schedule.button.isLoading=false;
        });

      }

    }

    save(type){

    
      this.sendChecks(type);


    }

    sendChecks(type):void{



        var checks = [];
        var data = <any>{};
        this.extra = [];
        this.extraObject = {};
        for (let i = 0; i < this.marks.length; i++) {
            var current= this.marks[i];

            checks.push({
              "entrada": {
                hora: (current.entrada.hora ? this.formatHoursPipe.transform(current.entrada.hora,"hh:mm A","HH:mm") : null),
                id:(current.entrada ? current.entrada.id : null),
                tipo: current.entrada.tipo
              },
              "salida": {
                hora: (current.salida.hora ? this.formatHoursPipe.transform(current.salida.hora,"hh:mm A","HH:mm") : null),
                id:(current.salida ? current.salida.id : null),
                tipo: current.salida.tipo
              },  
              "id_concepto":current.id_concepto,
            });
        }

        data.marcaciones=checks;
        data.marcaciones_eliminadas = this.deletedIds;
        data.modificado =  this.wasModified;
        data.comentario = this.message_modified;
        this.isLoadingApprove=true;
        this.extraEnable=0;
        this.httpService.putHttpAuth("/marcacion/"+this.id_marcacion,data,this.options)
        .subscribe(x => {
            this.message_modified="";
            
            if(this.marks.length==0)
              type=='save';
            
            if(type=='saveandapprove') {
              this.isLoadingApprove=true;
              this.httpService.getHttpAuth("/marcacion/"+this.id_marcacion+"/2",this.options).
              subscribe(data => {
              
                this.checksProccesed=data.data.marcaciones;
                this.scheduleInfo=data.data.horario;
                
                if(data.data.hora_extra && data.data.hora_extra.inicio){
                  // this.extraEnable++;
                  this.extraObject.inicio = {  
                    "entrada":(data.data.hora_extra.inicio.entrada ? {
                      "hora":this.formatHoursPipe.transform(data.data.hora_extra.inicio.entrada.hora,"HH:mm","hh:mm  A"),
                      "id":data.data.hora_extra.inicio.entrada.id
                    } : null),
                    "salida":(data.data.hora_extra.inicio.salida ? 
                      {
                        "hora":this.formatHoursPipe.transform(data.data.hora_extra.inicio.salida.hora,"HH:mm","hh:mm  A"),
                        "id":data.data.hora_extra.inicio.salida.id
                      } : null),
                    "approve":false
                  };
                  console.log(this.extraObject);
                }
              
                
                if(data.data.hora_extra && data.data.hora_extra.fin){
                  // this.extraEnable++;
                  this.extraObject.fin = {  
                    "entrada":(data.data.hora_extra.fin.entrada ? {
                        "hora":this.formatHoursPipe.transform(data.data.hora_extra.fin.entrada.hora,"HH:mm","hh:mm  A"),
                        "id":data.data.hora_extra.fin.entrada.id
                    }: null),
                    "salida":(data.data.hora_extra.fin.salida ? {
                        "hora":this.formatHoursPipe.transform(data.data.hora_extra.fin.salida.hora,"HH:mm","hh:mm  A"),
                        "id":data.data.hora_extra.fin.salida.id
                    } : null),
                    "approve":false
                };
                }
                  


                this.options.disabled=false;
                this.options.isLoading=false;
                this.extraComment="";
                this.changingValue.next(0);
                (<any>$("#modalMarks")).modal("hide");
                (<any>$("#modalExtra")).modal({backdrop: 'static', keyboard: false,show:true});
                this.isLoadingApprove=false;
                  
              },x => this.isLoadingApprove=false);

            }else{
              this.loadPeriod(null);
              this.isLoadingApprove=false;
              (<any>$("#modalMarks")).modal("hide");
              this.options.disabled=false;
              this.options.isLoading=false;
              this.alertService.success("Marcaciones guardadas de manera correcta");
            }


         }, x => this.isLoadingApprove=false);
    }

    onStep1Next(){
      
      this.extraStructure = {
        "inicio": ( this.extraObject.inicio && this.extraObject.inicio.approve ? {
          "entrada":{
            "hora":this.formatHoursPipe.transform(this.extraObject.inicio.entrada.hora,"hh:mm  A","HH:mm"),
            "id":this.extraObject.inicio.entrada.id
          },
          "salida":{
            "hora":this.formatHoursPipe.transform(this.extraObject.inicio.salida.hora,"hh:mm  A","HH:mm"),
            "id":this.extraObject.inicio.salida.id
          },
          aprobar: true,
          id_tipo:null
        } : null ),
        "fin":( this.extraObject.fin && this.extraObject.fin.approve ? {
          "entrada":{
            "hora":this.formatHoursPipe.transform(this.extraObject.fin.entrada.hora,"hh:mm  A","HH:mm"),
            "id":this.extraObject.fin.entrada.id
          },
          "salida":{
            "hora":this.formatHoursPipe.transform(this.extraObject.fin.salida.hora,"hh:mm  A","HH:mm"),
            "id":this.extraObject.fin.salida.id
          },
            aprobar: true,
            id_tipo:null
        } : null ),
        nota: this.extraComment
      };

      
      
      if(!this.extraStructure.inicio && !this.extraStructure.fin)
          this.extraStructure=null;
          
      var data  = {
      	"pre" : true,
      	"extra" :this.extraStructure,
        override: false
      };
      this.calculatedTime=[];
      this.isLoadingExtra=true;      
      this.httpService.postHttpAuth("/marcacion/aprobacion/"+this.id_marcacion,data)
        .subscribe(data => {
          this.isLoadingExtra=false;
          this.calculatedTime=data.data;
        }, 
        err => {
          this.isLoadingExtra=false;
          this.changingValue.next(0);
        });
    }

    onStep2Next(){
      
    
      
      var data  = {
      	"pre" : false,
      	"extra" : this.extraStructure,
        override: false
      };
      
      this.container.clear();
      const factory = this.resolver.resolveComponentFactory(<any>ModalConfirmComponent);
      this.componentRef = this.container.createComponent(factory);
      this.componentRef.instance.open();
      this.componentRef.instance.Confirm.subscribe(x => {
        this.changingValue.next(1);
        this.componentRef.instance.justDismiss();
        (<any>$("#modalExtra")).modal({backdrop: 'static', keyboard: false,show:true});
        this.proccessApprove(data);
      });
      this.componentRef.instance.Close.subscribe(x => {
        this.componentRef.instance.justDismiss();
        this.changingValue.next(1);
        (<any>$("#modalExtra")).modal({backdrop: 'static', keyboard: false,show:true});

      });


    }
    
    proccessApprove(data):void{
      this.isLoadingExtra=true;    
      this.httpService.postHttpAuth("/marcacion/aprobacion/"+this.id_marcacion,data)
      .subscribe(data_backend => {
        (<any>$("#modalExtra")).modal("hide");
    
        if(data_backend.data.error)
        { 
          this.container.clear();
          const factory = this.resolver.resolveComponentFactory(<any>ModalConfirmComponent);
          this.componentRef = this.container.createComponent(factory);
          this.componentRef.instance.title = data_backend.data.msj;
          this.componentRef.instance.open();
          this.componentRef.instance.Confirm.subscribe(x => {
            this.isLoadingExtra=false;      
            data.override=true;
            this.componentRef.instance.justDismiss();
            this.proccessApprove(data);
            (<any>$("#modalExtra")).modal("show");
            return 0;
          });
          this.componentRef.instance.Close.subscribe(x => {
            this.isLoadingExtra=false;    
            this.componentRef.instance.justDismiss();
            this.changingValue.next(0);
          });
          return 0;
        }
        
        this.loadPeriod(this.id_period);
        this.isLoadingExtra=false;    
        this.alertService.success("Día aprobado de manera correcta");
      });
    }
    
    savePeriod(type){

      this.container.clear();
      const factory = this.resolver.resolveComponentFactory(<any>ModalConfirmComponent);
      this.componentRef = this.container.createComponent(factory);
      this.componentRef.instance.open();
      this.componentRef.instance.Confirm.subscribe(x => {
        this.componentRef.instance.justDismiss();
        this.isLoadingApprove=true;
        this.httpService.getHttpAuth("/periodo/visto-bueno/"+this.id_period+"/"+type)
        .subscribe(x => {
          let data = x.data;
          this.isLoadingApprove=false;
          if(type==1){
            if(data.success==false){
        
              for (let i = 0; i < this.dataCards.length; i++) {
                  for (let k = 0; k < data.dias.length; k++) {
                      var data_moment=moment(this.dataCards[i].fecha,"DD-MM-YYYY").format("DD-MM-YYYY");
        
                      if(data.dias[k].fecha==data_moment){
                        this.dataCards[i].button.badgeClass="badge pos-absolute bagde-notificacion badge-danger extra";
                        this.dataCards[i].button.useBadge=true;
                        this.dataCards[i].button.badgeIcon="fa fa-exclamation";
                      }
                  }
              }

              this.alertService.danger("Los días señalados requieren aprobación antes de procesar el periodo");
            }
            else
            {
              this.finalDistribution = data.simple;
              this.userApprove = data.aprobaciones;
              this.finalDistributionDaily = data.detallado;
              this.indexDailyDistribution=0;

              (<any>$("#modalApprove")).modal("show");

            }

          }else{
              this.alertService.success("Periodo aprobado de forma correcta");
              (<any>$("#modalApprove")).modal("hide");
              this.loadPeriod(this.id_period);
          }




        },
        error =>  {
          this.isLoadingApprove=false;
        });
      });
      this.componentRef.instance.Close.subscribe(x => {
        this.componentRef.instance.justDismiss();
      });
    }

    dailySearchDistribution(type){
      if(type=='prev')
        this.indexDailyDistribution--;
      else
      this.indexDailyDistribution++;
    }

    get_logs(horario){

        if(!horario.id_marcacion)
        {
          this.alertService.info("No existen cambios para este día");
          return 0;
        }
        horario.buttonLogs.isLoading=true;
        horario.buttonLogs.disabled=true;
        this.httpService.getHttpAuth("/marcaciones/web/logs/marcacion/"+horario.id_marcacion,horario.buttonLogs)
        .subscribe(x => {
          horario.buttonLogs.isLoading=false;
          horario.buttonLogs.disabled=false;
          this.logs=x.logs;
          if(this.logs.length==0){
            this.alertService.info("No existen cambios para este día");
            return 0;
          }
          (<any>$("#logs-checks")).modal("show");
        });
    }

    disapprovePunch(){
      this.httpService.postHttpAuth('/marcacion/anulacion/'+this.id_marcacion,{})
      .subscribe(x => {
        this.alertService.success("Marcación anulada de manera correcta");
        (<any>$("#modalMarks")).modal("hide");
        this.loadPeriod(null);
      });
    }


    navigateToUser(){
      (<any>$("#modalMarks")).modal("hide");
      this.router.navigate(['/dashboard/',{ outlets: { section: ['users',this.user.usuario.id] } }]);
    }

    desapprovePeriod(){
      this.httpService.postHttpAuth('/periodo/anular/'+this.id_period,{})
      .subscribe(x => {
        this.alertService.success("Periodo anulado de manera correcta");
        (<any>$("#modalMarks")).modal("hide");
        this.loadPeriod(null);
      });
    }
    
    changeExtra(extra){
      if(extra)
        this.extraEnable++;
      else
        this.extraEnable--;
    }

    moveChecks() {
    	this.isLoadingApprove = true;
    	this.httpService.getHttpAuth('/periodo/ordenar/'+this.id_period,{})
  		.subscribe(x => {
  			this.isLoadingApprove = false;
    		this.alertService.success(x.data);
    		this.loadPeriod(this.id_period);
    	});
    }
}
