import { Component, AfterViewInit, OnInit,OnDestroy, ViewChild } from '@angular/core';
import { HttpService } from "@app/services/Http/http.service"
import { AlertService } from 'ngx-alerts'
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import * as moment from "moment"

@Component({
  selector: 'app-report',
  templateUrl: './special.component.html',
  styleUrls: ['./special.component.scss']
})
export class SpecialComponent implements OnInit {
    
    
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  startDateEdit: Date = moment().toDate();
  endDateEdit: Date = moment().toDate();
  type: string = "t";
  dtOptions: any = {
    "ordering": false,
    pagingType: 'full_numbers',
    dom: 'Bfrtip',
    buttons: [
        { extend: 'excel', title: 'Reporte de dias especiales trabajados', exportOptions:{
          stripNewlines: false
        }
        }
      ],
  "language": {
    "url": "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json",
    "emptyTable": "No existen datos para los parametros seleccionados"
  },
  "paging":   false  
  };
  isLoadingTab: boolean = false;
  rowsReport: any = [];
  groups: any = {};
  isLoading: boolean = false;
  
  constructor(public httpService: HttpService, private alertService: AlertService) { }

  
  ngOnInit() {
    // this.isLoadingTab= true;
    // this.httpService.postHttpAuth("/reporte/distribucion",{})
    // .subscribe(data => {
    //   this.isLoadingTab= false;
    //   this.rowsReport = data.data;
    //   this.rerender();
    // });
  }
  
  ngAfterViewInit(): void {
   this.dtTrigger.next();
   }

   ngOnDestroy(): void {
     // Do not forget to unsubscribe the event
     this.dtTrigger.unsubscribe();
   }
   
   apply(){
     console.log(this.groups);
     var data = {
       from:moment(this.startDateEdit).format("YYYY-MM-DD"),
       to: moment(this.endDateEdit).format("YYYY-MM-DD"),
       group:this.groups,
       type:this.type
     };
     this.isLoadingTab= true;
     this.httpService.postHttpAuth("/reporte/domingos-feriados",data)
     .subscribe(data => {
       this.isLoadingTab= false;
       this.rowsReport = data.data;
       console.log(this.rowsReport);
       this.rerender();
     });
     
   }
   
   rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
   }
   
   filter(event){
     this.groups=event;
     this.isLoading = event.loading
     console.log(this.groups);  
   }

}
