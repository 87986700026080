import { Component, OnInit, Input, HostListener, Output, EventEmitter, ViewChild, AfterViewInit, ComponentFactoryResolver,
  ViewContainerRef, ComponentRef } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from "@angular/router";
import { SharedService } from "./../services/eventsEmmiter/shared.service"
import { HttpService } from "./../services/Http/http.service"
import { AlertService } from 'ngx-alerts'
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { UserCalendarComponent } from "./../globals/user-calendar/user-calendar.component"
import { ContentLoaderChecksComponent } from "./../globals/content-loader-checks/content-loader-checks.component"
import * as moment from "moment"
import { GlobalsService } from '@services/globals/globals.service'
import { ModalConfirmComponent } from "@html/modals/modal-confirm/modal-confirm.component"
import { IgxDatePickerComponent, DateRangeType } from "igniteui-angular"
import { RolesService } from './../services/globals/roles.service'

interface AngSelectEvent {
  name: string;
  value: any;
}


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  @Output() onUserChanged = new EventEmitter<any>();
  @Input()  
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  @ViewChild("uidt") dpicker: IgxDatePickerComponent;
  @ViewChild("appModalCalendar") appModalCalendar : any;
  subjectAddDaysEdit: Subject<any> = new Subject();
  @ViewChild("fc") filtersCompany;
  //filtersCompany: any = { "sucursal": true, "departamento": false, "division": false, "centro_costo": false }
  companyCreateShow: any = { "sucursal": false, "departamento": false, "division": false, "centro_costo": false }
  isEditing = false;
  employeeData: boolean = false;
  supervisorData: boolean = false;
  noAdminData: boolean = false;
  mark_app: boolean = true;
  mark_web: boolean = true;
  mark_app_supervisor: boolean = true;
  mark_web_supervisor: boolean = true;
  name: string = "";
  lastname: string = "";
  centro_costo: string = "";
  username: string = "";
  password: string = "";
  email: string = "";
  phone: string = "";
  id_employee: string = "";
  pin: string = "";
  fingerPrintId: string = "";
  readWrite: string = "";
  card: string = "";
  type: string = "admin";
  dtOptions: any = {
    dom: 'Bfrtip',
    buttons: [
      { extend: 'excel',
          title: 'Reporte de usuarios',
          exportOptions:{
            stripNewlines: false,
            columns: ':not(.notexport)'
          } 
        }
    ],
    "language": {
      "url": "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json",
      "emptyTable": "No existen usuarios"
    },
    "pageLength": 25     
  };
  users: any = [];
  sucursal: boolean = false;
  sucursalSelect: number = 0;
  departament: boolean = false;
  departamentSelect: number = 0;
  division: boolean = false;
  divisionSelect: number = 0;
  divisionSelectArray: any = [];
  ccenterSelect: number = 0;
  sucursales: any = [];
  deparments: any = [];
  divisions: any = [];
  centers: any = [];
  events: AngSelectEvent[] = [];
  id_empresa: number;
  usersToEdit: any = [];
  dtTrigger: Subject<any> = new Subject();
  startDateEdit: Date = null;
  endDateEdit: Date = null;
  templateSelected: any = 0;
  templates: any = [];
  minutes: number = 5;
  nameSchedule: string = "";
  isLoadingTab: boolean = true;
  incapacity_found: any = "00:00";
  vacation_found: any = "00:00";
  @ViewChild("alertContainer", { read: ViewContainerRef }) container;
  filters: any = null;
  type_user_filter: string = "3,4,5,7,8";
  componentRef: ComponentRef<any>;
  user_delete : number = 0;
  position_user_delete : number = 0;
  permisionsSupervisor: any = {
    "view":true,
    "edit":true,
    "approve":true
  };
  ng_user_state : number = 0;
  ng_user_states = [{id:0, name : 'Todos'}, {id:1, name:'Activo'}, {id:2, name:'Inactivo'}];
  structure : any = {
  	type : this.type_user_filter,
  	state : this.ng_user_state,
  	id_sucursal : 0,
  	id_departamento : 0,
  	id_division :  0
  };
  components :any;

  constructor(private _sharedService: SharedService, public httpService: HttpService,
    public gs: GlobalsService,
    private alertService: AlertService,
    private router: Router,
    private resolver: ComponentFactoryResolver,
    private role : RolesService
  ) {
  	this.components = this.role.get();
  	
  }

  open() {
  	if(this.role.getRole() == 8) this.type= "adminRH";
  	if(this.role.getRole() == 7) this.type= "revisor";
  	if(this.role.getRole() == 3) this.type= "colaborador";  
  	this.selectType();	
    (<any>$("#modalUsers")).modal("show");
  }

  userSelect(id, type) {
    this._sharedService.emitChange({ id, type });
  }

  selectType() {
    this.sucursalSelect = 0;
    this.deparments = [];
    this.divisions = [];
    this.centers = [];

    if (this.type == "colaborador") {
      this.employeeData = true;
      this.supervisorData = false;
      this.companyCreateShow.sucursal = true;
      this.companyCreateShow.departamento = true;
      this.companyCreateShow.division = true;
      this.companyCreateShow.centro_costo = true;
      this.noAdminData = true;
    }
    else if (this.type == "preparador") {
      this.employeeData = false;
      this.supervisorData = true;
      this.companyCreateShow.sucursal = true;
      this.companyCreateShow.departamento = true;
      this.companyCreateShow.division = true;
      this.companyCreateShow.centro_costo = false;
      this.noAdminData = true;
    }
    else if (this.type == "adminRH" || this.type == "adminIT" || this.type == "revisor") {
      this.employeeData = false;
      this.supervisorData = false;
      this.companyCreateShow.sucursal = false;
      this.companyCreateShow.departamento = false;
      this.companyCreateShow.division = false;
      this.companyCreateShow.centro_costo = false;
      this.noAdminData = false;
    }
  }

  saveUser() {

    let base_data = <any>{
      "nombre": this.name,
      "apellido": this.lastname,
      "nombre_usuario": this.username,
      "email": this.email,
      "password": this.password,
      "telefono": this.phone,
      "id_grupo": 1,
      "id_tipo_grupo": 1,
      "id_rol": null,
      divisiones: null,
      "permisos": {
        "ver": true,
        "editar": true,
        "marcar_web": true,
        "marcar_app": true
      }
    };


    var id_rol = 0;
    if (this.type == "revisor") id_rol = 3;
    else if (this.type == "preparador") id_rol = 4;
    else if(this.type == "adminRH") id_rol = 7;
    else if(this.type == "adminIT") id_rol = 8;
    else if(this.type == "colaborador") id_rol = 5;

    
    if (this.type == "preparador") {
      base_data.permisos.marcar_app=this.mark_app_supervisor;
      base_data.permisos.marcar_web=this.mark_web_supervisor;
      base_data.permisos.ver = this.permisionsSupervisor.view;
      base_data.permisos.editar = this.permisionsSupervisor.edit;
      base_data.permisos.aprobar = this.permisionsSupervisor.approve;  

      if (this.permisionsSupervisor.edit) {
        base_data.permisos.ver = true;
        base_data.permisos.editar = true;
      }
      
      if (!this.permisionsSupervisor.view) {
        base_data.permisos.editar = false;
        base_data.permisos.aprobar = false;
      }
      

      if (this.sucursalSelect != 0) {
        base_data.id_grupo = this.sucursalSelect;
        base_data.id_tipo_grupo = 2;

        if (this.departamentSelect && this.departamentSelect!=0) {
          base_data.id_grupo = this.departamentSelect;
          base_data.id_tipo_grupo = 3;
        }

        if (this.divisionSelect || this.divisionSelectArray.length > 0) {
            base_data.divisiones = this.divisionSelectArray;
            base_data.id_grupo = this.departamentSelect;
            base_data.id_tipo_grupo = 3;
        }
      } else {
        this.alertService.info("Debe seleccionar al menos una sucursal");
        return 0;
      }

    }
    else if (this.type == "colaborador") {      
      base_data.id_tipo_grupo = 5;
      if (this.ccenterSelect == 0) {
        this.alertService.info("debe seleccionar un centro de costo para el empleado");
        return 0;
      }
      base_data.id_grupo = this.ccenterSelect;
      if (this.readWrite == "view") {
        base_data.permisos.ver = true;
        base_data.permisos.editar = false;
      }
      else {
        base_data.permisos.ver = false;
        base_data.permisos.editar = true;
      }

      base_data.permisos.marcar_app = this.mark_app;
      base_data.permisos.marcar_web = this.mark_web;
      base_data.codigo = this.id_employee;
      base_data.codigo_huella = this.fingerPrintId;
      base_data.fondo_incapacidad= moment.duration(this.incapacity_found).asMinutes();
      base_data.fondo_vacaciones = moment.duration(this.vacation_found).asMinutes();

    }

    base_data.id_rol = id_rol;
  
    this.httpService.postHttpAuth("/usuario", base_data).
      subscribe(data => {
        this.alertService.success('Usuario creado exitosamente');
        (<any>$("#modalUsers")).modal("hide");
        this.loadAllUsers();
        this.resetForm();
      }
      );



  }

  openModalModify() {  
     this.appModalCalendar.open();
  }
  
  userDeleted(id){
      console.log(id);
      this.users.find( (item,index) => {
         if(item.id == id)
            item.active = false;
      });
  }
  
  updatedSchedule(event){
      this.users.find((item,index) => {
         if(item.active) item.active=false; 
      });
  }

  addRowToEdit(e, user) {
      let bool = this.appModalCalendar.addUserToList(user);
      if(bool) user.active = true; else user.active = false;
  }

  changeDate() {
    var $this = this;

    setTimeout(function() {

      $this.updateCalendar();

    }, 100);

  }

  updateCalendar() {
    
    
    
    this.dpicker.disabledDates = [];
    
    
    
    if(this.endDateEdit && !this.startDateEdit){
      this.alertService.info("Debes seleccionar una fecha de inicio");
      this.endDateEdit=null;
      return 0;
    }
    else{
      
      var disableFrom = moment(this.startDateEdit).subtract(100, 'years').toDate();
      var dateMax = moment(this.startDateEdit).add(3, 'months').toDate();
      var dateMin = moment(this.startDateEdit).add(6, 'days').toDate();

      this.dpicker.disabledDates = [
        {type: DateRangeType.Between,dateRange:[dateMax, new Date("2200-1-15")]},
        {type: DateRangeType.Between,dateRange:[disableFrom, dateMin]}
      ];
      
    }

    if (this.startDateEdit && this.endDateEdit) {
      var template = {
        fecha_inicio: this.startDateEdit,
        fecha_fin: this.endDateEdit,
        plantilla: this.templates[this.templateSelected],
        horario_especial: []
      }

      this.subjectAddDaysEdit.next({
        template});

    }
  }

  saveScheduleByUsers() {
    var ids = [];
    for (let i = 0; i < this.usersToEdit.length; i++) {
      ids.push(this.usersToEdit[i].id);
    }
    var data = {
      "id_plantilla": this.templates[this.templateSelected].id,
      "minutos_extra": this.minutes,
      "nombre": this.nameSchedule,
      "fecha_inicio": moment(this.startDateEdit).format("DD-MM-YYYY"),
      "fecha_fin": moment(this.endDateEdit).format("DD-MM-YYYY"),
      "id": ids
    }

    this.httpService.postHttpAuth("/horario/store/users", data)
      .subscribe(x => {
        (<any>$("#modalEditSchedule")).modal("hide");
        this.alertService.success("Horario agregado correctamente");
      });
  }

  resetForm() {
    this.name = "";
    this.readWrite = "view";
    this.lastname = "";
    this.username = "";
    this.email = "";
    this.password = "";
    this.phone = "";
    this.sucursalSelect = 0;
    this.departamentSelect = 0;
    this.divisionSelect = 0;
    this.id_employee = "";
    this.fingerPrintId = "";
    this.mark_web_supervisor = true;
    this.mark_app_supervisor = true;
    this.mark_web = true;
    this.mark_app = true;
    this.deparments = [];
    this.divisions = [];
    this.centers = [];
    this.divisionSelectArray = [];
  }

  changeSucursal() {



    this.divisionSelect = 0;
    this.departamentSelect = 0;
    this.ccenterSelect = 0;
    this.divisions = [];
    this.deparments = [];
    this.centers = [];
    if (this.sucursalSelect != 0)
      this.httpService.getHttpAuth("/departamento/get/" + this.sucursalSelect)
        .subscribe(data => {
          this.deparments = data.data;

          if (this.deparments.length == 0)
            this.alertService.info("Esta sucursal no tiene departamentos divisiones asociadas");
        })


  }

  changeDeparment() {
    this.divisionSelectArray = [];

    if (this.departamentSelect != 0) {
      this.httpService.getHttpAuth("/division/get/" + this.departamentSelect)
        .subscribe(data => {

          this.divisions = data.data;
          if (this.divisions.length == 0)
            this.alertService.info("Esta división no tiene departamentos asociadas");
        })


    } else {
      this.divisions = [];
    }
  }

  changeDivision() {

    if (this.type != 'supervisor') {
      if (this.divisionSelect != 0) {
        this.httpService.getHttpAuth("/centro-costo/get/" + this.divisionSelect)
          .subscribe(data => {

            this.centers = data.data;
            if (this.divisions.length == 0)
              this.alertService.info("Este departamento no tiene centro de costos asociados");
          })


      } else {
        this.centers = [];
      }
    }

  }

  getSucursals() {
    this.sucursal = true;
    this.sucursalSelect = 0;
    this.httpService.getHttpAuth("/sucursal/get/" + this.id_empresa)
      .subscribe(data => {

        this.sucursales = data.data;
        if (this.sucursales.length == 0)
          this.alertService.info("Esta empresa no tiene sucursales asociadas");
      })
  }

  rerender(): void {

    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  userDelete(id, i) {

    const factory = this.resolver.resolveComponentFactory(<any>ModalConfirmComponent);
    this.user_delete = id;
    this.position_user_delete = i;
    this.componentRef = this.container.createComponent(factory);
    this.componentRef.instance.open();
    this.componentRef.instance.Confirm.subscribe(x => {
      this.componentRef.instance.justDismiss();

      this.httpService.deleteHttpAuth("/usuario/" + this.user_delete , {})
        .subscribe(data => {
          this.users.splice(this.position_user_delete, 1);
          this.alertService.success("Usuario eliminado exitosamente");
          this.rerender();
          this.componentRef = null;
          return 0;
        })
    });
    this.componentRef.instance.Close.subscribe(x => { this.componentRef.instance.justDismiss();this.componentRef = null; });

  }

  ngOnInit() {  	
    this.readWrite = "view";
    if (this.gs.getRole() == 5) {
      this.router.navigate(["/dashboard", { outlets: { section: ['users', this.gs.getUserId()] } }]);
    }

    var group = null;
    group = localStorage.getItem("grupo");
    group = JSON.parse(group);
    group = group.grupos;
    this.id_empresa = group.empresa.id;


    this.httpService.getHttpAuth("/plantilla/get/all")
      .subscribe(x => {
        this.getSucursals();
        this.loadAllUsers();
        this.templates = x.data;
      });

  }

  	search(loadUsers){  	
  		this.structure.type = this.type_user_filter;
  		this.structure.state = this.ng_user_state;  		
  		localStorage.setItem("user_filters", JSON.stringify(this.structure));
  		if(loadUsers) this.loadAllUsers();  		
  		//localStorage.setItem("filterInfo", )
  	}

  loadAllUsers(): void {
    this.isLoadingTab = true;
    var filters = "";

    if(this.filters)
      filters = `&id_grupo=${this.filters.id_grupo}&id_grupo_tipo=${this.filters.id_tipo_grupo}`;
  	if(this.ng_user_state != 0) {
      filters += '&id_estado=' + this.ng_user_state;
    } else{
      filters += '&id_estado=1,2,6,4';
    }

    this.appModalCalendar.deleteList();     
    this.httpService.getHttpAuth("/usuario/get/all?id_rol="+this.type_user_filter+filters)
      .subscribe(data => {
        this.isLoadingTab = false;      
        this.users = data.data;
        this.rerender();
      })
  }

  ngAfterViewInit(): void {  	
    let struc = JSON.parse(localStorage.getItem("user_filters"));
    if(struc){
    	this.type_user_filter = struc.type;
    	this.ng_user_state = struc.state;    	
    	this.filtersCompany.reset_filters(struc);
    }
    this.dtTrigger.next();
  }

  changeFilter(filter){
    this.filters = filter;
    this.loadAllUsers();
  }

  filter(filter){
  	if(filter.id_grupo != 0){
  		if(filter.id_tipo_grupo == 2){
  			this.structure.id_sucursal = filter.id_grupo;
  			this.structure.id_departamento = 0;
  			this.structure.id_division = 0;
  		}
  		if(filter.id_tipo_grupo == 3){  			
  			this.structure.id_departamento = filter.id_grupo;
  			this.structure.id_division = 0;
  		}
  		if(filter.id_tipo_grupo == 4){  			
  			this.structure.id_division = filter.id_grupo;  			
  		}
  	}
    this.filters = filter;
    if(filter.id_grupo==0)
      this.filters=null;

  	this.search(false);

    //this.loadAllUsers();
  }


  	


}
