import { Component, OnInit } from '@angular/core';
import { HttpService } from "@services/Http/http.service"
import {Router, ActivatedRoute} from "@angular/router";
import { AlertService } from 'ngx-alerts'
@Component({
  selector: 'app-maintenance-page',
  templateUrl: './maintenance-page.component.html',
  styleUrls: ['./maintenance-page.component.scss']
})
export class MaintenancePageComponent implements OnInit {

  constructor(public httpService: HttpService, public router: Router, public alertService: AlertService) {
    document.body.style.background = "#ffff";
  }

  ngOnInit() {
    this.httpService.postHttp("/status",{})
    .subscribe(x => {
        
        if(x.status=="up"){
          this.router.navigate(['/login'])
          this.alertService.success('Vuelve a iniciar sesión');
          localStorage.removeItem("token")
        }
    });
  }
  
  

}
