import { Pipe, PipeTransform } from '@angular/core';
import * as moment from "moment"
import 'twix';
@Pipe({
  name: 'formatHours'
})
export class FormatHoursPipe implements PipeTransform {

  transform(value: any, from: any,to:any): any {
    if(value)
      return moment(value,from).format(to);
    else
      return null;
  }

}
