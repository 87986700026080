import { Component, OnInit, ViewChild, ComponentFactoryResolver, ViewContainerRef, ComponentRef, AfterViewInit } from '@angular/core';
import { HttpService } from "@app/services/Http/http.service"
import { AlertService } from 'ngx-alerts'
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Angular5Csv } from 'angular5-csv/Angular5-csv';
import { Angular2Txt } from 'angular2-txt/Angular2-txt';
import { GlobalsService } from '@services/globals/globals.service'
import { ModalConfirmComponent } from "@html/modals/modal-confirm/modal-confirm.component"
import { DomService } from "@html/modals/modal-confirm/modal-service"
import { IgxDatePickerComponent, DateRangeType } from "igniteui-angular"

import * as moment from "moment"

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent implements OnInit {

  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  @ViewChild("alertContainer", { read: ViewContainerRef }) container;
  @ViewChild("uidt") dpicker: IgxDatePickerComponent;
  @ViewChild(DataTableDirective)
  componentRef: ComponentRef<any>;
  dtTrigger: Subject<any> = new Subject();
  checkin: any;
  users: any = [];
  rowsEdit: any = [];
  cardsIds: any = [];
  listIds: any = [];
  isLoadingTab: boolean = false;
  isLoadingApprove: boolean = false;
  actualPeriod: any = {};
  idCutPeriod: number = null;
  actualPositionUser: number = 0;
  actualPositionPeriod: number = 0;
  periodsCuts: any = [];
  userDistribution: any = [];
  selectedIdsModel: any = [];
  cutSelected: any = null;
  currentCut: any = null;
  date: Date = new Date(Date.now());
  cutDate: Date;
  show_cutDate: any = null;
  approve_date: Date;
  showApprovalWarning: boolean = false;
  constructor(public gs: GlobalsService,
     public httpService: HttpService,
     private alertService: AlertService,
     public domService: DomService,
     private resolver: ComponentFactoryResolver) { }

  dtOptions: any = {
    pagingType: 'full_numbers',
    'iDisplayLength': 100,
    "columnDefs": [
     { "targets": [0], "orderable": false }
    ],
    buttons: [
        { extend: 'colvis', text: 'Colunas', columns: [0, 1, 2] },
        { extend: 'copy', text: 'Copiar' },
        { extend: 'csv' },
        { extend: 'print', text: 'Imprimir', title: 'Tech Shop Web' },
        { extend: 'excel', title: 'Tech Shop Web' },
        { extend: 'pdf', title: 'Tech Shop Web' }
      ],
    "language": {
              "url": "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json"
    }
  };

  addRowToEdit(e, id){
    console.log(e.target.checked);
    if(e.target.checked)
      this.rowsEdit.push(id);
    else
      this.rowsEdit.splice( this.rowsEdit.indexOf(id), 1 );

  }

  ngOnInit() {
    this.isLoadingTab=true;
    this.httpService.getHttpAuth("/periodo/tarjetas")
    .subscribe(data => {
      console.log(data.data);
        this.isLoadingTab=false;
        this.users=data.data.colaboradores;
        this.actualPeriod.init=data.data.fechas.inicio;
        this.actualPeriod.end=data.data.fechas.fin;
        this.idCutPeriod = data.data.cortes.length - 1;
        this.periodsCuts = data.data.cortes;
        this.cutDate=(data.data.corte.fecha_aprobacion ? moment(data.data.corte.fecha_aprobacion).toDate() : null);
        this.show_cutDate = (this.cutDate == null) ? null : moment(this.cutDate).add(1, 'days');
        this.actualPositionPeriod =  data.data.cortes.length - 1;
        this.cutSelected = this.periodsCuts[this.actualPositionPeriod];
        this.currentCut=data.data.corte;

        if(!data.data.corte.fecha_aprobacion){
          this.showApprovalWarning = true;          
        }
        
        this.dtTrigger.next();
        this.dtElement.dtInstance.then((dtInstance:any) => {
          //console.log(dtInstance.rows({filter: 'applied'}));
          let df= dtInstance.rows( { page: 'current' } );
          //console.log(df);
        });
    })
  }
  
  

  loadPeriodCut(id){
    this.isLoadingTab=true;
    if(!id) id="";
    this.httpService.getHttpAuth("/periodo/tarjetas/"+id)
    .subscribe(data => {
      console.log(data.data);
        this.isLoadingTab=false;
        this.users=data.data.colaboradores;
        this.actualPeriod.init=data.data.fechas.inicio;
        this.actualPeriod.end=data.data.fechas.fin;
        this.idCutPeriod = id;
        this.periodsCuts = data.data.cortes;
        this.currentCut=data.data.corte;
        this.rerender();
    })
  }

  updateIds(value,id,i){
    // console.log(this.cardsIds[i],this.cardsIds, i);
    if(value.target.checked==true)
      this.listIds.push(id);
    else{
      var index = this.listIds.findIndex((item, i) => {
        return item===id;
      });
      this.listIds.splice(index, 1);
    }
  }

  approve(){
    var data = {
      usuarios: this.listIds
    };
    
    if(this.listIds.length==0)
    { 
      this.alertService.info("Debes seleccionar al menos a un usuario.");
      return 0;
    }
    this.isLoadingApprove=true;
    this.httpService.postHttpAuth("/periodo/aprobar", data)
    .subscribe( data => {
      this.isLoadingApprove=false;
      this.alertService.success("Periodos aprobados correctamente.");
      this.userDistribution = data.data;
      for (let i = 0; i < this.users.length; i++) {
          if(this.listIds.includes(this.users[i].id))
            this.users[i].aprobar=2;
      }
      this.listIds=[];
      (<any>$("#logs-distribution")).modal({backdrop: 'static', keyboard: false,show:true});
    
    
      },
      err => {
        this.isLoadingApprove=false;
      });
  }

  changePeriod(type){
      
    if(type=='prev')
      this.actualPositionPeriod--;
      else
      this.actualPositionPeriod++;
  

      if(this.periodsCuts[this.actualPositionPeriod]){
          this.cutSelected = this.periodsCuts[this.actualPositionPeriod];  
          this.loadPeriodCut(this.periodsCuts[this.actualPositionPeriod]);
      }
      else{
        this.alertService.warning("No existe otro periodo");
      }

  }
  
  changeUserDistribution(type){
    if(type=='prev')
      this.actualPositionUser--;
      else
      this.actualPositionUser++;
  }

    call_approvalSave() {
        var from = moment(this.actualPeriod.init,"DD-MM-YYYY").format("YYYY-MM-DD");
        var disableFrom = moment(from).subtract(200, 'years').toDate();
        var dateMax = moment(from).subtract(1, 'days').toDate();
        console.log(disableFrom, dateMax, this.actualPeriod.init);
        this.dpicker.disabledDates = [
          {type: DateRangeType.Between,dateRange:[disableFrom, dateMax]},
        ];
        (<any>$("#modal-approval-date")).modal({backdrop: 'static', keyboard: false,show:true});
    }

    approvalSave() {
        this.container.clear();
        const factory = this.resolver.resolveComponentFactory(<any>ModalConfirmComponent);
        this.componentRef = this.container.createComponent(factory);
        this.componentRef.instance.open();
        this.componentRef.instance.Confirm.subscribe(x => {
          this.componentRef.instance.justDismiss();
          this.isLoadingApprove=true;
          this.httpService.postHttpAuth("/periodo/cierre/aprobacion",{
            fecha_corte: moment(this.approve_date).format("YYYY-MM-DD")
            }).subscribe(data => {
                this.alertService.success("Fecha de cierre actualizada.");
              
                (<any>$("#modal-approval-date")).modal("hide");
                this.showApprovalWarning=false;
                this.isLoadingApprove=false;
                this.cutDate=moment(this.approve_date).toDate();
                this.show_cutDate = this.cutDate;
            }, error => {
                this.isLoadingApprove=false;
            });
        });
        this.componentRef.instance.Close.subscribe(x => {
          this.componentRef.instance.justDismiss();
        });


   
        
    }

  closePeriod(){
    (<any>$("#cut-period")).modal({backdrop: 'static', keyboard: false,show:true});
  }
  
  cutPeriod(){
      this.container.clear();
      const factory = this.resolver.resolveComponentFactory(<any>ModalConfirmComponent);
      this.componentRef = this.container.createComponent(factory);
      this.componentRef.instance.open();
      this.componentRef.instance.Confirm.subscribe(x => {
        this.componentRef.instance.justDismiss();
        this.isLoadingApprove=true;
        this.httpService.postHttpAuth("/periodo/cierre", {
          fecha_corte: moment(this.cutDate).format("YYYY-MM-DD")
        })
        .subscribe( data => {
          this.componentRef.instance.justDismiss();
          this.isLoadingApprove=false;
          
    
          if(data.data.error==true){
            
              var message="";
              
              var faltantes = data.data.periodos.length-5;
              
              for (let i = 0; i < data.data.periodos.length; i++) {
                  
                  if(i==5)
                    break;
            
                  message+=data.data.periodos[i].usuario;
                  
                  if(i<4 && data.data.periodos.length!=1)  
                   message+=", ";
              }
            
              if(data.data.periodos.length>5)
                message+=" y " + faltantes + " usuarios más requieren de aprobacion";
              else if(data.data.periodos.length > 1 && data.data.periodos.length <= 5)
                message+=" requieren aprobación.";
              else if(data.data.periodos.length==1)
                message+=" requiere aprobación";
                
              this.alertService.info(message);
          }else{
            (<any>$("#cut-period")).modal("hide");
            this.alertService.success("Cierre realizado de manera correcta.");
            this.show_cutDate = null;
            this.loadPeriodCut(data.data.nuevo.corte.id);
          }
          
        },
        error => {
          this.isLoadingApprove=false
          this.componentRef.instance.justDismiss();
        });
      });
      this.componentRef.instance.Close.subscribe(x => {
        this.componentRef.instance.justDismiss();
      });
  }
  
  
  generateFile(quick){   
    var $this = this;
    var success = function(x) {
      console.log("success");
      $this.isLoadingApprove=false;
      var options = { 
        fieldSeparator: (x.data.config.separator == 'tab' ? "\t" : x.data.config.separator),
        quoteStrings:"",
        useBom : false
      };
             
      new Angular2Txt(x.data.data,x.data.config.filename + " " + moment().format("DD-MM-YY HH:mm"),options);
    }

    var error = function(){
      $this.isLoadingApprove=false;
    }
    
   
    if(quick) {     
      if(this.listIds.length==0)
      { 
        this.alertService.info("Debes seleccionar al menos a un usuario.");
        return 0;
      }   
      this.isLoadingApprove=true;
      this.httpService.postHttpAuth('/periodo/generar/rapido', {usuarios: this.listIds}).subscribe(success, error);
    } else {  
      this.isLoadingApprove=true;     
      this.httpService.getHttpAuth('/periodo/generar/'+this.cutSelected).subscribe( success, error);
    }
  }
  
  rerender(): void {

    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
  
  chequearVisibles(){
    this.dtElement.dtInstance.then((dtInstance:DataTables.Api) => {

      let df= dtInstance.rows( { page: 'current' } );
      console.log(df);
    });
  }
  
  checkAll(target){    
    if(this.checkin==false){
      this.listIds=[];
      return 0;
    }
    
    let users = this.users;
    this.dtElement.dtInstance.then((dtInstance:DataTables.Api) => {
      let df= dtInstance.rows( { page: 'current' } );
      let select = df["0"];
      for (let i = 0; i < select.length; i++) {
          if(this.listIds.includes(users[select[i]].id)){
            var index = this.listIds.indexOf(users[select[i]].id);
            this.listIds.splice(index, 1);
            continue;
          }
          if(users[select[i]].aprobar!=2)
            this.listIds.push(users[select[i]].id);
      
      }
    });
  }
}
