import { Component, AfterViewInit, OnInit,OnDestroy, ViewChild } from '@angular/core';
import { HttpService } from "@app/services/Http/http.service"
import { AlertService } from 'ngx-alerts'
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import * as moment from "moment"

@Component({
  	selector: 'app-summary',
  	templateUrl: './summary.component.html',
  	styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {

 	constructor(public httpService: HttpService, private alertService: AlertService) { }

 	@ViewChild(DataTableDirective)
  	dtElement: DataTableDirective;
  	dtTrigger: Subject<any> = new Subject();
  	startDateEdit: Date = moment().toDate();
  	endDateEdit: Date = moment().toDate();
  	groups: any = {};
  	dtOptions: any = {
    	pagingType: 'full_numbers',
    	dom: 'Bfrtip',
    	buttons: [
        	{ extend: 'print', text: 'Imprimir', title: 'Reporte de Resumen de periodos' },
        	{ extend: 'excel', title: 'Reporte de Resumen de periodos' }
      	],
  		"language": {
    		"url": "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json",
    		"emptyTable": "No existen ausencias con los parametros especificados"
  		},
  		"pageLength": 50     
  	};
  	isLoadingTab: boolean = false;
  	isLoading: boolean = false;
  	rowsReport: any = [];

  	ngOnInit() {}

  	ngAfterViewInit(): void {
   		this.dtTrigger.next();
   	}

   	ngOnDestroy(): void {
     	// Do not forget to unsubscribe the event
     	this.dtTrigger.unsubscribe();
   	}   

  	filter(event){ 
     	this.groups=event;
     	this.isLoading = event.loading;    
   	}

  	apply(){
     	let data = {
     		from:moment(this.startDateEdit).format("YYYY-MM-DD"),
       		to: moment(this.endDateEdit).format("YYYY-MM-DD"),
        	group: this.groups
    	};
    	
     	this.isLoadingTab= true;
     	this.httpService.postHttpAuth("/reporte/resumen", data).subscribe(data => {
     		this.rowsReport = [];
       		this.isLoadingTab= false;
       		this.rowsReport = data.report;       		
       		this.rerender();
     	});     
   	}

   	rerender(): void {
    	this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        	// Destroy the table first
        	dtInstance.destroy();
        	// Call the dtTrigger to rerender again
        	this.dtTrigger.next();
      	});
   	}
}